import React, { Component } from 'react';
import * as api from '../../api.js';
import PropTypes from 'prop-types';
import FilePreview from '../FilePreview';
import {
  createProductSheet,
  deleteDraftProduct,
  getProductDetails,
  modifyProductSheet,
  resetProductDetails,
  createProductVersion,
} from '../../actions/productActions';
import { resetAppSuccess } from '../../actions/appActions';
import { getPartners } from '../../actions/partnerActions';
import { getIngredients } from '../../actions/ingredientActions';
import { getTestItemAll } from '../../actions/testItemActions';
import { addBatchTestReport, testReportAddModalShowToggle } from '../../actions/batchActions';
import { batchDetailsModalShowToggle, getBatchDetails, resetBatchDetails } from '../../actions/batchActions';
import { Link } from 'react-router-dom';
import { Col, Container, Form, FormControl, FormGroup, FormLabel, Row, ThemeProvider } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Formik, FieldArray } from 'formik';
import './style.scss';
import TimesIcon from '../../images/times.svg';
import TimesColorIcon from '../../images/times_color.svg';
import groupByArray from '../../utils/groupByArray';
import removeArrayItemByValue from '../../utils/removeArrayItemByValue';
import AddIcon from '../../images/add.svg';
import config from '../../config';
import { withTranslation } from 'react-i18next';
import AppStatus from '../AppStatus';
import { Select, Tree } from 'antd';

import { MultiSelectComponent, CheckBoxSelection, Inject } from '@syncfusion/ej2-react-dropdowns';
import { SampleBase } from './sample-base';
import { push } from 'connected-react-router';
// import 'antd/dist/antd.css';

const { Option } = Select;

const { API_URL } = config;

class ProductCreateModifySheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableIngredientAutoSuggestion: false,
      enableTestItemAutoSuggestion: false,
      expandedKeys: [],
      checkedKeys: [],
      selectedKeys: [],
      autoExpandParent: true
    }
    this.onExpand = this.onExpand.bind(this);
    this.onCheck = this.onCheck.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  componentDidMount() {
    this.props.getPartners();
    this.props.getIngredients();
    this.props.getTestItemAll();
    const { productId } = this.props.match.params;
    if (productId) {
      this.props.getProductDetails(productId);
    } else {
      this.props.resetProductDetails();
    }

  }

  getYupShape(productId, msg) {
    return productId ? yup.mixed() : yup.mixed().required(msg);
  }


  onExpand = (y) => {
    console.log('onExpand', y); // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({ expandedKeys: y });
    // this.setState({autoExpandParent:false})
  };

  onCheck = (c) => {
    console.log('onCheck', c);
    this.setState({ checkedKeys: c });
  };

  onSelect = (s, info) => {
    console.log('onSelect', info);
    this.setState({ selectedKeys: s });
  };



  render() {
    const { t, batchId } = this.props;
    const { dataList: partnerList } = this.props.partner;
    const { dataList: ingredientList } = this.props.ingredient;
    const { dataListAll: testItemAllList } = this.props.testItemAll;
    const testItemList = this.props?.testItemAll?.dataListAll;
    const groupByCategory = testItemList?.reduce(function (rv, x) {
      (rv[x['testCategory']['_id']] = rv[x['testCategory']['_id']] || []).push(x);
      return rv;
    }, {});

    let groupedPartnerList;
    if (partnerList) {
      groupedPartnerList = groupByArray(partnerList, 'Categorie');
    }
    const { productDetails } = this.props.product;
    const { productId, parentProductId } = this.props.match.params;

    const ProductCreateSheetSchema = yup.object().shape({
      Categorie: yup.string().required(t('Please select Categorie')),
      nameFR: yup.string().required(t('Please enter Nom (FR)')),
      nameEN: yup.string().required(t('Please enter Nom (EN)')),
      codeEAN: yup.string().required(t('Please enter Code EAN')),
      SKU: yup.string(),
      averageMarket: yup.number(t('Please enter valid Average Market')).required(t('Please enter Average Market')),
      dermatologicalTestDate: yup.string().matches(/^\d{1,2}\/\d{1,2}\/\d{4}$/, {
        excludeEmptyString: true,
        message: t('Please enter correct date'),
      }),
      dermatologicalTestReport: yup.mixed(),
      productImage: this.getYupShape(productId, t('Please upload product image')),
      Packaging: this.getYupShape(productId, t('Please upload packaging image')),
      lotImage: this.getYupShape(productId, t('Please upload lot image')),
      qRCodeImage: yup.mixed(),
      Documentation: yup.array().of(
        yup.object().shape({
          _id: yup.string(),
          logo: yup.mixed(),
          certificate: yup.mixed(),
        }),
      ),
      partners: yup.array(),
      ingredients: yup.array(),
      molecules: yup.array(),
      Draft: yup.bool(),
    });


    // const molist = []

    // for (const item of testItemAllList) {

    //   if (!molist.hasOwnProperty(item?.testCategory?.name))
    //     molist[item?.testCategory?.name] = {}

    //   if (!molist[item?.testCategory?.name].hasOwnProperty(item?.name))
    //     molist[item?.testCategory?.name][item.name] = []

    //   for (const molecule of item?.molecules) {
    //     molist[item?.testCategory?.name][item?.name].push(molecule?.name)
    //   }
    // }



    let molist2 = []
    for (const item of testItemAllList) {

      const id = molist2.findIndex(f => f.title === item?.testCategory?.name)
      const tabl = []

      for (const mole of item.molecules) {
        tabl.push({ 
          title: mole?.name, 
          key: mole?._id 
        })
      }
      console.log("tabl", tabl)
      
      if (id === -1) {
        molist2.push({ 
          title: item?.testCategory?.name, 
          key: item?.testCategory?._id, 
          children: [{ title: item?.name, key: item?._id, children: [...tabl] }] 
        })
      } else {
        molist2[id].children.push({ 
          title: item?.name, 
          key: item?._id, 
          children: [...tabl] 
        })
      }
    }
    console.log("molist2", molist2)
    console.log("testItemAllList", testItemAllList)
    console.log("CHECK", this.state.checkedKeys)




    return (
      <>
        <div className="top-bar">
          <Container>
            <Row>
              <Col md={6}>
                {productId ? (
                  <Link to={`/ProductDetails/${productId}`} className="top-bar-left-link">
                    <i>←</i> {t('Return to product sheet')}
                  </Link>
                ) : (
                    <Link to="/ProductList" className="top-bar-left-link">
                      <i>←</i> {t('Return to product list')}
                    </Link>
                  )}
              </Col>
              {productDetails?.Draft && (
                <Col md={6} className="text-right">
                  <button
                    type="button"
                    className="action_btn_with_txt"
                    onClick={(e) => {
                      this.props.deleteDraftProduct(productId);
                      this.props.history.push('/ProductList');
                    }}
                  >
                    <i className="action-icon  mr-2">
                      <img src={TimesIcon} alt="Delete" />
                    </i>
                    {t('Delete draft')}
                  </button>
                </Col>
              )}
            </Row>
          </Container>
        </div>
        {(productId && productDetails) || !productId ? (
          <Formik
            enableReinitialize
            initialValues={{
              Categorie: productDetails ? productDetails?.Categorie : '',
              nameFR: productDetails ? productDetails?.nameFR : '',
              nameEN: productDetails ? productDetails?.nameEN : '',
              codeEAN: productDetails ? productDetails?.codeEAN : '',
              SKU: productDetails ? productDetails?.SKU : '',
              averageMarket: productDetails ? productDetails?.averageMarket : '',
              productImage: productDetails ? productDetails?.productImage : null,
              lotImage: productDetails ? productDetails?.lotImage : null,
              dermatologicalTestReport: productDetails ? productDetails?.dermatologicalTestReport : null,
              qRCodeImage: productDetails ? productDetails?.qRCodeImage : null,
              Packaging: productDetails ? productDetails?.Packaging : null,
              Documentations: productDetails ? productDetails?.Documentations : null,
              partners: productDetails && productDetails?.Partners ? productDetails?.Partners?.map((p) => p?._id) : [],
              ingredients:
                productDetails && productDetails?.Ingredients ? productDetails?.Ingredients?.map((p) => p?._id) : [],
              dermatologicalTestDate: productDetails ? productDetails?.dermatologicalTestDate : '',
              molecules: ((productDetails && productDetails.Molecules) && productDetails.Molecules.map((p) => p)) || [],
              Draft: productDetails ? productDetails?.Draft : false
            }}
            validationSchema={ProductCreateSheetSchema}
            onSubmit={(values) => {

              console.log("valuesvalues0", values)

              if (values.ingredients && ingredientList) {
                console.log("PREMIER VALUE-INBREDIREN", values.ingredients)
                console.log("ingredientList", ingredientList)
                values.ingredients = values.ingredients.filter((filterItem) =>
                  ingredientList.find((ingredientOne) => filterItem === ingredientOne._id),
                );
                console.log("DEUXIEME VALUE ingredients", values.ingredients)
              }


              const tab1 = [];
              const tab2 = [];
              if (values.molecules && testItemList) {


                // const mainMolecule = values.molecules = values.molecules.filter((filterItem) =>
                // testItemList.find((ingredientOne) => filterItem === ingredientOne._id),
                // );

                // console.log('mainMolecule', mainMolecule)
                console.log("PREMIER VALUE-MOLECULES", values.molecules)
                console.log("testItemList", testItemList)
                for (let h = 0; h < values?.molecules?.length; h++) {
                  console.log("FOR")
                  const i = values?.molecules?.[h];
                  if (i?.type === 'main') {
                    console.log("IF MAIN")
                    tab1.push(i?._id);
                  } else if (i?.type === 'sub') {
                    console.log("IF SUB")
                    tab2.push(i?._id);
                  }
                };
                console.log("testItems 0", testItems)
                var testItems = tab1.concat(tab2);
                console.log("testItems 1", testItems)
                values.molecules = this.state.checkedKeys
                console.log("testItems 2", testItems)
                console.log("TROISIE%E VALUE-MOLECULES", values.molecules)

                console.log("valuesvalues0", values)
              }

              if (parentProductId) {
                this.props.createProductVersion(productId, parentProductId, values, this.props.history);
              } else if (productId) {
                this.props.modifyProductSheet(productId, values, this.props.history);
              } else {
                this.props.createProductSheet(values, this.props.history);
              }
            }}
          >
            {(renderProps) => (
              <form className="mt-5" onSubmit={renderProps.handleSubmit} onChange={this.props.resetAppSuccess}>
                <Container>
                  <div>
                    <AppStatus />
                  </div>
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      <div className="text-center mb-5">
                        <h5 className="page_head_sub">{!productId ? t('Create a') : t('Update')}</h5>
                        <h1 className="page_head">{!productId ? t('NEW PRODUCT SHEET') : t('PRODUCT SHEET')}</h1>
                      </div>
                      <FormGroup className="rect_box_title" as={Row} controlId="Marque">
                        <FormLabel column lg={5}>
                          {t('Brand')}
                        </FormLabel>
                        <Col lg={7}>
                          <input className="form-control" type="text" placeholder="YOU" readOnly />
                        </Col>
                      </FormGroup>
                      <FormGroup as={Row} controlId="Categorie">
                        <FormLabel className="rect_box_title" column lg={5}>
                          {t('Category')}
                        </FormLabel>
                        <Col lg={7}>
                          <FormControl
                            as="select"
                            name="Categorie"
                            required
                            onChange={(ev) => {
                              if (!productId || renderProps.values.Draft) {
                                renderProps.handleChange(ev);
                              }
                            }}
                            onBlur={renderProps.handleBlur}
                            value={renderProps.values.Categorie}
                            readOnly={!!productId && !renderProps.values.Draft}
                          >
                            <option>Select</option>
                            <option>Liquide vaisselle</option>
                            <option>Nettoyant ménager</option>
                            <option>Nettoyant sol</option>
                            <option>Lessive</option>
                            <option>Lingette</option>
                            <option>Gel douche</option>
                            <option>Désinfectant</option>
                            <option>Ingrédient naturel</option>
                            <option>Lave-vaisselle</option>
                            <option>Déodorant</option>
                            <option>Cheveux</option>
                            <option>Savon</option>
                            <option>Savon main</option>
                            <option>Serpillère</option>
                            <option>Soin du linge</option>
                            <option>WC</option>
                            <option>Vitres</option>
                            <option>Désodorisant</option>
                            <option>Soin de la peau</option>
                            <option>Solaire</option>
                            <option>Accessoires</option>
                            <option>Dépoussiérant</option>
                            <option>Hygiène</option>
                            <option>Insecticide</option>
                            <option>Bougie</option>
                          </FormControl>
                        </Col>
                      </FormGroup>
                      <FormGroup as={Row} controlId="nameFR">
                        <FormLabel className="rect_box_title" column lg={5}>
                          {t('Name')}
                        </FormLabel>
                        <Col lg={7}>
                          <div className="custom-text-area-group mb-2">
                            <i className="flag-icon flag-icon-fr" />
                            <FormControl
                              type="text"
                              name="nameFR"
                              value={renderProps.values.nameFR}
                              onChange={(ev) => {
                                renderProps.handleChange(ev);
                              }}
                              onBlur={renderProps.handleBlur}
                              required
                              readOnly={!!productId && !renderProps.values.Draft && !parentProductId}
                            />
                          </div>
                          <div className="custom-text-area-group">
                            <i className="flag-icon flag-icon-gb " />
                            <FormControl
                              type="text"
                              name="nameEN"
                              value={renderProps.values.nameEN}
                              onChange={(ev) => {
                                renderProps.handleChange(ev);
                              }}
                              onBlur={renderProps.handleBlur}
                              required
                              readOnly={!!productId && !renderProps.values.Draft && !parentProductId}
                            />
                          </div>
                        </Col>
                      </FormGroup>
                      <FormGroup as={Row} controlId="codeEAN">
                        <FormLabel className="rect_box_title" column lg={5}>
                          {t('EAN code')}
                        </FormLabel>
                        <Col lg={7}>
                          <FormControl
                            type="text"
                            name="codeEAN"
                            value={renderProps.values.codeEAN}
                            onChange={(ev) => {
                              renderProps.handleChange(ev);
                            }}
                            onBlur={renderProps.handleBlur}
                            required
                            readOnly={!!productId && !renderProps.values.Draft && !parentProductId}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup as={Row} controlId="SKU">
                        <FormLabel className="rect_box_title" column lg={5}>
                          {t('SKU')}
                          <span className="text-muted d-block font-weight-normal font-smallest"> {t('Optional')}</span>
                        </FormLabel>
                        <Col lg={7}>
                          <FormControl
                            type="text"
                            name="SKU"
                            value={renderProps.values.SKU}
                            onChange={(ev) => {
                              renderProps.handleChange(ev);
                            }}
                            onBlur={renderProps.handleBlur}
                          />
                        </Col>
                      </FormGroup>
                      <div className="divider mt-4 mb-4" />
                      <FormGroup as={Row} controlId="dermatologicalTestDate">
                        <FormLabel className="rect_box_title" column lg={5}>
                          {t('Dermatological test')}
                          <span className="text-muted d-block font-weight-normal font-smallest">{t('Optional')}</span>
                        </FormLabel>
                        <Col lg={7}>
                          <Row>
                            <Col lg={5}>
                              <FormControl
                                type="text"
                                name="dermatologicalTestDate"
                                value={renderProps.values.dermatologicalTestDate}
                                onChange={(ev) => {
                                  renderProps.handleChange(ev);
                                }}
                                onBlur={renderProps.handleBlur}
                                placeholder={t('DD/MM/YYYY')}
                              />
                            </Col>
                            <Col lg={7}>
                              {renderProps?.values?.dermatologicalTestReport ? (
                                <div className="certificate-file-item mt-2">
                                  <span>
                                    {typeof renderProps?.values?.dermatologicalTestReport === 'string'
                                      ? renderProps?.values?.dermatologicalTestReport
                                        ?.split(/[\\]/)
                                        ?.pop()
                                        .substr(
                                          0,
                                          renderProps?.values?.dermatologicalTestReport
                                            ?.split(/[\\]/)
                                            ?.pop()
                                            .lastIndexOf('.'),
                                        )
                                      : renderProps.values.dermatologicalTestReport[0].name?.substr(
                                        0,
                                        renderProps.values.dermatologicalTestReport[0].name?.lastIndexOf('.'),
                                      )}
                                  </span>
                                  <button
                                    className="reset-btn"
                                    type="button"
                                    onClick={() => renderProps.setFieldValue('dermatologicalTestReport', null)}
                                  >
                                    <img src={TimesColorIcon} alt="" />
                                  </button>
                                </div>
                              ) : (
                                  <>
                                    <input
                                      type="file"
                                      name="dermatologicalTestReport"
                                      id="dermatologicalTestReport"
                                      className="d-none"
                                      onBlur={renderProps.handleBlur}
                                      onChange={(ev) => {
                                        renderProps.setFieldValue(
                                          'dermatologicalTestReport',
                                          ev.currentTarget.files.length === 1 ? ev.currentTarget.files : null,
                                        );
                                      }}
                                    />
                                    <label
                                      htmlFor="dermatologicalTestReport"
                                      className="rect-lbl action_btn_with_txt font-weight-normal mt-1"
                                    >
                                      <i className="action-icon rounded-circle mr-1">
                                        <img src={AddIcon} alt="Add" />
                                      </i>
                                      {t('Add the analysis report')}
                                    </label>
                                  </>
                                )}
                            </Col>
                          </Row>
                        </Col>
                      </FormGroup>
                      <FormGroup as={Row} controlId="averageMarket">
                        <FormLabel className="rect_box_title" column lg={5}>
                          {t('Average market')}
                        </FormLabel>
                        <Col lg={7}>
                          <FormControl
                            type="text"
                            name="averageMarket"
                            value={renderProps.values.averageMarket}
                            onChange={(ev) => {
                              renderProps.handleChange(ev);
                            }}
                            onBlur={renderProps.handleBlur}
                            required
                            className="d-inline-block mr-2"
                            style={{ maxWidth: 50 }}
                            inputMode="numeric"
                            maxLength={2}
                          />
                          <span className=""> /100</span>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Container>
                <div className="bg_block py-5 my-5">
                  <Container>
                    <Row className="justify-content-center">
                      <Col lg={8}>
                        <h3 className="page_head_2 mb-4">{t('PHOTOS / Illustrations')}</h3>
                        <Row className="mt-3">
                          <Col lg={3}>
                            <div className="rect_box_wrapper">
                              <div className="rect_box_title mb-2">{t('Product picture')}</div>
                              <div className="rect_box">
                                <input
                                  type="file"
                                  name="productImage"
                                  id="productImage"
                                  className="d-none"
                                  accept="image/*"
                                  onBlur={renderProps.handleBlur}
                                  onChange={(ev) => {
                                    renderProps.setFieldValue('productImage', ev.currentTarget.files);
                                  }}
                                />
                                <label htmlFor="productImage" className="rect-lbl">
                                  +
                                </label>
                                {renderProps?.values?.productImage && (
                                  <div className="preview_container">
                                    {(!productId || renderProps.values.Draft || parentProductId) && (
                                      <button
                                        onClick={(ev) => {
                                          renderProps.setFieldValue('productImage', undefined);
                                          document.getElementById('productImage').value = '';
                                        }}
                                        type="button"
                                        className="preview_close"
                                      >
                                        <img src={TimesIcon} alt="remove" />
                                      </button>
                                    )}
                                    {renderProps?.values?.productImage &&
                                      typeof renderProps?.values?.productImage === 'string' ? (
                                        <img
                                          src={API_URL.concat(`/${renderProps.values.productImage}`)}
                                          alt=""
                                          className="img-fluid prev_image"
                                        />
                                      ) : (
                                        renderProps?.values?.productImage && (
                                          <FilePreview file={renderProps.values.productImage[0]} />
                                        )
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="rect_box_wrapper">
                              <div className="rect_box_title mb-2">{t('Lot Number _o')}</div>
                              <div className="rect_box">
                                <input
                                  type="file"
                                  name="lotImage"
                                  id="lotImage"
                                  className="d-none"
                                  accept="image/*"
                                  onBlur={renderProps.handleBlur}
                                  onChange={(ev) => {
                                    renderProps.setFieldValue('lotImage', ev.currentTarget.files);
                                  }}
                                />
                                <label htmlFor="lotImage" className="rect-lbl">
                                  +
                                </label>
                                {renderProps?.values?.lotImage && (
                                  <div className="preview_container">
                                    <button
                                      onClick={(ev) => {
                                        renderProps.setFieldValue('lotImage', undefined);
                                        document.getElementById('lotImage').value = '';
                                      }}
                                      type="button"
                                      className="preview_close"
                                    >
                                      <img src={TimesIcon} alt="remove" />
                                    </button>
                                    {renderProps?.values?.lotImage &&
                                      typeof renderProps?.values?.lotImage === 'string' ? (
                                        <img
                                          src={API_URL.concat(`/${renderProps.values.lotImage}`)}
                                          alt=""
                                          className="img-fluid prev_image"
                                        />
                                      ) : (
                                        renderProps?.values?.lotImage && (
                                          <FilePreview file={renderProps.values.lotImage[0]} />
                                        )
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="rect_box_wrapper">
                              <div className="rect_box_title mb-2">{t('Packaging')}</div>
                              <div className="rect_box">
                                <input
                                  type="file"
                                  name="Packaging"
                                  id="Packaging"
                                  className="d-none"
                                  accept="image/*"
                                  onBlur={renderProps.handleBlur}
                                  onChange={(ev) => {
                                    renderProps.setFieldValue('Packaging', ev.currentTarget.files);
                                  }}
                                />
                                <label htmlFor="Packaging" className="rect-lbl">
                                  +
                                </label>
                                {renderProps?.values?.Packaging && (
                                  <div className="preview_container">
                                    <button
                                      onClick={(ev) => {
                                        renderProps.setFieldValue('Packaging', undefined);
                                        document.getElementById('Packaging').value = '';
                                      }}
                                      type="button"
                                      className="preview_close"
                                    >
                                      <img src={TimesIcon} alt="remove" />
                                    </button>
                                    {renderProps?.values?.Packaging &&
                                      typeof renderProps?.values?.Packaging === 'string' ? (
                                        <img
                                          src={API_URL.concat(`/${renderProps.values.Packaging}`)}
                                          alt=""
                                          className="img-fluid prev_image"
                                        />
                                      ) : (
                                        renderProps?.values?.Packaging && (
                                          <FilePreview file={renderProps.values.Packaging[0]} />
                                        )
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="rect_box_wrapper">
                              <div className="rect_box_title mb-2">
                                {t('QR Code')}
                                <span className="d-inline-block pl-3 text-muted font-weight-normal font-smallest text-capitalize">
                                  {t('Optional')}
                                </span>
                              </div>
                              <div className="rect_box">
                                <input
                                  type="file"
                                  name="qRCodeImage"
                                  id="qRCodeImage"
                                  className="d-none"
                                  accept="image/*"
                                  onBlur={renderProps.handleBlur}
                                  onChange={(ev) => {
                                    renderProps.setFieldValue('qRCodeImage', ev.currentTarget.files);
                                  }}
                                />
                                <label htmlFor="qRCodeImage" className="rect-lbl">
                                  +
                                </label>
                                {renderProps?.values?.qRCodeImage && (
                                  <div className="preview_container">
                                    {(!productId || renderProps.values.Draft || parentProductId) && (
                                      <button
                                        onClick={(ev) => {
                                          renderProps.setFieldValue('qRCodeImage', undefined);
                                          document.getElementById('qRCodeImage').value = '';
                                        }}
                                        type="button"
                                        className="preview_close"
                                      >
                                        <img src={TimesIcon} alt="remove" />
                                      </button>
                                    )}
                                    {renderProps?.values?.qRCodeImage &&
                                      typeof renderProps?.values?.qRCodeImage === 'string' ? (
                                        <img
                                          src={API_URL.concat(`/${renderProps.values.qRCodeImage}`)}
                                          alt=""
                                          className="img-fluid prev_image"
                                        />
                                      ) : (
                                        renderProps?.values?.qRCodeImage && (
                                          <FilePreview file={renderProps.values.qRCodeImage[0]} />
                                        )
                                      )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <Container>
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      <FormGroup as={Row}>
                        <FormLabel column lg={5} className="rect_box_title pt-0">
                          {t('Ingredients s')}
                        </FormLabel>
                        <Col lg={7}>
                          {renderProps.values.ingredients &&
                            renderProps.values.ingredients.map((item, index) => (
                              <div className="custom-text-area-group-del" key={index}>
                                <FormControl
                                  type="text"
                                  onFocus={() => {
                                    { console.log("DEBUT INGREDIENT") }
                                    { console.log("renderProps.values.ingredients", renderProps.values.ingredients) }
                                    { console.log("ingredientList", ingredientList) }
                                    { console.log("FIN INGREDIENT") }
                                    this.setState({ enableIngredientAutoSuggestion: true });
                                  }}
                                  required
                                  autoComplete="off"
                                  value={
                                    item && ingredientList
                                      ? ingredientList.find((ingredientOne) => item === ingredientOne._id)
                                        ? ingredientList.find((ingredientOne) => item === ingredientOne._id).Nom
                                        : item
                                      : ''
                                  }
                                  onChange={(ev) => {
                                    let ingredientsNew = renderProps.values.ingredients;
                                    ingredientsNew[index] = ev.currentTarget.value;
                                    renderProps.setFieldValue('ingredients', ingredientsNew);
                                    console.log("ON CHANGE ingredientsNew", ingredientsNew)
                                  }}
                                  readOnly={!!productId && !renderProps.values.Draft && !parentProductId}
                                />
                                {(!productId || renderProps.values.Draft || parentProductId) && (
                                  <img
                                    className="close-icon"
                                    src={TimesColorIcon}
                                    alt=""
                                    onClick={() => {
                                      let ingredientsNew = renderProps.values.ingredients;
                                      ingredientsNew.splice(index, 1);
                                      renderProps.setFieldValue('ingredients', ingredientsNew);
                                    }}
                                  />
                                )}
                                {this.state.enableIngredientAutoSuggestion && (
                                  <div className="pops-auto-complete">
                                    {ingredientList &&
                                      ingredientList
                                        .filter(
                                          (filterItem) =>
                                            !renderProps.values.ingredients.find(
                                              (ingredientOne) => ingredientOne === filterItem._id,
                                            ),
                                        )
                                        .map(
                                          (oneIngredient, key) =>
                                            oneIngredient.Nom.search(
                                              new RegExp(renderProps.values.ingredients[index], 'i'),
                                            ) > -1 && (
                                              <div
                                                key={key}
                                                className="pops-auto-complete-item"
                                                onClick={() => {
                                                  console.log("ingredients", renderProps.values.ingredients)
                                                  const ingredientsNew = renderProps.values.ingredients;
                                                  ingredientsNew[index] = oneIngredient._id;
                                                  renderProps.setFieldValue('ingredients', ingredientsNew);
                                                  this.setState({
                                                    enableIngredientAutoSuggestion: false,
                                                  });
                                                }}
                                              >
                                                {oneIngredient.Nom}
                                              </div>
                                            ),
                                        )}
                                  </div>
                                )}
                              </div>
                            ))}
                          {(!productId || renderProps.values.Draft || parentProductId) && (
                            <div>
                              <button
                                type="button"
                                className="action_btn_with_txt font-weight-normal"
                                onClick={(e) => {
                                  renderProps.setFieldValue(
                                    'ingredients',
                                    (renderProps.values.ingredients || []).concat(['']),
                                  );
                                }}
                              >
                                <i className="action-icon rounded-circle mr-1">
                                  <img src={AddIcon} alt="Add" />
                                </i>
                                {t('Add an ingredient')}
                              </button>
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Container>




                {/* ------------------  ------------------ */}
                {/* <Select defaultValue="lq vqleur eotoaeaiejrjr" style={{ width: 120 }} onChange={(e) => {console.log(e)}}>
                  <Option value="lq vqleur jqcl">Jack</Option>
                  <Option value="lq vqleur lucy">Lucy</Option>
                  <Option value="disabled" disabled>
                    Disabled
                  </Option>
                  <Option value="lq vqleur eotoaeaiejrjr">yiminghe</Option>
                </Select> */}



                {/* ------------------  ------------------ */}



                {/* ------------------  ------------------ */}
                {/* <Container>
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      <FormGroup as={Row}>
                        <FormLabel column lg={5} className="rect_box_title pt-0">
                          {t('Molecules')}
                        </FormLabel>
                        <Col lg={7}>
                          <FieldArray
                            name="molecules"
                            render={(arrayHelpers) => (
                              <>
                                {renderProps.values.molecules?.map((item, index) => {
                                  if (item) {
                                    return (
                                      <div className="custom-text-area-group-del" key={index}>
                                        <FormControl
                                          type="text"
                                          onFocus={() => {
                                            this.setState({ enableIngredientAutoSuggestion: true });
                                          }}
                                          autoComplete="off"
                                          value={(() => {

                                            let moleculesNames = null
                                            testItemList.map((row) => {
                                              if (row.molecules.length && row && item)
                                                row.molecules.map(mole => {
                                                  if (mole._id == item && mole._id) {
                                                    moleculesNames = mole.name
                                                  }
                                                })
                                              else if (row._id === item && item) {
                                                moleculesNames = row.name
                                              }

                                            })

                                            if (item?.type === 'main' && item) {
                                              console.log("DANS LE MAIN")
                                              return testItemList?.find((testItemOne) => item?._id === testItemOne?._id)?.name;
                                            } else if (item?.type === 'sub' && item) {
                                              console.log("DANS LE SUB")
                                              let findDeepVal;
                                              const testItemName = testItemList?.find((testItemOne) => {
                                                if (testItemOne) {
                                                  findDeepVal = testItemOne?.molecules?.find(
                                                    (testItemM) => item?._id === testItemM?._id,
                                                  );
                                                  return Boolean(findDeepVal);
                                                }
                                              })?.name;
                                              return `${findDeepVal?.name} (${testItemName})`;
                                            } else if (item?.type === '' && item) {
                                              return '';
                                            }
                                            console.log("item", item)
                                            return moleculesNames;
                                          })()}
                                          // onChange={(ev) => {
                                          //   console.log("ON CHANGE moleculesNew 1", moleculesNew)
                                          //   let moleculesNew = renderProps.values.molecules;
                                          //   console.log("ON CHANGE moleculesNew 2", moleculesNew)
                                          //   moleculesNew[index] = ev.currentTarget.value;
                                          //   console.log("ON CHANGE moleculesNew 3", moleculesNew)
                                          //   renderProps.setFieldValue('molecules', moleculesNew);
                                          // }}
                                          onChange={(ev) => {
                                            console.log("ONCHNAGGGGGEEEEE");
                                            arrayHelpers.replace(index, ev.currentTarget.value)
                                          }}
                                          readOnly={!!productId && !renderProps.values.Draft && !parentProductId}
                                        />
                                        {(!productId || renderProps.values.Draft || parentProductId) && (
                                          <img
                                            className="close-icon"
                                            src={TimesColorIcon}
                                            alt=""
                                            onClick={() => {
                                              arrayHelpers.remove(index);
                                              let itemNew = renderProps.values.molecules;
                                              itemNew.splice(index, 1);
                                              renderProps.setFieldValue('molecules', itemNew);

                                            }}
                                          />
                                        )}
                                        {this.state.enableIngredientAutoSuggestion && (
                                          <div className="pops-auto-complete">
                                            {Object?.keys(groupByCategory)?.map((key) => (
                                              <div key={key}>
                                                <div className="pops-auto-complete-item">
                                                  {groupByCategory[key][0]?.testCategory?.name}
                                                </div>
                                                {groupByCategory[key]?.map(
                                                  (oneTestItem, keyS) =>
                                                    (typeof renderProps.values.molecules[index] == 'object' ||
                                                      oneTestItem?.name?.search(
                                                        new RegExp(renderProps.values.molecules[index], 'i'),
                                                      ) > -1) && (
                                                      <div key={keyS}>
                                                        <div
                                                          className="pops-auto-complete-item"
                                                          onClick={() => {
                                                            if (!(oneTestItem?.molecules?.length > 0)) {
                                                              arrayHelpers.replace(index, {
                                                                _id: oneTestItem._id,
                                                                type: 'main',
                                                                name: oneTestItem.name
                                                              });
                                                              this.setState({
                                                                enableIngredientAutoSuggestion: false,
                                                              });
                                                            }
                                                          }}
                                                        >
                                                          &nbsp; - &nbsp; {oneTestItem.name}
                                                        </div>
                                                        {oneTestItem?.molecules?.map((oneMolecule, mKey) => {
                                                          if (oneMolecule) {
                                                            return (
                                                              <div
                                                                key={mKey}
                                                                datawww={oneMolecule}
                                                                className="pops-auto-complete-item"
                                                                onClick={() => {
                                                                  arrayHelpers.replace(index, {
                                                                    _id: oneMolecule._id,
                                                                    type: 'sub',
                                                                    name: oneMolecule.name
                                                                  });
                                                                  this.setState({
                                                                    enableIngredientAutoSuggestion: false,
                                                                  });
                                                                  // console.log("MOLECULE AVANT ", renderProps.values.molecules);
                                                                  // if (renderProps.values.molecules[index]._id !== "") {
                                                                  //   alert("we")
                                                                  //   const itemNew = renderProps.values.molecules;
                                                                  //   itemNew[index] = oneMolecule._id;
                                                                  //   renderProps.setFieldValue('molecules', itemNew);
                                                                  // }
                                                                  // console.log("MOLECULE APRES ", renderProps.values.molecules);
                                                                  // renderProps.setFieldValue(
                                                                  //   'molecules',
                                                                  //   (renderProps.values.molecules || []));
                                                                  // console.log("MOLECULE APRES", renderProps.values.molecules)
                                                                }}
                                                              >
                                                                &nbsp; &nbsp; &nbsp; - &nbsp; {oneMolecule.name}
                                                              </div>
                                                            )
                                                          }
                                                        })}
                                                      </div>
                                                    ),
                                                )}
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                      </div>
                                    )
                                  }
                                })}
                                {(!productId || renderProps.values.Draft || parentProductId) && (
                                  <div>
                                    <button
                                      type="button"
                                      className="action_btn_with_txt font-weight-normal"
                                      onClick={(e) => {
                                        renderProps.setFieldValue(
                                          'molecules',
                                          (renderProps.values.molecules || []),
                                        );
                                        arrayHelpers.push({
                                          _id: '',
                                          type: '',
                                          name: ""
                                        })
                                      }}
                                    >
                                      <i className="action-icon rounded-circle mr-1">
                                        {console.log("-----------------------------------------------------------")}
                                        {console.log("MOLECULE du onclick d'en bas ", renderProps.values.molecules)}
                                        {console.log("TESTITEM d'en bas ", testItemList)}
                                        {console.log("---------------")}

                                        <img src={AddIcon} alt="Add" />
                                      </i>
                                      {t('Add a molecule')}
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Container> */}
                {/* ------------------ Fin de la fonctionnalité ------------------*/}


                {/* ------------------ NOUVELLE FONCTIONNLITE ------------------ */}
                <Container>
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      <FormGroup as={Row}>
                        <FormLabel column lg={5} className="rect_box_title pt-0">
                          {t('Molecules')}
                        </FormLabel>
                        <Col lg={4}>
                          <FieldArray
                            name="molecules"
                            render={(arrayHelpers) => (
                              <>

                                <Tree
                                  checkable
                                  selectable={true}
                                  onExpand={this.onExpand}
                                  expandedKeys={this.state.expandedKeys}
                                  autoExpandParent={this.state.autoExpandParent}
                                  onCheck={this.onCheck}
                                  checkedKeys={this.state.checkedKeys}
                                  onSelect={this.onSelect}
                                  selectedKeys={this.state.selectedKeys}
                                  treeData={molist2}
                                />

                                </>
                              )}
                              readOnly={!!productId && !renderProps.values.Draft && !parentProductId}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Container>
                {/* ------------------ Fin de la fonctionnalité ------------------*/}


                {/* // const molist = {}

// for (const item of testItemAllList)
// {

//   if (!molist.hasOwnProperty(item?.testCategory?.name))
//     molist[item?.testCategory?.name] = {}

//   if (!molist[item?.testCategory?.name].hasOwnProperty(item?.name))
//     molist[item?.testCategory?.name][item.name] = []

//   for (const molecule of item?.molecules)
//   {
//     molist[item?.testCategory?.name][item?.name].push(molecule?.name)
//   }
// }
// console.log("LE FINAL", molist)

// testItemAllList.map(item => {
//   console.log(item?.testCategory?.name)
//   console.log("    ", item?.name)
//   item.molecules.map(mole => {
//     console.log(mole.name)
//   })
// }) */}





                <div className="bg_block py-5 my-5 product-documents">
                  <Container>
                    <Row className="justify-content-center dispo">
                      <Col lg={8}>
                        <h3 className="page_head_2 mb-4">{t('PRODUCT DESCRIPTION')}</h3>
                        <Row className="mt-3">
                          <FieldArray name="Documentations">
                            {(arrayHelpers) => (
                              <>
                                {renderProps?.values?.Documentations?.map((DocumentationOne, index) => {
                                  return (
                                    <Col lg={3} key={index}>
                                      <div className="rect_box_wrapper">
                                        <div className="rect_box" style={{ height: '150px' }}>
                                          <div className="preview_container">
                                            <button
                                              onClick={() => arrayHelpers.remove(index)}
                                              type="button"
                                              className="preview_close"
                                            >
                                              <img src={TimesIcon} alt="remove" />
                                            </button>
                                            {DocumentationOne?.logo && typeof DocumentationOne?.logo === 'string' ? (
                                              <img
                                                src={API_URL.concat(`/${DocumentationOne?.logo}`)}
                                                alt=""
                                                className="img-fluid prev_image"
                                              />
                                            ) : (
                                                DocumentationOne?.logo && <FilePreview file={DocumentationOne?.logo} />
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="pt-3 pb-4">
                                        {!DocumentationOne?.certificate ? (
                                          <>
                                            <input
                                              type="file"
                                              name={`Documentations.${index}.certificate`}
                                              id={`Documentations.${index}.certificate`}
                                              className="d-none documentationsCertificate"
                                              onChange={(ev) => {
                                                let newFiles = Array.from(ev.currentTarget.files);
                                                newFiles.map((item) =>
                                                  arrayHelpers.replace(index, {
                                                    logo: DocumentationOne?.logo,
                                                    certificate: item,
                                                    _id: DocumentationOne?._id,
                                                  }),
                                                );
                                              }}
                                              onBlur={renderProps.handleBlur}
                                            />
                                            <label
                                              htmlFor={`Documentations.${index}.certificate`}
                                              className="brand-color"
                                            >
                                              {t('Add a certificate')}
                                            </label>
                                          </>
                                        ) : (
                                            <div className="certificate-file-item">
                                              <span>
                                                {typeof DocumentationOne?.certificate === 'string'
                                                  ? DocumentationOne?.certificate
                                                    ?.split(/[\\]/)
                                                    ?.pop()
                                                    .substr(
                                                      0,
                                                      DocumentationOne?.certificate
                                                        ?.split(/[\\]/)
                                                        ?.pop()
                                                        .lastIndexOf('.'),
                                                    )
                                                  : DocumentationOne?.certificate &&
                                                  DocumentationOne?.certificate?.name?.substr(
                                                    0,
                                                    DocumentationOne?.certificate?.name?.lastIndexOf('.'),
                                                  )}
                                              </span>
                                              <button
                                                className="reset-btn"
                                                type="button"
                                                onClick={() =>
                                                  arrayHelpers.replace(index, {
                                                    logo: DocumentationOne?.logo,
                                                    certificate: null,
                                                    _id: DocumentationOne?._id,
                                                  })
                                                }
                                              >
                                                <img src={TimesColorIcon} alt="" />
                                              </button>
                                            </div>
                                          )}
                                      </div>
                                    </Col>
                                  );
                                })}
                                <Col lg={3}>
                                  <div className="rect_box_wrapper">
                                    <div className="rect_box" style={{ height: '150px' }}>
                                      <input
                                        type="file"
                                        name="Documentations"
                                        id="Documentations"
                                        className="d-none"
                                        accept="image/*"
                                        onChange={(ev) => {
                                          let newFiles = Array.from(ev.currentTarget.files);
                                          newFiles.map((item) =>
                                            arrayHelpers.push({
                                              logo: item,
                                              certificate: null,
                                              _id: '',
                                            }),
                                          );
                                        }}
                                        onBlur={renderProps.handleBlur}
                                        multiple
                                      />
                                      <label htmlFor="Documentations" className="rect-lbl">
                                        +
                                      </label>
                                    </div>
                                  </div>
                                </Col>
                              </>
                            )}
                          </FieldArray>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <Container>
                  <Row className="justify-content-center mb-3">
                    <Col lg={8}>
                      <h3 className="page_head_2 mb-1">{t('AUTHORIZED PARTNERS')}</h3>
                      <p>{t('List of partners authorized to view information for this product')}</p>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <Row className="justify-content-center mb-5">
                    {groupedPartnerList &&
                      Object.keys(groupedPartnerList)?.map((key, indexGroup) => (
                        <Col key={key} lg={4}>
                          <h5 className="group-head mb-4">{t(key + '_p')}</h5>
                          {groupedPartnerList[key]?.map(
                            (onePartner, index) =>
                              (!productId ||
                                renderProps.values.Draft ||
                                parentProductId ||
                                (renderProps.values.partners &&
                                  renderProps.values.partners.indexOf(onePartner._id) !== -1)) && (
                                <Form.Group key={index} className="custom-checkbox-group">
                                  <Form.Check
                                    custom
                                    label={onePartner.Nom}
                                    className="checkbox-partenaire"
                                    id={'partners' + indexGroup + index}
                                    value={onePartner._id}
                                    checked={
                                      renderProps.values.partners &&
                                      renderProps.values.partners.indexOf(onePartner._id) !== -1
                                    }
                                    onChange={(ev) => {
                                      if (!productId || renderProps.values.Draft || parentProductId) {
                                        const { checked, value } = ev.target;
                                        let existingPartners = renderProps.values.partners.slice();
                                        if (checked) {
                                          existingPartners.push(value);
                                        } else {
                                          existingPartners = removeArrayItemByValue(existingPartners, value);
                                        }
                                        renderProps.setFieldValue('partners', existingPartners);
                                      }
                                    }}
                                    onBlur={renderProps.handleBlur}
                                    readOnly={!!productId && !renderProps.values.Draft && !parentProductId}
                                  />
                                </Form.Group>
                              ),
                          )}
                        </Col>
                      ))}
                  </Row>
                </Container>
                <Container className="mb-5">
                  <div className="divider" />
                </Container>
                <Container>
                  <div>
                    {renderProps.errors.Categorie && renderProps.touched.Categorie ? (
                      <div className="alert alert-danger">{renderProps.errors.Categorie}</div>
                    ) : null}
                    {renderProps.errors.nameFR && renderProps.touched.nameFR ? (
                      <div className="alert alert-danger">{renderProps.errors.nameFR}</div>
                    ) : null}
                    {renderProps.errors.nameEN && renderProps.touched.nameEN ? (
                      <div className="alert alert-danger">{renderProps.errors.nameEN}</div>
                    ) : null}
                    {renderProps.errors.codeEAN && renderProps.touched.codeEAN ? (
                      <div className="alert alert-danger">{renderProps.errors.codeEAN}</div>
                    ) : null}
                    {renderProps.errors.dermatologicalTestDate && renderProps.touched.dermatologicalTestDate ? (
                      <div className="alert alert-danger">{renderProps.errors.dermatologicalTestDate}</div>
                    ) : null}
                    {renderProps.errors.averageMarket && renderProps.touched.averageMarket ? (
                      <div className="alert alert-danger">{renderProps.errors.averageMarket}</div>
                    ) : null}
                    {renderProps.errors.productImage && renderProps.touched.productImage ? (
                      <div className="alert alert-danger">{renderProps.errors.productImage}</div>
                    ) : null}
                    {renderProps.errors.Packaging && renderProps.touched.Packaging ? (
                      <div className="alert alert-danger">{renderProps.errors.Packaging}</div>
                    ) : null}
                    {renderProps.errors.lotImage && renderProps.touched.lotImage ? (
                      <div className="alert alert-danger">{renderProps.errors.lotImage}</div>
                    ) : null}
                    <AppStatus />
                  </div>
                  <div className="mb-5 mt-3 pb-5 text-right form-footer">
                    {this.props.app.loading ? (
                      <div className="spinner-wrapper">
                        <i className="fa fa-spin fa-spinner" />
                      </div>
                    ) : (
                        <>
                          {(!productId || renderProps.values.Draft) && (
                            <button
                              type="button"
                              className="btn btn-outline-custom"
                              onClick={(ev) => {
                                renderProps.setFieldValue('Draft', true);
                                renderProps.handleSubmit(ev);
                              }}
                            >
                              {t('Save as draft')}
                            </button>
                          )}
                          <button
                            type="button"
                            className="btn btn-custom "
                            onClick={(ev) => {
                              renderProps.setFieldValue('Draft', false);
                              renderProps.handleSubmit(ev);
                            }}
                          >
                            {!productId || renderProps.values.Draft
                              ? t('Create New Item')
                              : parentProductId
                                ? t('Version the product')
                                : t('Save file')}
                          </button>
                        </>
                      )}
                  </div>
                </Container>
              </form>
            )}
          </Formik>
        ) : (
            <div className="text-center mt-5">
              <div className="spinner-wrapper">
                <i className="fa fa-spin fa-spinner" />
              </div>
            </div>
          )}
      </>
    );
  }
}

ProductCreateModifySheet.propTypes = {
  t: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  createProductVersion: PropTypes.func.isRequired,
  deleteDraftProduct: PropTypes.func,
  modifyProductSheet: PropTypes.func,
  getProductDetails: PropTypes.func.isRequired,
  resetProductDetails: PropTypes.func.isRequired,
  getIngredients: PropTypes.func.isRequired,
  getPartners: PropTypes.func.isRequired,
  createProductSheet: PropTypes.func,
  resetAppSuccess: PropTypes.func.isRequired,
  partner: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  ingredient: PropTypes.object.isRequired,
  getTestItemAll: PropTypes.object.isRequired,
  testItemAll: PropTypes.object.isRequired,
  productId: PropTypes.string.isRequired,
  batchId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  app: state.app,
  partner: state.partner,
  ingredient: state.ingredient,
  product: state.product,
  testCategory: state.testCategory,
  testItemAll: state.testItem,
  batch: state.batch,
});

export default withTranslation()(
  connect(mapStateToProps, {
    getIngredients,
    getTestItemAll,
    getPartners,
    createProductSheet,
    resetAppSuccess,
    getProductDetails,
    resetProductDetails,
    modifyProductSheet,
    deleteDraftProduct,
    createProductVersion,
    batchDetailsModalShowToggle,
    getBatchDetails,
    resetBatchDetails,
    addBatchTestReport
  })(ProductCreateModifySheet),
);
