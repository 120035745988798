import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, ignoreRedirect, roles, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const { user, isAuthenticated } = auth;
      let redirectTo = '/';
      if (user && !user?.validateInformation) {
        redirectTo = '/InfoConfirmation';
      } else if (user && !user?.firstLogin) {
        redirectTo = '/PasswordChange';
      }
      return isAuthenticated && (!roles || roles?.find((role) => role === user?.userType)) ? (
        ignoreRedirect || (user && user?.firstLogin && user?.validateInformation) ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirectTo} />
        )
      ) : (
        <Redirect to="/login" />
      );
    }}
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  component: PropTypes.any.isRequired,
  roles: PropTypes.array,
  ignoreRedirect: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
