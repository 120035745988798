import setAuthToken from '../utils/setAuthToken';
import * as api from '../api.js';
import { APP_ERROR, APP_LOADING, APP_SUCCESS } from './types';
import { GET_ERRORS, SET_CURRENT_USER } from './types';
import { passwordChangeModalShow } from './passwordChangeModalActions';

export const registerUser = (userData, history) => async (dispatch) => {
  try {
    await api.registerUser(userData);
    history.push('/login');
  } catch (err) {
    console.log(err)
    dispatch({
      type: GET_ERRORS,
      payload: err.response,
    });
  }
};

export const loginUser = (userData) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.loginUser(userData);
    updateLocalStore(dispatch, res.data);
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Invalid Username or Password.'],
    });
  }
};

export const resetPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.resetPassword(email);
    dispatch({
      type: APP_SUCCESS,
      payload: [res?.data?.msg],
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      payload: [err?.response ? err?.response?.data?.msg : 'Something is wrong.'],
    });
  }
};

export const updateLocalStore = (dispatch, data) => {
  const { jwt } = data;
  localStorage.setItem('localSession', JSON.stringify(data));
  setAuthToken(jwt);
  dispatch(setCurrentUser(data));
  if (data?.changePassword) {
    passwordChangeModalShow(true)(dispatch);
  }
};

export const setCurrentUser = (payload) => {
  return {
    type: SET_CURRENT_USER,
    payload,
  };
};

export const logoutUser = () => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    await api.logoutUser();
    localStorage.removeItem('localSession');
    setAuthToken(false);
    dispatch(setCurrentUser(null));
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const validateSession = () => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    if (localStorage.localSession) {
      const localSession = JSON.parse(localStorage.localSession);
      setAuthToken(localSession.jwt);
      dispatch(setCurrentUser(localSession));
      const res = await api.validateSession();
      updateLocalStore(dispatch, res.data);
    }
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    localStorage.removeItem('localSession');
    setAuthToken(false);
    dispatch(setCurrentUser(null));
    console.log(err);
  }
};

export const firstLoginSuccessful = (history = undefined, redirect = '') => async (dispatch) => {
  try {
    await api.firstLoginSuccessful();
    await validateSession()(dispatch);
    if (history && redirect) {
      history.push(redirect);
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};
