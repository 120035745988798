import { APP_ERROR, APP_LOADING, APP_SUCCESS } from './types';

export const resetAppSuccess = () => async (dispatch) => {
  dispatch({
    type: APP_SUCCESS,
    payload: [],
  });
};

export const resetAppError = () => async (dispatch) => {
  dispatch({
    type: APP_ERROR,
    payload: [],
  });
};

export const setAppLoading = (status) => async (dispatch) => {
  dispatch({
    type: APP_LOADING,
    payload: status,
  });
};
