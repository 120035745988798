import React, { Component, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { batchDetailsModalShowToggle, getBatchDetails, resetBatchDetails, modifyBatch2 } from '../../actions/batchActions';
import { getTestItemAll } from '../../actions/testItemActions';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import { resetAppSuccess } from '../../actions/appActions';
import { Col, Modal, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import './style.scss';
import TimesColorIcon from '../../images/times_color.svg';
import RoundCheckMarkColor from '../../images/round-check-mark-color.svg';
import PendingDotGray from '../../images/pending-dot-gray.svg';
import DocIcon from '../../images/document.svg';
import GreenDotGroup from '../../images/green-dot-group.svg';
import OrangeDotGroup from '../../images/orange-dot-group.svg';
import RedDotGroup from '../../images/red-dot-group.svg';
import Roles from '../../utils/roles';
import config from '../../config';
import { Report } from '@material-ui/icons';
const { API_URL } = config;

class BatchDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFileList: null,
      afficherAlert:false, 
      afficherConfirm: false,
      disabled: true
    };
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.onClickHandle = this.onClickHandle.bind(this);
  }



  onChangeHandle(e) {
    console.log('FileList', e.target.files[0]);
    this.setState({
      selectedFileList: e.target.files[0],
      disabled: false
    })
  }
  

  onClickHandle(batchId, lotNumber, creationDate, analysisReportDate) {
    if (this.state.selectedFileList != null) {
      const formdata = new FormData()
      formdata.append('analysisReport', this.state.selectedFileList)
      formdata.append('lotNumber', lotNumber)
      formdata.append('creationDate', creationDate)
      formdata.append('analysisReportDate', analysisReportDate)
      axios.patch('http://localhost:4040/Batch/modif/' + batchId, formdata, {
      }).then(res => {
        console.log('Filelist', res.status)
        this.setState({
          afficherConfirm: true,
          afficherAlert: false,
          disabled:true
        })
      })
    } else return (
      this.setState({
        afficherAlert: true,
        afficherConfirm: false
      })
    )
  }

  //   var formdata = new FormData();
  // formdata.append("analysisReport", fileInput.files[0], "/C:/Users/Hydris/OneDrive/Documents/Hydris_ALL_docs_Yoga/QLAY/website asset/Calque 2.png");

  // var requestOptions = {
  //   method: 'PATCH',
  //   body: formdata,
  //   redirect: 'follow'
  // };

  // fetch("http://localhost:4040/Batch/modif/601593dde561e906c6b3d8e4", requestOptions)
  //   .then(response => response.text())
  //   .then(result => console.log(result))
  //   .catch(error => console.log('error', error));


  componentDidMount() {
    this.props.getTestItemAll();
  }


  render() {

    const { productDetails, t } = this.props;
    // const { productId } = this.props.match.params;
    const { batchDetailsModalShow, batchDetails } = this.props.batch;
    const testItemList = this.props?.testItem?.dataListAll;
    const totalTestItem = testItemList?.reduce(
      (acc, current) => acc + (current?.molecules?.length > 0 ? current?.molecules?.length : 1),
      0,
    );
    const testItemDetected = batchDetails?.testReports?.reduce(
      (acc, testReport) => acc + testReport?.testItemMolecules?.length + testReport?.testItems?.length,
      0,
    );
    const allLaboratories = productDetails?.Partners?.filter((item) => item?.Categorie === Roles.Laboratory);

    let calcWithCoefficient = 0;
    for (let l = 0; l < batchDetails?.testReports?.length; l++) {
      const singleTest = batchDetails?.testReports?.[l];
      for (let u = 0; u < singleTest?.testItemMolecules?.length; u++) {
        const testItemMolecule = singleTest?.testItemMolecules?.[u];
        console.log("X testItemMolecule", singleTest?.testItemMolecules?.[u])
        console.log("X testItemMolecules?.length", singleTest.testItemMolecules?.length)
        if (testItemMolecule?.TestItem?.testCategory?.coefficient) {
          calcWithCoefficient += testItemMolecule?.TestItem?.testCategory?.coefficient;
          console.log("X calcWithCoefficient", calcWithCoefficient)
        }
      }
      for (let u = 0; u < singleTest?.testItems?.length; u++) {
        const testItem = singleTest?.testItems?.[u];
        if (testItem?.testCategory?.coefficient) {
          calcWithCoefficient += testItem?.testCategory?.coefficient;
        }
      }
    }
    // console.log("X calcWithCoefficient", calcWithCoefficient)
    // console.log("X Total", totalTestItem)
    // console.log("X productDetails.Molecules.length", productDetails.Molecules.length)
    // console.log("X batchDetails?.testReports?.lengthh", batchDetails?.testReports?.length)
    const score = Math.round(((productDetails.Molecules.length - calcWithCoefficient) / productDetails.Molecules.length) * 100);
    // const score = Math.round(((batchDetails?.testReports?.length / productDetails.Molecules.length) * 100));

    let noteIcon = '';
    if (score <= 30) {
      noteIcon = RedDotGroup;
    } else if (score > 30 && score <= 70) {
      noteIcon = OrangeDotGroup;
    } else if (score > 70) {
      noteIcon = GreenDotGroup;
    }

    const modifyBatch2 = (batchId, payload, headers) => {
      axios.patch(API_URL.concat('/Batch/modif/' + batchId), payload, headers);
    }

    // const values = {
    //     lotNumber: batchDetails ? batchDetails.lotNumber : '',
    //     creationDate: batchDetails ? batchDetails.creationDate : '',
    //     analysisReport: batchDetails ? batchDetails.analysisReport : null,
    //     analysisReportDate: batchDetails ? batchDetails.analysisReportDate : '',
    //     product: productId,
    //     user: user?.id,
    //     partner: user?.partnerData?._id,
    //     analysisReportUser: user?.id,
    //     analysisReportPartner: user?.partnerData?._id,
    // }



    return (
      <Modal
        show={batchDetailsModalShow}
        onHide={this.props.batchDetailsModalShowToggle}
        className="brand-modal"
        size="lg"
      >
        <Modal.Header style={{ borderBottom: 0, paddingBottom: 0 }}>
          <Modal.Title />
          <button onClick={this.props.batchDetailsModalShowToggle} type="button" className="modal-close-btn">
            <img src={TimesColorIcon} alt="close" />
          </button>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          <div className="batch-details-con">
            <Row>
              <Col lg={6}>
                <div>
                  <div className="batch-title">{productDetails?.Categorie}</div>
                  <div className="batch-subtitle">{productDetails?.nameFR}</div>
                  <div className="batch-lot">
                    {t('Lot Number')} : {batchDetails?.lotNumber}
                  </div>
                  <div className="batch-log">
                    <div className="batch-log-item">
                      <img src={RoundCheckMarkColor} alt="" className="batch-log-mark" />
                      <div className="batch-log-date">
                        {t('Le')} {batchDetails?.creationDate}
                      </div>
                      <div className="batch-log-title">{t('CREATION OF BATCH')}</div>
                      <div className="batch-log-by">
                        {t('By')}
                        <div className="batch-log-by-title">{batchDetails?.partner?.Nom}</div>
                      </div>
                    </div>
                    <div className="batch-log-item">
                      {batchDetails?.analysisReportDate ? (
                        <>
                          <img src={RoundCheckMarkColor} alt="" className="batch-log-mark" />
                          <div className="batch-log-date">
                            {t('Le')} {batchDetails?.analysisReportDate}
                          </div>
                        </>
                      ) : (
                          <>
                            <img src={PendingDotGray} alt="" className="batch-log-mark" />
                            <div className="batch-log-date action-pending">{t('Pending')}</div>
                          </>
                        )}
                      <div className="batch-log-title">{t('Product quality control')}</div>
                      <div className="batch-log-by">
                        {t('By')}
                        <div className="batch-log-by-title">{batchDetails?.analysisReportPartner?.Nom}</div>
                      </div>

                      {batchDetails?.analysisReport && (
                        <>
                          <a
                            target="_blank"
                            href={API_URL + '/' + batchDetails?.analysisReport}
                            className="batch-log-doc"
                            rel="noopener noreferrer"
                          >
                            <img src={DocIcon} alt="" /> {t('Download the analysis report')}
                          </a>
                          <div className="rect_box_wrapper">
                            <div>
                              <br />
                              <input type="file" name="file" onChange={(e) => this.onChangeHandle(e)} />
                              <br />
                              <p> </p>
                              <button type="button" class="btn btn-primary" disabled={this.state.disabled} onClick={() => this.onClickHandle(batchDetails?._id, batchDetails?.lotNumber, batchDetails?.creationDate, batchDetails?.analysisReportDate)}>
                                Remplacer le rapport d'analyse
                              </button>
                              {this.state.afficherAlert && (
                                <div>
                                  <div class="alert alert-danger" role="alert">
                                    Une erreur est survenue
                                  </div>
                                </div>
                              )}
                              {this.state.afficherConfirm && (
                                <div>
                                  <div class="alert alert-success" role="alert">
                                    Le rapport à bien été remplacé !
                                  </div>
                                </div>
                              )}
                              {/* <DropzoneDialog
                                filesLimit={1}
                                cancelButtonText={"Annuler"}
                                submitButtonText={"Valider"}
                                maxFileSize={5000000}
                                open={this.state.open}
                                onClose={() => this.setState({open: false})}
                                onSave={(files) => {
                                  if (batchDetails._id) {
                                    modifyBatch2(batchDetails?._id, files);
                                    console.log("update reussi", files);
                                  }
                                }}
                                showPreviews={true}
                                showFileNamesInPreview={true}
                              /> */}
                            </div>
                          </div>
                        </>
                      )}
                      { }
                    </div>
                    {productDetails?.Partners?.filter((item) => item?.Categorie === Roles.Laboratory)?.map(
                      (singlePartner, index) => {
                        const testReport = batchDetails?.testReports?.find(
                          (item) => item?.partner?._id === singlePartner?._id,
                        );
                        return (
                          <div className="batch-log-item" key={index}>
                            {testReport ? (
                              <>
                                <img src={RoundCheckMarkColor} alt="" className="batch-log-mark" />
                                <div className="batch-log-date">
                                  {t('Le')} {testReport?.testAnalysisReportDate}
                                </div>
                              </>
                            ) : (
                                <>
                                  <img src={PendingDotGray} alt="" className="batch-log-mark" />
                                  <div className="batch-log-date action-pending">{t('Pending')}</div>
                                </>
                              )}
                            <div className="batch-log-title">{t('PRODUCT SECURITY ANALYSIS')}</div>
                            <div className="batch-log-by">
                              {t('By')}
                              <div className="batch-log-by-title">{singlePartner?.Nom}</div>
                            </div>
                            {testReport && (
                              <>
                                <div className="batch-log-report-title">{t('Molecules above the thresholds')} :</div>
                                {console.log(productDetails?.molecules)}
                                {(testReport?.testItemMolecules && testReport?.testItemMolecules?.length > 0) ||
                                  (testReport?.testItems && testReport?.testItems?.length > 0) ? (
                                    <ul className="batch-log-reports">
                                      {console.log(testReport?.testItemMolecules)}
                                      {testReport?.testItemMolecules?.map((testItemMolecule, testItemMoleculeIndex) => (
                                        <li key={testItemMoleculeIndex}>
                                          • {testItemMolecule?.name} ({testItemMolecule?.TestItem?.name}&nbsp;/&nbsp;
                                          {testItemMolecule?.TestItem?.testCategory?.name})
                                        </li>
                                      ))}
                                      {testReport?.testItems?.map((testItem, testItemIndex) => (
                                        <li key={testItemIndex}>
                                          • {testItem?.name} ({testItem?.testCategory?.name})
                                        </li>
                                      ))}
                                    </ul>
                                  ) : (
                                    <div className="batch-log-report-empty">{t('No')}</div>
                                  )}
                                {testReport?.testAnalysisReport && (
                                  <a
                                    target="_blank"
                                    href={API_URL + '/' + testReport?.testAnalysisReport}
                                    className="batch-log-doc"
                                    rel="noopener noreferrer"
                                  >
                                    <img src={DocIcon} alt="" /> {t('Download the analysis report')}
                                  </a>
                                )}
                              </>
                            )}
                          </div>
                        );
                      },
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="batch-note mt-4">
                  <h3 className="batch-note-title mb-3">{t('BATCH NOTE')}</h3>
                  {allLaboratories?.length === batchDetails?.testReports?.length ? (
                    <>
                      <div className="mb-3">
                        <img src={noteIcon} alt="" />
                      </div>
                      <h2 className="batch-note-score mb-1">{score}/100</h2>
                      <div className="batch-note-score-note">
                        ({productDetails.Molecules.length - testItemDetected}/{productDetails.Molecules.length} {t('residues not detected')})
                      </div>
                    </>
                  ) : (
                      <div className="batch-note-pending">{t('Analysis results is pending')}</div>
                    )}
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

BatchDetails.propTypes = {
  t: PropTypes.func,
  match: PropTypes.object,
  modifyBatch2: PropTypes.func.isRequired,
  getBatchDetails: PropTypes.func.isRequired,
  resetBatchDetails: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  batchDetailsModalShowToggle: PropTypes.func.isRequired,
  app: PropTypes.object.isRequired,
  batch: PropTypes.object.isRequired,
  productDetails: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  getTestItemAll: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,

};

const mapStateToProps = (state) => ({
  app: state.app,
  product: state.product,
  batch: state.batch,
  testItem: state.testItem,
  auth: state.auth,

});

export default withTranslation()(
  connect(mapStateToProps, {
    modifyBatch2,
    resetAppSuccess,
    batchDetailsModalShowToggle,
    getBatchDetails,
    resetBatchDetails,
    getTestItemAll,
  })(BatchDetails),
);
