import './style.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Roles from '../../utils/roles';

class PartnerItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    const { _id, Nom, Categorie, Localisation, Archived } = this.props.partner;
    return (
      <div className="col-lg-3 col-md-6">
        <div
          className="partner_item"
          onClick={() => {
            this.props.history.push('/PartnerDetails/' + _id);
          }}
        >
          <div className="partner_item_wrapper">
            <h6 className={'partner_item_category ' + (Archived === true ? 'category_archived' : '')}>
              {t(Categorie)}
            </h6>
            <h2 className="partner_item_mon">{Nom}</h2>
            {Categorie === Roles.Manufacturer && <p className="partner_item_description_partner">{Localisation}</p>}
          </div>
        </div>
      </div>
    );
  }
}

PartnerItem.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object,
  partner: PropTypes.object.isRequired,
};

export default withTranslation()(PartnerItem);
