import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { resetAppSuccess } from '../../actions/appActions';
import './style.scss';
import { Row, Col, Table } from 'react-bootstrap';
import PencilIcon from '../../images/pencil_color.svg';
import { getUsers } from '../../actions/profileActions';
import { passwordChangeModalShow } from '../../actions/passwordChangeModalActions';
import UserAccount from '../UserAccount';
import { isAdmin } from '../../utils/roleUtils';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    if (isAdmin()) {
      this.props.getUsers();
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const { t } = this.props;
    const { user } = this.props.auth;
    const { userList } = this.props.profile;
    return (
      <>
        <div className="top-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <button onClick={this.goBack} className="reset-btn top-bar-left-link">
                  <i>←</i> {t('Back')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-5 mb-5">
          <div className="text-center mb-4">
            <h1 className="page_head ">{t('Profile')}</h1>
          </div>
        </div>
        <div className="container">
          <Row className="justify-content-center">
            <Col md={8}>
              <Table responsive className="w-100 profile-table">
                <tbody>
                  <tr>
                    <th>{t('First name')}</th>
                    <td>{user?.firstName}</td>
                  </tr>
                  <tr>
                    <th>{t('Name')}</th>
                    <td>{user?.lastName}</td>
                  </tr>
                  <tr>
                    <th>{t('E-mail')}</th>
                    <td>{user?.email}</td>
                  </tr>
                  <tr>
                    <th>{t('Password')}</th>
                    <td>
                      <button
                        onClick={() => this.props.passwordChangeModalShow(true)}
                        type="button"
                        className="action_btn_with_txt"
                      >
                        ******
                        <img src={PencilIcon} alt="" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          {isAdmin() && <UserAccount userList={userList} />}
        </div>
      </>
    );
  }
}

Profile.propTypes = {
  t: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  app: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  passwordChangeModalShow: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
  profile: state.profile,
  auth: state.auth,
});

export default withTranslation()(
  connect(mapStateToProps, { getUsers, resetAppSuccess, passwordChangeModalShow })(Profile),
);
