import React, { Component } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import FilePreview from '../FilePreview';
import { addBatchTestReport, testReportAddModalShowToggle } from '../../actions/batchActions';
import { getTestItemAll } from '../../actions/testItemActions';
import { getProductDetails } from '../../actions/productActions';
import { resetAppSuccess } from '../../actions/appActions';
import { Container, Row, Col, FormGroup, FormControl, Modal, FormLabel } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { FieldArray, Formik } from 'formik';
import './style.scss';
import TimesIcon from '../../images/times.svg';
import TimesColorIcon from '../../images/times_color.svg';
import AddIcon from '../../images/add.svg';
import AppStatus from '../AppStatus';

const OPTIONS = ['Apples', 'Nails', 'Bananas', 'Helicopters'];
class BatchTestReportAddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableIngredientAutoSuggestion: false,
      value: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.getTestItemAll();
    const { productId } = this.props;
    if (productId) {
      this.props.getProductDetails(productId);
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }


  render() {
    const styleOfMain = {
      color: '#006646',
      fontSize: '15px'
    }
    const { Option } = Select;
    const { selectedItems } = this.state;
    const { productId, batchId, t } = this.props;
    const { productDetails } = this.props.product;
    const { testReportAddModalShow } = this.props.batch;
    const { user } = this.props.auth;
    const testItemList = this.props?.testItem?.dataListAll;
    const groupByCategory = testItemList?.reduce(function (rv, x) {
      (rv[x['testCategory']['_id']] = rv[x['testCategory']['_id']] || []).push(x);
      return rv;
    }, {});
    const QualityAnalysisReportSchema = yup.object().shape({
      molecules: yup.array().of(yup.mixed()),
      testAnalysisReport: yup.mixed().required(t('Please select an analysis report')),
      testAnalysisReportDate: yup.string().required(t('Please enter a date')).matches(/^\d{1,2}\/\d{1,2}\/\d{4}$/, {
        excludeEmptyString: true,
        message: t('Please enter correct analysis report date'),
      }),
    });
    console.log("groupByCategory", groupByCategory)
    console.log("testItemList", testItemList)

    return (
      <Formik
        initialValues={{
          testAnalysisReport: '',
          testAnalysisReportDate: '',
          molecules: [],
          user: user?.id,
          partner: user?.partnerData?._id,
        }}
        validationSchema={QualityAnalysisReportSchema}
        onSubmit={(values, { resetForm }) => {
          const testItems = [],
            testItemMolecules = [];
          for (let h = 0; h < values?.molecules?.length; h++) {
            const i = values?.molecules?.[h];
            if (i?.type === 'main') {
              testItems.push(i?._id);
            } else if (i?.type === 'sub') {
              testItemMolecules.push(i?._id);
            }
          }
          this.props.addBatchTestReport(batchId, productId, { ...values, testItems, testItemMolecules });
          //this.props.qualityAnalysisReportAddModalShowToggle();
          resetForm();
        }}
      >
        {(renderProps) => (
          <Modal
            show={testReportAddModalShow}
            onHide={this.props.testReportAddModalShowToggle}
            className="brand-modal"
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>{t('Adding a report')}</Modal.Title>
              <button onClick={this.props.testReportAddModalShowToggle} type="button" className="modal-close-btn">
                <img src={TimesColorIcon} alt="close" />
              </button>
            </Modal.Header>
            <form onSubmit={renderProps.handleSubmit} onChange={this.props.resetAppSuccess}>
              <Modal.Body style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Container>
                  <Row className="">
                    <Col md={5}>
                      <label className="add_quality_label text-left mb-3">{t('Product safety analysis')}</label>
                      <div style={{ maxWidth: 205 }}>
                        <div className="rect_box_wrapper">
                          <div className="rect_box" style={{ height: 283, marginBottom: 10 }}>
                            <input
                              type="file"
                              name="testAnalysisReport"
                              id="testAnalysisReport"
                              className="d-none"
                              onBlur={renderProps.handleBlur}
                              onChange={(ev) => {
                                renderProps.setFieldValue('testAnalysisReport', ev.currentTarget.files);
                              }}
                            />
                            <label htmlFor="testAnalysisReport" className="rect-lbl">
                              +
                            </label>
                            {renderProps?.values?.testAnalysisReport && (
                              <div className="preview_container">
                                <button
                                  onClick={(ev) => {
                                    renderProps.setFieldValue('testAnalysisReport', undefined);
                                    document.getElementById('testAnalysisReport').value = '';
                                  }}
                                  type="button"
                                  className="preview_close"
                                >
                                  <img src={TimesIcon} alt="remove" />
                                </button>
                                <FilePreview file={renderProps.values.testAnalysisReport[0]} />
                              </div>
                            )}
                          </div>
                        </div>
                        <FormGroup controlId="testAnalysisReportDate">
                          <FormControl
                            type="text"
                            name="testAnalysisReportDate"
                            value={renderProps.values.testAnalysisReportDate}
                            onChange={(ev) => {
                              renderProps.handleChange(ev);
                            }}
                            onBlur={renderProps.handleBlur}
                            placeholder={t('DD/MM/YYYY')}
                          />
                        </FormGroup>
                      </div>
                    </Col>
                    <Col md={7}>
                      <FormGroup>
                        <FormLabel className="add_quality_label text-left mb-3">
                          {t('Molecule (s) above the thresholds')}
                        </FormLabel>
                        <FieldArray
                          name="molecules"
                          render={(arrayHelpers) => (
                            <>
                              {renderProps.values.molecules?.map((item, index) => (
                                <div className="custom-text-area-group-del" key={index}>
                                  <FormControl
                                    type="text"
                                    onFocus={() => {
                                      this.setState({ enableIngredientAutoSuggestion: true });
                                    }}
                                    autoComplete="off"
                                    value={(() => {
                                      if (item?.type === 'main') {
                                        return testItemList?.find((testItemOne) => item?._id === testItemOne?._id)
                                          ?.name;
                                      } else if (item?.type === 'sub') {
                                        let findDeepVal;
                                        const testItemName = testItemList?.find((testItemOne) => {
                                          findDeepVal = testItemOne?.molecules?.find(
                                            (testItemM) => item?._id === testItemM?._id,
                                          );
                                          return Boolean(findDeepVal);
                                        })?.name;
                                        return `${findDeepVal?.name} (${testItemName})`;
                                      } else if (item?.type === '') {
                                        return '';
                                      }
                                      return item;
                                    })()}
                                    onChange={(ev) => arrayHelpers.replace(index, ev.currentTarget.value)}
                                  />
                                  <img
                                    className="close-icon"
                                    src={TimesColorIcon}
                                    alt=""
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                      let itemNew = renderProps.values.molecules;
                                      itemNew.splice(index, 1);
                                      renderProps.setFieldValue('molecules', itemNew);

                                    }}
                                  />
                                  {this.state.enableIngredientAutoSuggestion && (
                                    <div className="pops-auto-complete">
                                      {Object?.keys(groupByCategory)?.map((key) => (
                                        <div key={key}>
                                          <div className="pops-auto-complete-item">
                                            {groupByCategory[key][0]?.testCategory?.name}
                                          </div>
                                          {groupByCategory[key]?.map(
                                            (oneTestItem, keyS) =>
                                              (typeof renderProps.values.molecules[index] == 'object' ||
                                                oneTestItem?.name?.search(
                                                  new RegExp(renderProps.values.molecules[index], 'i'),
                                                ) > -1) && (
                                                // (productDetails.Molecules.indexOf(oneTestItem._id) != -1) ?
                                                  <div key={keyS}>
                                                    {
                                                      productDetails.Molecules.indexOf(oneTestItem._id) != -1  ?
                                                      <div
                                                      className="pops-auto-complete-item"
                                                      onClick={() => {
                                                          arrayHelpers.replace(index, {
                                                            _id: oneTestItem._id,
                                                            type: 'main',
                                                            name: oneTestItem.name
                                                          });
                                                          this.setState({
                                                            enableIngredientAutoSuggestion: false,
                                                          });
                                                          console.log("item séléctionnée", oneTestItem.name)
                                                          
                                                        
                                                      }}
                                                      >
                                                      &nbsp; - &nbsp; {oneTestItem.name}
                                                    </div>
                                                    : ""
                                                    // : (oneTestItem?.molecules?.length > 0) && (productDetails.Molecules.indexOf(oneTestItem.molecules._id)) ?
                                                    // <div
                                                    //   className="pops-auto-complete-item"
                                                    //   onClick={() => {
                                                    //       arrayHelpers.replace(index, {
                                                    //         _id: oneTestItem._id,
                                                    //         type: 'main',
                                                    //         name: oneTestItem.name
                                                    //       });
                                                    //       this.setState({
                                                    //         enableIngredientAutoSuggestion: false,
                                                    //       });
                                                    //       console.log("item séléctionnée", oneTestItem.name)
                                                          
                                                        
                                                    //   }}
                                                    //   >
                                                    //   &nbsp; - &nbsp; {oneTestItem.name}
                                                    // </div>
                                                    // : ""
                                                    }


                                                    {oneTestItem?.molecules?.map((oneMolecule, mKey) => (
                                                      productDetails.Molecules.indexOf(oneMolecule._id) != -1 ?
                                                        <div
                                                          key={mKey}
                                                          className="pops-auto-complete-item"
                                                          onClick={() => {
                                                            if ((oneTestItem?.molecules?.length > 0)) {
                                                              console.log("deuxième if")
                                                              arrayHelpers.replace(index, {
                                                                _id: oneMolecule._id,
                                                                type: 'sub',
                                                                name: oneMolecule.name
                                                              });
                                                              this.setState({
                                                                enableIngredientAutoSuggestion: false,
                                                              });
                                                              console.log("mole séléctionnée", oneMolecule.name)
                                                            }
                                                          }}
                                                        >
                                                          <div style={styleOfMain}>{oneTestItem.name} </div>&nbsp; - &nbsp; {oneMolecule.name}
                                                        </div>
                                                        : ""

                                                    ))}
                                                  </div> 
                                              
                                              ),
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ))}
                              <div>
                                <button
                                  type="button"
                                  className="action_btn_with_txt font-weight-normal"
                                  onClick={(e) =>
                                    arrayHelpers.push({
                                      _id: '',
                                      type: '',
                                      name: ""
                                    })
                                  }
                                >
                                  <i className="action-icon rounded-circle mr-1">
                                    {console.log("MOLECULE du onclick d'en bas ", renderProps.values.molecules)}
                                    <img src={AddIcon} alt="Add" />
                                  </i>
                                  {t('Add a molecule')}
                                </button>
                              </div>
                            </>
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="">
                    {renderProps.errors.testAnalysisReport && renderProps.touched.testAnalysisReport ? (
                      <div className="alert alert-danger">{renderProps.errors.testAnalysisReport}</div>
                    ) : null}
                    {renderProps.errors.testAnalysisReportDate && renderProps.touched.testAnalysisReportDate ? (
                      <div className="alert alert-danger">{renderProps.errors.testAnalysisReportDate}</div>
                    ) : null}
                    <AppStatus />
                  </div>
                </Container>

                {/* {---------------------------------------------------------------} */}

              </Modal.Body>
              <Modal.Footer>
                {this.props.app.loading ? (
                  <div className="spinner-wrapper">
                    <i className="fa fa-spin fa-spinner" />
                  </div>
                ) : (
                    <button type="submit" className="btn btn-custom-common px-3">
                      {t('Add')}
                    </button>
                  )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    );
  }
}

BatchTestReportAddModal.propTypes = {
  t: PropTypes.func,
  getTestItemAll: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  testReportAddModalShowToggle: PropTypes.func.isRequired,
  addBatchTestReport: PropTypes.func,
  app: PropTypes.object.isRequired,
  productId: PropTypes.string.isRequired,
  batchId: PropTypes.string.isRequired,
  batch: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  testCategory: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  getProductDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
  product: state.product,
  batch: state.batch,
  auth: state.auth,
  testCategory: state.testCategory,
  testItem: state.testItem,
});

export default withTranslation()(
  connect(mapStateToProps, { getProductDetails, resetAppSuccess, addBatchTestReport, testReportAddModalShowToggle, getTestItemAll })(
    BatchTestReportAddModal,
  ),
);
