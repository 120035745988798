import * as api from '../api.js';
import {
  APP_ERROR,
  APP_LOADING,
  APP_SUCCESS,
  INGREDIENT_LIST,
  INGREDIENT_SEARCH,
  INGREDIENT_CURRENT_PAGE,
  INGREDIENT_ARCHIVED_STATUS,
  INGREDIENT_DETAILS,
} from './types';

export const createIngredient = (ingredientData, history) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.createIngredient(ingredientData);
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data?.msg],
    });
    if (res.data?.ingredient?._id) {
      const res2 = await api.getIngredientDetails(res.data?.ingredient?._id);
      dispatch({
        type: INGREDIENT_DETAILS,
        payload: res2.data,
      });
      history.push(`/IngredientModify/${res.data?.ingredient?._id}`);
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const getIngredients = () => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.getIngredients();
    dispatch({
      type: INGREDIENT_LIST,
      payload: res.data.ingredient,
    });
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const archiveIngredientToggle = (ingredientId) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    await api.archiveIngredientToggle(ingredientId);
    await getIngredients()(dispatch);
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const setIngredientSearchKey = (SearchKey) => async (dispatch) => {
  dispatch({
    type: INGREDIENT_SEARCH,
    payload: SearchKey,
  });
};

export const setIngredientCurrentPage = (currentPage) => async (dispatch) => {
  dispatch({
    type: INGREDIENT_CURRENT_PAGE,
    payload: currentPage,
  });
};

export const setIngredientArchivedStatus = (archivedStatus) => async (dispatch) => {
  dispatch({
    type: INGREDIENT_ARCHIVED_STATUS,
    payload: archivedStatus,
  });
};

export const resetIngredientDetails = () => (dispatch) => {
  dispatch({
    type: INGREDIENT_DETAILS,
    payload: null,
  });
};

export const getIngredientDetails = (ingredientId) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.getIngredientDetails(ingredientId);
    dispatch({
      type: INGREDIENT_DETAILS,
      payload: res.data,
    });
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const modifyIngredient = (ingredientId, ingredientData) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.modifyIngredient(ingredientId, ingredientData);
    const res2 = await api.getIngredientDetails(ingredientId);
    dispatch({
      type: INGREDIENT_DETAILS,
      payload: res2.data,
    });
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};
