import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icon.min.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { validateSession } from './actions/authActions';
import './App.scss';
import Register from './component/Register';
import Login from './component/Login';
import Headerbar from './component/Headerbar';
import PrivateRoute from './component/PrivateRoute';
import Dashboard from './component/Dashboard';
import Sidebar from './component/Sidebar';
import ProductCreateModifySheet from './component/ProductCreateModifySheet';
import ProductDetails from './component/ProductDetails';
import PartnerCreateModify from './component/PartnerCreateModify';
import PartnerList from './component/PartnerList';
import IngredientCreateModify from './component/IngredientCreateModify';
import IngredientList from './component/IngredientList';
import Profile from './component/Profile';
import PartnerDetails from './component/PartnerDetails';
import ActiveProductList from './component/ActiveProductList';
import ArchivedProductList from './component/ArchivedProductList';
import PasswordChangeModal from './component/PasswordChangeModal';
import ConsumerSiteKnowMore from './component/ConsumerSiteKnowMore';
import ConsumerSiteScoringMethod from './component/ConsumerSiteScoringMethod';
import InfoConfirmation from './component/InfoConfirmation';
import PasswordChange from './component/PasswordChange';
import TestCategoryCreateModify from './component/TestCategoryCreateModify';
import TestCategoryList from './component/TestCategoryList';
import TestItemCreateModify from './component/TestItemCreateModify';
import TestItemList from './component/TestItemList';
import BatchCreateModify from './component/BatchCreateModify';
import ResetPassword from './component/ResetPassword';
import classes from 'classnames';
import { isAdmin } from './utils/roleUtils';
import Roles from './utils/roles';
import ScrollToTop from './component/ScrollToTop';

class App extends Component {
  constructor(props) {
    super(props);
    props.validateSession();
  }
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <div
          className={classes('App', {
            'sidebar-yes': isAdmin(),
            'sidebar-no': !isAdmin(),
          })}
        >
          <PasswordChangeModal />
          <Headerbar />
          {isAdmin() && <Sidebar />}
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Switch>
            <PrivateRoute
              exact
              path="/InfoConfirmation"
              component={InfoConfirmation}
              ignoreRedirect
              roles={[Roles.Manufacturer]}
            />
            <PrivateRoute exact path="/PasswordChange" component={PasswordChange} ignoreRedirect />
            <PrivateRoute exact path="/Profile" component={Profile} />
            <PrivateRoute exact path="/ConsumerSiteKnowMore" component={ConsumerSiteKnowMore} roles={[Roles.Admin]} />
            <PrivateRoute exact path="/ConsumerSiteScoringMethod" component={ConsumerSiteScoringMethod} roles={[Roles.Admin]} />

            <PrivateRoute exact path="/ProductCreateSheet" component={ProductCreateModifySheet} roles={[Roles.Admin]} />
            <PrivateRoute
              exact
              path="/ProductModifySheet/:productId"
              component={ProductCreateModifySheet}
              roles={[Roles.Admin]}
            />
            <PrivateRoute
              exact
              path="/ProductVersionModifySheet/:productId/:parentProductId"
              component={ProductCreateModifySheet}
              roles={[Roles.Admin]}
            />
            <PrivateRoute exact path="/ProductListArchived" component={ArchivedProductList} roles={[Roles.Admin]} />
            <PrivateRoute exact path="/ProductList" component={ActiveProductList} />
            <PrivateRoute exact path="/ProductDetails/:productId" component={ProductDetails} />

            <PrivateRoute exact path="/PartnerCreateSheet" component={PartnerCreateModify} roles={[Roles.Admin]} />
            <PrivateRoute exact path="/PartnerList" component={PartnerList} roles={[Roles.Admin]} />
            <PrivateRoute
              exact
              path="/PartnerModify/:partnerId"
              component={PartnerCreateModify}
              roles={[Roles.Admin]}
            />
            <PrivateRoute
              exact
              path="/PartnerDetails/:partnerID/product/:productId"
              component={PartnerDetails}
              roles={[Roles.Admin]}
            />
            <PrivateRoute exact path="/PartnerDetails/:partnerID" component={PartnerDetails} roles={[Roles.Admin]} />

            <PrivateRoute exact path="/BatchCreate/:productId" component={BatchCreateModify} />
            <PrivateRoute exact path="/BatchModify/:productId/:batchId" component={BatchCreateModify} />

            <PrivateRoute exact path="/IngredientCreate" component={IngredientCreateModify} roles={[Roles.Admin]} />
            <PrivateRoute
              exact
              path="/IngredientModify/:ingredientId"
              component={IngredientCreateModify}
              roles={[Roles.Admin]}
            />
            <PrivateRoute exact path="/IngredientList" component={IngredientList} roles={[Roles.Admin]} />

            <PrivateRoute exact path="/TestCategoryCreate" component={TestCategoryCreateModify} roles={[Roles.Admin]} />
            <PrivateRoute
              exact
              path="/TestCategoryModify/:testCategoryId"
              component={TestCategoryCreateModify}
              roles={[Roles.Admin]}
            />
            <PrivateRoute exact path="/TestCategoryList" component={TestCategoryList} roles={[Roles.Admin]} />
            <PrivateRoute
              exact
              path="/TestCategory/:testCategoryId/TestItemCreate"
              component={TestItemCreateModify}
              roles={[Roles.Admin]}
            />
            <PrivateRoute
              exact
              path="/TestCategory/:testCategoryId/TestItemModify/:testItemId"
              component={TestItemCreateModify}
              roles={[Roles.Admin]}
            />
            <PrivateRoute
              exact
              path="/TestCategory/:testCategoryId/TestItemList"
              component={TestItemList}
              roles={[Roles.Admin]}
            />

            <PrivateRoute exact path="/" component={Dashboard} roles={[Roles.Admin]} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  app: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  validateSession: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
  auth: state.auth,
});

export default withTranslation()(connect(mapStateToProps, { validateSession })(App));
