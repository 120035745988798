import { TEST_CATEGORY_LIST, TEST_CATEGORY_SEARCH, TEST_CATEGORY_DETAILS } from '../actions/types';

const initialState = {
  dataList: [],
  filteredDataList: [],
  search: '',
  testCategoryDetails: null,
};

export default function (state = initialState, { type, payload }) {
  const filterSearchedTestCaegories = (dataList, search) => {
    return dataList.filter((testCategory) => {
      if (search.length === 0) {
        return true;
      } else {
        let payloadT = search.toLowerCase();
        return (
          testCategory.name.toLowerCase().search(payloadT) !== -1 ||
          testCategory.description.toLowerCase().search(payloadT) !== -1
        );
      }
    });
  };
  let filteredDataList = [];
  switch (type) {
    case TEST_CATEGORY_LIST:
      filteredDataList = payload;
      return {
        ...state,
        dataList: payload,
        filteredDataList: filteredDataList,
        pageCount: Math.ceil(filteredDataList.length / state.perPage),
      };

    case TEST_CATEGORY_SEARCH:
      filteredDataList = filterSearchedTestCaegories(state.dataList, payload);
      return {
        ...state,
        search: payload,
        filteredDataList: filteredDataList,
        pageCount: Math.ceil(filteredDataList.length / state.perPage),
        currentPage: 0,
        offset: 0,
      };
    case TEST_CATEGORY_DETAILS:
      return {
        ...state,
        testCategoryDetails: payload,
      };
    default:
      return { ...state };
  }
}
