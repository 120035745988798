import {
  TEST_ITEM_LIST,
  TEST_ITEM_SEARCH,
  TEST_ITEM_CURRENT_PAGE,
  TEST_ITEM_ARCHIVED_STATUS,
  TEST_ITEM_DETAILS,
  TEST_ITEM_ALL,
} from '../actions/types';

const initialState = {
  offset: 0,
  dataListAll: [],
  dataList: [],
  filteredDataList: [],
  perPage: 48,
  currentPage: 0,
  search: '',
  pageCount: 0,
  archivedStatus: false,
  testItemDetails: null,
};

export default function (state = initialState, { type, payload }) {
  const filterArchivedTestItems = (dataList, archivedStatus) => {
    return dataList.filter((testItem) => !testItem.archived || archivedStatus === testItem.archived);
  };
  const filterSearchedTestItems = (dataList, search) => {
    return dataList.filter((testItem) => {
      if (search.length === 0) {
        return true;
      } else {
        let payloadT = search.toLowerCase();
        return (
          testItem.name.toLowerCase().search(payloadT) !== -1 ||
          testItem.description.toLowerCase().search(payloadT) !== -1
        );
      }
    });
  };
  let filteredDataList = [];
  switch (type) {
    case TEST_ITEM_LIST:
      filteredDataList = filterArchivedTestItems(payload, state.archivedStatus);
      return {
        ...state,
        dataList: payload,
        filteredDataList: filteredDataList,
        pageCount: Math.ceil(filteredDataList.length / state.perPage),
      };

    case TEST_ITEM_SEARCH:
      filteredDataList = filterSearchedTestItems(state.dataList, payload);
      filteredDataList = filterArchivedTestItems(filteredDataList, state.archivedStatus);
      return {
        ...state,
        search: payload,
        filteredDataList: filteredDataList,
        pageCount: Math.ceil(filteredDataList.length / state.perPage),
        currentPage: 0,
        offset: 0,
      };
    case TEST_ITEM_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
        offset: payload * state.perPage,
      };
    case TEST_ITEM_ARCHIVED_STATUS:
      filteredDataList = filterSearchedTestItems(state.dataList, state.search);
      filteredDataList = filterArchivedTestItems(filteredDataList, payload);
      return {
        ...state,
        filteredDataList: filteredDataList,
        pageCount: Math.ceil(filteredDataList.length / state.perPage),
        currentPage: 0,
        offset: 0,
        archivedStatus: payload,
      };
    case TEST_ITEM_DETAILS:
      return {
        ...state,
        testItemDetails: payload,
      };
    case TEST_ITEM_ALL:
      return {
        ...state,
        dataListAll: payload,
      };
    default:
      return { ...state };
  }
}
