import axios from 'axios';
import config from './config.js';
const { API_URL } = config;

export const registerUser = (payload) => axios.post(API_URL.concat('/User/register'), payload);

export const loginUser = (payload) => axios.post(API_URL.concat('/User/login'), payload);

export const resetPassword = (payload) => axios.patch(API_URL.concat('/User/password-reset'), payload);

export const validateSession = () => axios.get(API_URL.concat('/User/validateSession'));

export const logoutUser = () => axios.post(API_URL.concat('/User/logout'));

export const createPartnerSheet = (payload, headers) => axios.post(API_URL.concat('/Partner/'), payload, headers);

export const modifyPartnerSheet = (partnerId, payload, headers) =>
  axios.patch(API_URL.concat('/Partner/' + partnerId), payload, headers);

export const getPartners = () => axios.get(API_URL.concat('/Partner/'));

export const getPartnerDetails = (partnerId) => axios.get(API_URL.concat('/Partner/' + partnerId));

export const archivePartnerToggle = (partnerId) =>
  axios.post(API_URL.concat('/Partner/archivePartnerToggle/' + partnerId));

export const createProductSheet = (payload, headers) => axios.post(API_URL.concat('/Product/'), payload, headers);

export const modifyProductSheet = (productId, payload, headers) =>
  axios.patch(API_URL.concat('/Product/' + productId), payload, headers);

export const createProductVersion = (productId, parentProductId, payload, headers) =>
  axios.post(API_URL.concat('/Product/' + productId + '/' + parentProductId), payload, headers);

export const getArchivedProducts = () => axios.get(API_URL.concat('/Product/archivedProducts'));

export const getActiveProducts = () => axios.get(API_URL.concat('/Product/activeProducts'));

export const getDraftProducts = () => axios.get(API_URL.concat('/Product/draftProducts'));

export const getProductDetails = (productId) => axios.get(API_URL.concat('/Product/' + productId));

export const archiveProductToggle = (productId) =>
  axios.patch(API_URL.concat('/Product/Archive_Product_Toggle/' + productId));

export const deleteDraftProduct = (productId) =>
  axios.delete(API_URL.concat('/Product/Delete_Draft_Product/' + productId));

export const createIngredient = (payload) => axios.post(API_URL.concat('/Ingredient/'), payload);

export const modifyIngredient = (ingredientId, payload) =>
  axios.patch(API_URL.concat('/Ingredient/' + ingredientId), payload);

export const getIngredients = () => axios.get(API_URL.concat('/Ingredient/'));

export const archiveIngredientToggle = (ingredientId) =>
  axios.post(API_URL.concat('/Ingredient/Archive_Ingredient_Toggle/' + ingredientId));

export const getIngredientDetails = (ingredientId) => axios.get(API_URL.concat('/Ingredient/' + ingredientId));

export const changePasswordByUserId = (userId, password) =>
  axios.patch(API_URL.concat('/User/changePassword/' + userId), { password });

export const changePassword = (password) => axios.patch(API_URL.concat('/User/changePassword/'), { password });

export const firstLoginSuccessful = () => axios.patch(API_URL.concat('/User/firstLoginSuccessful'), {});

export const getUsers = () => axios.get(API_URL.concat('/User/'));

export const deleteUser = (userId) => axios.delete(API_URL.concat('/User/deleteUser/' + userId));

export const addUser = (payload) => axios.post(API_URL.concat('/User'), payload);

export const modifyUser = (userId, payload) => axios.patch(API_URL.concat('/User/' + userId), payload);

export const addSiteInfo = (key, value) => axios.post(API_URL.concat('/site-info/' + key), { value });

export const getSiteInfo = () => axios.get(API_URL.concat('/site-info/'));

export const createTestCategory = (payload) => axios.post(API_URL.concat('/test-category/'), payload);

export const modifyTestCategory = (testCategoryId, payload) =>
  axios.patch(API_URL.concat('/test-category/' + testCategoryId), payload);

export const getTestCategories = () => axios.get(API_URL.concat('/test-category/'));

export const getTestCategoryDetails = (testCategoryId) => axios.get(API_URL.concat('/test-category/' + testCategoryId));

export const createTestItem = (testCategoryId, testItemData) =>
  axios.post(API_URL.concat(`/test-item/${testCategoryId}/`), testItemData);

export const modifyTestItem = (testCategoryId, testItemId, payload) =>
  axios.patch(API_URL.concat(`/test-item/${testCategoryId}/${testItemId}`), payload);

export const getTestItems = (testCategoryId) => axios.get(API_URL.concat(`/test-item/${testCategoryId}/`));

export const getTestItemAll = () => axios.get(API_URL.concat(`/test-item/`));

export const archiveTestItemToggle = (testCategoryId, testItemId) =>
  axios.patch(API_URL.concat(`/test-item/${testCategoryId}/archive-toggle/${testItemId}`));

export const getTestItemDetails = (testCategoryId, testItemId) =>
  axios.get(API_URL.concat(`/test-item/${testCategoryId}/${testItemId}`));

export const getBatchDetails = (batchId) => axios.get(API_URL.concat('/Batch/' + batchId));

export const createBatch = (payload, headers) => axios.post(API_URL.concat('/Batch/'), payload, headers);

export const modifyBatch = (batchId, payload, headers) =>
  axios.patch(API_URL.concat('/Batch/' + batchId), payload, headers);

export const modifyBatch2 = (batchId, payload, headers) =>
  axios.patch(API_URL.concat('/Batch/modif/' + batchId), payload, headers);

export const addBatchQualityReport = (batchId, payload, headers) =>
  axios.post(API_URL.concat('/Batch/QualityReport/' + batchId), payload, headers);

export const addBatchTestReport = (batchId, payload, headers) =>
  axios.post(API_URL.concat('/Batch/TestReport/' + batchId), payload, headers);

export const availableBatchToggle = (batchId) =>
  axios.patch(API_URL.concat('/Batch/' + batchId + '/batchAvailableToggle'));