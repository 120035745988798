import React, { Component } from 'react';
import ProductList from '../ProductList';
import './style.scss';

class ArchivedProductList extends Component {
  render() {
    return <ProductList />;
  }
}

export default ArchivedProductList;
