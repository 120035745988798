import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FilePreview from '../FilePreview';
import { addBatchQualityReport, qualityAnalysisReportAddModalShowToggle } from '../../actions/batchActions';
import { resetAppSuccess } from '../../actions/appActions';
import { Container, Row, Col, FormGroup, FormControl, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Formik } from 'formik';
import './style.scss';
import TimesIcon from '../../images/times.svg';
import TimesColorIcon from '../../images/times_color.svg';
import AppStatus from '../AppStatus';

class BatchQualityReportAddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { productId, batchId, t } = this.props;
    const { qualityAnalysisReportAddModalShow } = this.props.batch;
    const { user } = this.props.auth;
    const QualityAnalysisReportSchema = yup.object().shape({
      analysisReport: yup.mixed().required(t('Please select a quality report')),
      analysisReportDate: yup.string().required(t('Please enter a date')).matches(/^\d{1,2}\/\d{1,2}\/\d{4}$/, {
        excludeEmptyString: true,
        message: t('Please enter correct analysis report date'),
      }),
    });
    return (
      <Formik
        initialValues={{
          analysisReport: '',
          analysisReportDate: '',
          analysisReportUser: user?.id,
          analysisReportPartner: user?.partnerData?._id,
        }}
        validationSchema={QualityAnalysisReportSchema}
        onSubmit={(values, { resetForm }) => {
          this.props.addBatchQualityReport(batchId, productId, values);
          //this.props.qualityAnalysisReportAddModalShowToggle();
          resetForm();
        }}
      >
        {(renderProps) => (
          <Modal
            show={qualityAnalysisReportAddModalShow}
            onHide={this.props.qualityAnalysisReportAddModalShowToggle}
            className="brand-modal"
          >
            <Modal.Header>
              <Modal.Title>{t('Adding a report')}</Modal.Title>
              <button
                onClick={this.props.qualityAnalysisReportAddModalShowToggle}
                type="button"
                className="modal-close-btn"
              >
                <img src={TimesColorIcon} alt="close" />
              </button>
            </Modal.Header>
            <form onSubmit={renderProps.handleSubmit} onChange={this.props.resetAppSuccess}>
              <Modal.Body>
                <Container>
                  <label className="add_quality_label mb-3">{t('Product quality control')}</label>
                  <Row className="justify-content-center">
                    <Col md={5}>
                      <div className="rect_box_wrapper">
                        <div className="rect_box" style={{ height: 220, marginBottom: 10 }}>
                          <input
                            type="file"
                            name="analysisReport"
                            id="analysisReport"
                            className="d-none"
                            onBlur={renderProps.handleBlur}
                            onChange={(ev) => {
                              renderProps.setFieldValue('analysisReport', ev.currentTarget.files);
                            }}
                          />
                          <label htmlFor="analysisReport" className="rect-lbl">
                            +
                          </label>
                          {renderProps?.values?.analysisReport && (
                            <div className="preview_container">
                              <button
                                onClick={(ev) => {
                                  renderProps.setFieldValue('analysisReport', undefined);
                                  document.getElementById('analysisReport').value = '';
                                }}
                                type="button"
                                className="preview_close"
                              >
                                <img src={TimesIcon} alt="remove" />
                              </button>
                              <FilePreview file={renderProps.values.analysisReport[0]} />
                            </div>
                          )}
                        </div>
                      </div>
                      <FormGroup controlId="analysisReportDate">
                        <FormControl
                          type="text"
                          name="analysisReportDate"
                          value={renderProps.values.analysisReportDate}
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          placeholder={t('DD/MM/YYYY')}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="">
                    {renderProps.errors.analysisReport && renderProps.touched.analysisReport ? (
                      <div className="alert alert-danger">{renderProps.errors.analysisReport}</div>
                    ) : null}
                    {renderProps.errors.analysisReportDate && renderProps.touched.analysisReportDate ? (
                      <div className="alert alert-danger">{renderProps.errors.analysisReportDate}</div>
                    ) : null}
                    <AppStatus />
                  </div>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                {this.props.app.loading ? (
                  <div className="spinner-wrapper">
                    <i className="fa fa-spin fa-spinner" />
                  </div>
                ) : (
                  <button type="submit" className="btn btn-custom-common px-3">
                    {t('Add')}
                  </button>
                )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    );
  }
}

BatchQualityReportAddModal.propTypes = {
  t: PropTypes.func,
  resetAppSuccess: PropTypes.func.isRequired,
  qualityAnalysisReportAddModalShowToggle: PropTypes.func.isRequired,
  addBatchQualityReport: PropTypes.func,
  app: PropTypes.object.isRequired,
  productId: PropTypes.string.isRequired,
  batchId: PropTypes.string.isRequired,
  batch: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
  batch: state.batch,
  auth: state.auth,
});

export default withTranslation()(
  connect(mapStateToProps, { resetAppSuccess, addBatchQualityReport, qualityAnalysisReportAddModalShowToggle })(
    BatchQualityReportAddModal,
  ),
);
