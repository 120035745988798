import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { getProductDetails, archiveProductToggle } from '../../actions/productActions';
import { resetAppSuccess } from '../../actions/appActions';
import { connect } from 'react-redux';
import { Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.scss';
import PencilWhiteIcon from '../../images/pencil_white.svg';
import groupByArray from '../../utils/groupByArray';
import DeleteIcon from '../../images/delete_white_icon.svg';
import TimesColorIcon from '../../images/times_color.svg';
import DuplicateIcon from '../../images/duplicate_icon.svg';
import { withTranslation } from 'react-i18next';
import DocIcon from '../../images/document.svg';
import AddIcon from '../../images/add.svg';
import BatchList from '../BatchList';
import { isAdmin, isManufacturer, isManufacturerOrLaboratory } from '../../utils/roleUtils';
import config from '../../config';
import { getIngredientDetails } from '../../actions/ingredientActions';
import AppStatus from '../AppStatus';
const { API_URL } = config;
const { URL_ETH } = config;
class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProduitImage: true,
      showProduitLot: false,
      showProduitQRcode: false,
      Packaging: false,
      ingredientDetailsModalShow: false,
    };
    this.resetGallery = this.resetGallery.bind(this);
    this.handleProductArchiveModalClose = this.handleProductArchiveModalClose.bind(this);
    this.handleProductArchiveModalShow = this.handleProductArchiveModalShow.bind(this);
    this.handleIngredientDetailsModalClose = this.handleIngredientDetailsModalClose.bind(this);
    this.handleIngredientDetailsModalShow = this.handleIngredientDetailsModalShow.bind(this);
  }

  handleProductArchiveModalClose() {
    this.setState({ productArchiveModalShow: false });
  }

  handleProductArchiveModalShow() {
    this.setState({ productArchiveModalShow: true });
  }

  handleIngredientDetailsModalShow(ingredientId) {
    this.props.getIngredientDetails(ingredientId);
    this.setState({ ingredientDetailsModalShow: true });
  }

  handleIngredientDetailsModalClose() {
    this.setState({ ingredientDetailsModalShow: false });
  }

  componentDidMount() {
    const { productId } = this.props.match.params;
    if (productId) {
      this.props.getProductDetails(productId);
    }
  }

  resetGallery() {
    this.setState({
      showProduitImage: false,
      showProduitLot: false,
      showProduitQRcode: false,
      Packaging: false,
    });
  }

  render() {
    const { t } = this.props;
    const { productId } = this.props.match.params;
    const { productDetails } = this.props.product;
    const { dataListAll: testItemAllList } = this.props.testItemAll;
    const testItemList = this.props?.testItemAll?.dataListAll;
    let groupedPartnerList;
    if (productDetails?.Partners) {
      groupedPartnerList = groupByArray(productDetails.Partners, 'Categorie');
    }
    const { ingredientDetails } = this.props.ingredient;
    return (
      <Fragment>
        <Modal
          show={this.state.productArchiveModalShow}
          onHide={this.handleProductArchiveModalClose}
          className="brand-modal"
        >
          <Modal.Header>
            <Modal.Title>{t('Archiving')}</Modal.Title>
            <button onClick={this.handleProductArchiveModalClose} type="button" className="modal-close-btn">
              <img src={TimesColorIcon} alt="close" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="pop-msg-con mb-3">
              <p className="pop-msg-con-h">{t('productArchiveHead')}</p>
              <p className="mb-0"> {t('productArchivePara1')}</p>
              <p>{t('productArchivePara2')}</p>
            </div>
            <div className="">
              <AppStatus />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={this.handleProductArchiveModalClose}
              className="btn btn-custom-outline-common px-3 mr-2"
            >
              {t('Go back')}
            </button>
            {this.props.app.loading ? (
              <div className="spinner-wrapper">
                <i className="fa fa-spin fa-spinner" />
              </div>
            ) : (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      console.log(this.props.archiveProductToggle(productId))
                      this.props.archiveProductToggle(productId);
                      this.handleProductArchiveModalClose();
                      this.props.history.push('/ProductList');
                    }}
                    className="btn btn-custom-common px-3"
                  >
                    {t('Archive')}
                  </button>
                </>
              )}
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.ingredientDetailsModalShow}
          onHide={this.handleIngredientDetailsModalClose}
          className="brand-modal"
        >
          <Modal.Header className="pb-0 border-less">
            <Modal.Title>{ingredientDetails?.Nom}</Modal.Title>
            <button onClick={this.handleIngredientDetailsModalClose} type="button" className="modal-close-btn">
              <img src={TimesColorIcon} alt="close" />
            </button>
          </Modal.Header>
          <Modal.Body className="pt-0 pb-4">
            <div>
              <h6 className="ingredient_item_sous_titre mt-1">{ingredientDetails?.sousTitre}</h6>
              <h6 className="ingredient_item_sous_titre">{ingredientDetails?.Provenance}</h6>
              <p className="ingredient_item_description_ingredient">{ingredientDetails?.descriptionIngredient}</p>
            </div>
          </Modal.Body>
        </Modal>
        <div className="top-bar">
          <Container>
            <Row>
              <Col md={4}>
                <Link to="/ProductList" className="top-bar-left-link">
                  <i>←</i> {t('Return to product list')}
                </Link>
              </Col>
              <Col md={8} className="text-right">
                {isManufacturer() && (
                  <Link to={'/BatchCreate/' + productId} className="top-bar-right-link  float-right mr-1">
                    <i className="action-icon rounded-circle mr-2">
                      <img src={AddIcon} alt="Add" />
                    </i>
                    {t('Create a new batch')}
                  </Link>
                )}
                {isAdmin() && (
                  <>
                    <Link to={`/ProductModifySheet/${productDetails?._id}`} className="top-bar-right-link mr-3">
                      <i>
                        <img src={PencilWhiteIcon} alt="Add" />
                      </i>
                      {t('Modify the file')}
                    </Link>
                    <Link
                      to={`/ProductVersionModifySheet/${productDetails?._id}/${productDetails?.Parent?._id}`}
                      className="top-bar-right-link mr-3"
                    >
                      <img src={DuplicateIcon} alt="Duplicate" className="mr-2" />
                      {t('Create a new version')}
                    </Link>
                    <button
                      className="top-bar-right-link action_btn_with_txt"
                      type="button"
                      onClick={() => {
                        if (productDetails?.Archived) {
                          this.props.archiveProductToggle(productId);
                        } else {
                          this.handleProductArchiveModalShow();
                        }
                      }}
                    >
                      <i>
                        <img src={DeleteIcon} alt="archieve" />{' '}
                      </i>
                      {productDetails?.Archived ? t('Reactivate') : t('Archive the file')}
                    </button>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
        <div className="mt-5">
          <Container>
            <Row>
              <Col md={5}>
                {isAdmin() && (
                  <Row>
                    <Col xs={3}>
                      <div className="gallery-container">
                        {productDetails?.productImage && (
                          <img
                            src={API_URL + '/' + productDetails?.productImage}
                            onClick={() => {
                              this.resetGallery();
                              this.setState({ showProduitImage: true });
                            }}
                            alt=""
                          />
                        )}
                        {productDetails?.lotImage && (
                          <img
                            src={API_URL + '/' + productDetails?.lotImage}
                            onClick={() => {
                              this.resetGallery();
                              this.setState({ showProduitLot: true });
                            }}
                            alt=""
                          />
                        )}
                        {productDetails?.Packaging && (
                          <img
                            src={API_URL + '/' + productDetails?.Packaging}
                            onClick={() => {
                              this.resetGallery();
                              this.setState({ Packaging: true });
                            }}
                            alt=""
                          />
                        )}
                        {productDetails?.qRCodeImage && (
                          <img
                            src={API_URL + '/' + productDetails?.qRCodeImage}
                            onClick={() => {
                              this.resetGallery();
                              this.setState({ showProduitQRcode: true });
                            }}
                            alt=""
                          />
                        )}
                      </div>
                    </Col>
                    <Col xs={9}>
                      <div className="gallery-full-container">
                        {this.state.showProduitImage && productDetails?.productImage && (
                          <div className="gallery-full-item">
                            <div className="gallery-full-item-title">{t('Product picture')}</div>
                            <a
                              target="_blank"
                              href={API_URL + '/' + productDetails?.productImage}
                              rel="noopener noreferrer"
                            >
                              <img src={API_URL + '/' + productDetails?.productImage} alt="" />
                            </a>
                          </div>
                        )}
                        {this.state.showProduitLot && productDetails?.lotImage && (
                          <div className="gallery-full-item">
                            <div className="gallery-full-item-title">{t('Lot Number _o')}</div>
                            <a
                              target="_blank"
                              href={API_URL + '/' + productDetails?.lotImage}
                              rel="noopener noreferrer"
                            >
                              <img src={API_URL + '/' + productDetails?.lotImage} alt="" />
                            </a>
                          </div>
                        )}
                        {this.state.Packaging && productDetails?.Packaging && (
                          <div className="gallery-full-item">
                            <div className="gallery-full-item-title"> {t('Packaging')}</div>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={API_URL + '/' + productDetails?.Packaging}
                            >
                              <img src={API_URL + '/' + productDetails?.Packaging} alt="" />
                            </a>
                          </div>
                        )}
                        {this.state.showProduitQRcode && productDetails?.qRCodeImage && (
                          <div className="gallery-full-item">
                            <div className="gallery-full-item-title"> {t('QR Code')}</div>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={API_URL + '/' + productDetails?.qRCodeImage}
                            >
                              <img src={API_URL + '/' + productDetails?.qRCodeImage} alt="" />
                            </a>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
                {isManufacturerOrLaboratory() && (
                  <a
                    target="_blank"
                    href={API_URL + '/' + productDetails?.productImage}
                    rel="noopener noreferrer"
                    className="d-block product-single-image"
                  >
                    <img src={API_URL + '/' + productDetails?.productImage} alt="" className="img-fluid" />
                  </a>
                )}
              </Col>
              <Col md={7}>
                <div className="product-item-label mb-1">
                  {t('Version')} {productDetails?.Version}
                </div>
                <h1 className="product_item_category">{productDetails?.Categorie}</h1>
                <h6 className="product_item_name mb-4">{productDetails?.nameFR}</h6>
                <div className="product_item_table_c">
                  <Table>
                    <tbody>
                      <tr>
                        <th>{t('Blockchain ID')}</th>
                        <td className="id-blockchain"><a target="_blank" href={URL_ETH + '/' + productDetails?.productWalletAddress}>{productDetails?.productWalletAddress}</a></td>
                      </tr>
                      <tr>
                        <th>{t('Consumer URL')}</th>
                        <td>www.youvousdittout.fr/viewProduct/0{productDetails?.productIndex}</td>
                      </tr>
                      <tr>
                        <th>{t('EAN code')}</th>
                        <td>{productDetails?.codeEAN}</td>
                      </tr>
                      <tr>
                        <th>{t('SKU')}</th>
                        <td>{productDetails?.SKU}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                {isAdmin() && (
                  <>
                    <p className="dermatological-test-text mt-4 mb-0">
                      {productDetails?.dermatologicalTestReport && (
                        <a
                          target="_blank"
                          href={API_URL + '/' + productDetails?.dermatologicalTestReport}
                          className=""
                          rel="noopener noreferrer"
                        >
                          <img src={DocIcon} alt="" /> {t('Dermatological test')} -{' '}
                          {productDetails?.dermatologicalTestDate}
                        </a>
                      )}
                    </p>
                    <p className="average-market-text mt-4">
                      {t('Average market for similar products')} : <b>{productDetails?.averageMarket}/100</b>
                    </p>
                  </>
                )}
              </Col>
            </Row>
          </Container>
          {isAdmin() ? (
            <>
              <div className="product_details_bg_area">
                <Container>
                  <Row>
                    <Col md={4}>
                      <div>
                        <h4 className="product-details-item-header">{t('INGREDIENTS')}</h4>
                        <ul className="ingredient-list mt-3">
                          {productDetails?.Ingredients?.map((oneIngredient, index) => {
                           if (oneIngredient?.Nom) {
                              return (
                                <li key={index}>
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.handleIngredientDetailsModalShow(oneIngredient?._id);
                                  }}
                                  className="reset-btn"
                                  >
                                  <span>•</span>
                                  {oneIngredient?.Nom}
                                </button>
                              </li>
                            );
                          } else {return(<></>)};
                          })}
                        </ul>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        <h4 className="product-details-item-header">{t('MOLECULES')}</h4>
                        <ul className="ingredient-list mt-3">

                          {productDetails?.Molecules?.map((item, index) => {
                            let moleculesNames = null
                            testItemList.map((row) => {
                              if (row.molecules.length && row)
                              row.molecules.map(mole => {
                                console.log("row")
                                if (mole._id == item && mole._id) {
                                  console.log("mole")
                                  moleculesNames = mole.name
                                  console.log("moleculesNames", moleculesNames)
                                  }
                                })
                              else if (row._id === item && row._id)
                                moleculesNames = row.name
                              })
                              if (moleculesNames) {
                                  return (
                                    <li key={index}>
                                      <button
                                        type="text"
                                        className="reset-btn"
                                      >
                                        <span>•</span>
                                        {moleculesNames}
                                      </button>
                                    </li>
                                  );
                              }
                          })}
                        </ul>
                      </div>
                    </Col>
                    <Col md={4}>
                      <h4 className="product-details-item-header">{t('PRODUCT DESCRIPTION')}</h4>
                      <div className="mt-4">
                        <Row>
                          {productDetails?.Documentations?.map((DocumentationOne, index) => {
                            const { certificate, logo } = DocumentationOne;
                            return (
                              <Col md={3} className="mb-4" key={index}>
                                <div className="documentations-preview">
                                  <div className="documentations-preview-image">
                                    <img src={API_URL + '/' + logo} alt="" />
                                  </div>
                                  {certificate && (
                                    <a
                                      target="_blank"
                                      href={API_URL + '/' + certificate}
                                      className="documentations-preview-link"
                                      rel="noopener noreferrer"
                                    >
                                      {t('Certificate')}
                                    </a>
                                  )}
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Container>
                <div className="partenaire-autoriser">
                  <div className="pt-1">
                    <h4 className="product-details-item-header">{t('AUTHORIZED PARTNERS')}</h4>
                    <p>{t('List of partners authorized to view information for this product')}</p>
                  </div>
                  <ul className="partner-list mt-3 mb-5">
                    {groupedPartnerList &&
                      Object.keys(groupedPartnerList).map((key, indexGroup) => (
                        <li key={indexGroup}>
                          <span>
                            <span>{t(key + '_p')}</span>
                          </span>
                          {groupedPartnerList[key].map((onePartner, index) => (
                            <Fragment key={index}>
                              <Link to={`/PartnerDetails/${onePartner?._id}/product/${productId}`}>
                                {onePartner?.Nom}
                              </Link>
                              {groupedPartnerList[key].length > index + 1 && <>, </>}
                            </Fragment>
                          ))}
                        </li>
                      ))}
                  </ul>
                </div>
              </Container>
            </>
          ) : (
              <Container className="my-5">
                <div className="divider" />
              </Container>
            )}
          <Container>{productDetails && <BatchList productDetails={productDetails} />}</Container>
        </div>
      </Fragment>
    );
  }
}

ProductDetails.propTypes = {
  t: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  getProductDetails: PropTypes.func.isRequired,
  archiveProductToggle: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getIngredientDetails: PropTypes.func.isRequired,
  ingredient: PropTypes.object.isRequired,
  getTestItemAll: PropTypes.object.isRequired,
  testItemAll: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  product: state.product,
  app: state.app,
  auth: state.auth,
  ingredient: state.ingredient,  
  testItemAll: state.testItem,
});

export default withTranslation()(
  connect(mapStateToProps, { getProductDetails, resetAppSuccess, archiveProductToggle, getIngredientDetails })(
    ProductDetails,
  ),
);
