import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FilePreview from '../FilePreview';
import {
  createPartnerSheet,
  getPartnerDetails,
  resetPartnerDetails,
  modifyPartnerSheet,
} from '../../actions/partnerActions';
import { resetAppSuccess } from '../../actions/appActions';
import { Link } from 'react-router-dom';
import { Container, Row, Col, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Formik } from 'formik';
import './style.scss';
import TimesIcon from '../../images/times.svg';
import Roles from '../../utils/roles';
import config from '../../config';
import AppStatus from '../AppStatus';
import UserAccount from '../UserAccount';
const { API_URL } = config;

class PartnerCreateModify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userModalShow: false,
      userArrayHelper: null,
    };
    this.handleUserModalClose = this.handleUserModalClose.bind(this);
    this.handleUserModalShow = this.handleUserModalShow.bind(this);
  }

  componentDidMount() {
    const { partnerId } = this.props.match.params;
    if (partnerId) {
      this.props.getPartnerDetails(partnerId);
    } else {
      this.props.resetPartnerDetails();
    }
  }

  handleUserModalClose() {
    this.setState({ userModalShow: false });
  }

  handleUserModalShow() {
    this.setState({ userModalShow: true });
  }

  render() {
    const { t } = this.props;
    const { partnerDetails } = this.props.partner;
    const { partnerId } = this.props.match.params;

    yup.addMethod(yup.array, 'unique', function (message, mapper = (a) => a) {
      return this.test('unique', message, function (list) {
        return typeof list === 'undefined' || list.length < 2 || list.length === new Set(list.map(mapper)).size;
      });
    });

    const UserCreateSchema = yup.object().shape({
      lastName: yup.string().required(t('Please enter last name')),
      firstName: yup.string().required(t('Please enter first name')),
      email: yup.string().required(t('Please enter e-mail')).email(t('Please enter a valid e-mail')),
    });

    const PartnerSheetSchema = yup.object().shape({
      Categorie: yup.string().oneOf([Roles.Laboratory, Roles.Manufacturer]).required(t('Please select Categorie')),
      Nom: yup.string().required(t('Please enter Nom')),
      videoFabrication: yup.mixed(),
      User: yup
        .array()
        .of(UserCreateSchema)
        .unique(t('Please enter unique email.'), (a) => a.email),
      Localisation: yup
        .string()
        .notRequired()
        .when('Categorie', {
          is: (val) => {
            return val === Roles.Manufacturer;
          },
          then: yup.string().required(t('Please enter Localisation')),
          otherwise: yup.string().notRequired(),
        }),
    });

    return (
      <>
        <div className="top-bar">
          <Container>
            <Row>
              <Col md={6}>
                <Link to="/PartnerList" className="top-bar-left-link">
                  <i>←</i> {t('Back to the list of partners')}
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        {(partnerId && partnerDetails) || (!partnerId && !partnerDetails) ? (
          <>
            {/*
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
            }}
            validationSchema={UserCreateSchema}
            onSubmit={(values, { resetForm }) => {
              this.state.userArrayHelper.push(values);
              this.handleUserModalClose();
              resetForm();
            }}
            render={renderProps => (
              <Modal show={this.state.userModalShow} onHide={this.handleUserModalClose} className="brand-modal">
                <Modal.Header>
                  <Modal.Title>{t('Adding a user')}</Modal.Title>
                  <button onClick={this.handleUserModalClose} type="button" className="modal-close-btn"><img
                    src={TimesColorIcon} alt="close"/></button>
                </Modal.Header>
                <form onSubmit={renderProps.handleSubmit} onChange={this.props.resetAppSuccess}>
                  <Modal.Body>
                    <Container>
                      <div className="form-group row pr-3">
                        <label htmlFor="firstName" className="col-lg-3 col-form-label">{t('First name')}</label>
                        <input
                          className="form-control col-lg-9 "
                          type="text"
                          name="firstName"
                          id="firstName"
                          value={renderProps.values.firstName}
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          required
                        />
                      </div>
                      <div className="form-group row pr-3">
                        <label htmlFor="lastName" className="col-lg-3 col-form-label">{t('Name')}</label>
                        <input
                          className="form-control col-lg-9 "
                          type="text"
                          name="lastName"
                          id="lastName"
                          value={renderProps.values.lastName}
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          required
                        />
                      </div>
                      <div className="form-group row pr-3">
                        <label htmlFor="email" className="col-lg-3 col-form-label">{t('E-mail')}</label>
                        <input
                          className="form-control col-lg-9 "
                          type="email"
                          name="email"
                          id="email"
                          value={renderProps.values.email}
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          required
                        />
                      </div>
                      <div className="">
                        {renderProps.errors.firstName && renderProps.touched.firstName ? (
                          <div className="alert alert-danger">{renderProps.errors.firstName}</div>) : null}
                        {renderProps.errors.lastName && renderProps.touched.lastName ? (
                          <div className="alert alert-danger">{renderProps.errors.lastName}</div>) : null}
                        {renderProps.errors.email && renderProps.touched.email ? (
                          <div className="alert alert-danger">{renderProps.errors.email}</div>) : null}
                      </div>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn btn-custom-common px-5">{t('Add')}</button>
                  </Modal.Footer>
                </form>
              </Modal>
            )}
          />
          */}
            <Formik
              enableReinitialize
              initialValues={{
                Categorie: partnerDetails ? partnerDetails.Categorie : '',
                Nom: partnerDetails ? partnerDetails.Nom : '',
                Localisation: partnerDetails ? partnerDetails.Localisation : '',
                videoFabrication: partnerDetails ? partnerDetails.videoFabrication : null,
                User: partnerDetails && partnerDetails.Users.length > 0 ? partnerDetails.Users : [],
              }}
              validationSchema={PartnerSheetSchema}
              onSubmit={(values) => {
                if (partnerId) {
                  this.props.modifyPartnerSheet(partnerId, values);
                } else {
                  this.props.createPartnerSheet(values, this.props.history);
                }
              }}
            >
              {(renderProps) => (
                <form className="mt-5" onSubmit={renderProps.handleSubmit} onChange={this.props.resetAppSuccess}>
                  <Container>
                    <Row className="justify-content-center">
                      <Col lg={8}>
                        <div className="text-center mb-5">
                          <h5 className="page_head_sub">{partnerId ? t('Update') : t('Create a')}</h5>
                          <h1 className="page_head ">{partnerId ? t('PARTNER SHEET') : t('NEW PARTNER SHEET')}</h1>
                        </div>
                        <FormGroup as={Row} controlId="Categorie">
                          <FormLabel column lg={5}>
                            {t('Category')}
                          </FormLabel>
                          <Col lg={7}>
                            <FormControl
                              as="select"
                              name="Categorie"
                              required
                              onChange={(ev) => {
                                renderProps.handleChange(ev);
                              }}
                              onBlur={renderProps.handleBlur}
                              value={renderProps.values.Categorie}
                            >
                              <option value="">Select</option>
                              <option value={Roles.Manufacturer}>{t(Roles.Manufacturer)}</option>
                              <option value={Roles.Laboratory}>{t(Roles.Laboratory)}</option>
                            </FormControl>
                          </Col>
                        </FormGroup>
                        <FormGroup as={Row} controlId="Nom">
                          <FormLabel column lg={5}>
                            {t('Name')}
                          </FormLabel>
                          <Col lg={7}>
                            <FormControl
                              type="text"
                              name="Nom"
                              value={renderProps.values.Nom}
                              onChange={(ev) => {
                                renderProps.handleChange(ev);
                              }}
                              onBlur={renderProps.handleBlur}
                              required
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup as={Row} controlId="Localisation">
                          <FormLabel column lg={5}>
                            {t('Location')}
                          </FormLabel>
                          <Col lg={7}>
                            <FormControl
                              type="text"
                              name="Localisation"
                              value={renderProps.values.Localisation}
                              onChange={(ev) => {
                                renderProps.handleChange(ev);
                              }}
                              onBlur={renderProps.handleBlur}
                              required={Roles.Manufacturer === renderProps.values.Categorie}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Container>
                  <div className="bg_block py-5 mt-5">
                    <Container>
                      <Row className="justify-content-center">
                        <Col lg={8}>
                          <h3 className="page_head_2 mb-4">
                            {t('Manufacturing video de')}
                            <span className="d-inline-block pl-3 text-muted font-weight-normal font-smallest text-capitalize">
                              {t('Optional')}
                            </span>
                          </h3>
                          <Row className=" mt-3">
                            <Col lg={4}>
                              <div className="rect_box_wrapper">
                                <div className="rect_box" style={{ height: 145 }}>
                                  <input
                                    type="file"
                                    name="videoFabrication"
                                    id="videoFabrication"
                                    className="d-none"
                                    accept="video/mp4"
                                    onChange={(ev) => {
                                      renderProps.setFieldValue('videoFabrication', ev.currentTarget.files);
                                    }}
                                    onBlur={renderProps.handleBlur}
                                  />
                                  <label htmlFor="videoFabrication" className="rect-lbl">
                                    +
                                  </label>
                                  {renderProps?.values?.videoFabrication && (
                                    <div className="preview_container">
                                      <button
                                        onClick={(ev) => {
                                          renderProps.setFieldValue('videoFabrication', undefined);
                                          document.getElementById('videoFabrication').value = '';
                                        }}
                                        type="button"
                                        className="preview_close"
                                      >
                                        <img src={TimesIcon} alt="remove" />
                                      </button>
                                      {renderProps?.values?.videoFabrication &&
                                      typeof renderProps?.values?.videoFabrication === 'string' ? (
                                        <video
                                          controls
                                          src={API_URL.concat(`/${renderProps.values.videoFabrication}`)}
                                          className="img-fluid prev_video"
                                        />
                                      ) : (
                                        renderProps?.values?.videoFabrication && (
                                          <FilePreview file={renderProps.values.videoFabrication[0]} />
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <Container>
                    <Row className="justify-content-center ">
                      <Col lg={8}>
                        {partnerDetails && (
                          <UserAccount partnerData={partnerDetails} userList={partnerDetails?.Users} />
                        )}
                      </Col>
                    </Row>
                  </Container>
                  {/*
                 <Container>
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      <h3 className="page_head_2 mb-4"> {t('User accounts')}
                        <span
                          className="d-inline-block pl-3 text-muted font-weight-normal font-smallest text-capitalize">{t('Optional')}</span>
                      </h3>
                      <FieldArray
                        name="User"
                        render={arrayHelpers => (
                          <div className="mt-3">
                            <div className="mb-4">
                              <button type="button" className="action_btn_with_txt"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.handleUserModalShow();
                                        this.setState({
                                          userArrayHelper: arrayHelpers,
                                        });
                                      }}><i className="action-icon rounded-circle mr-1"><img src={AddIcon}
                                                                                             alt="Add"/></i> {t('Add user')}
                              </button>
                            </div>

                            {renderProps.values.User && renderProps.values.User.length > 0 && (
                              <Table responsive striped borderless hover className="w-100 brand-table">
                                <thead>
                                <tr>
                                  <th>{t('First name')}</th>
                                  <th>{t('Name')}</th>
                                  <th>{t('E-mail')}</th>
                                  <th/>
                                </tr>
                                </thead>
                                <tbody>
                                {renderProps.values.User.map((User, index) => (
                                  <tr key={index}>
                                    <td>{User.lastName}</td>
                                    <td>{User.firstName} </td>
                                    <td>{User.email} </td>
                                    <td className="text-right text-nowrap">
                                      <a className="action_btn mx-1" onClick={(e) => {
                                        e.preventDefault();
                                        arrayHelpers.remove(index);
                                      }}><img src={TimesIcon} alt=""/></a>
                                    </td>
                                  </tr>
                                ))}
                                </tbody>
                              </Table>
                            )}
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                </Container>
                */}
                  <Container className="my-5">
                    <div className="divider" />
                  </Container>
                  <Container>
                    <Row className="justify-content-center">
                      <Col lg={8}>
                        <div className="">
                          {renderProps.errors.Categorie && renderProps.touched.Categorie ? (
                            <div className="alert alert-danger">{renderProps.errors.Categorie}</div>
                          ) : null}
                          {renderProps.errors.Nom && renderProps.touched.Nom ? (
                            <div className="alert alert-danger">{renderProps.errors.Nom}</div>
                          ) : null}
                          {renderProps.errors.Localisation && renderProps.touched.Localisation ? (
                            <div className="alert alert-danger">{renderProps.errors.Localisation}</div>
                          ) : null}
                          {renderProps.errors.User ? (
                            <div className="alert alert-danger">{renderProps.errors.User}</div>
                          ) : null}
                          <AppStatus />
                        </div>
                      </Col>
                    </Row>
                    <div className="mb-5 mt-3 pb-5 text-right form-footer">
                      {this.props.app.loading ? (
                        <div className="spinner-wrapper">
                          <i className="fa fa-spin fa-spinner" />
                        </div>
                      ) : (
                        <>
                          {/*
                          !partnerId && (
                            <button type="submit" className="btn btn-outline-custom">
                              {t('Save as draft')}
                            </button>
                          )
                          */}
                          <button type="submit" className="btn btn-custom ">
                            {partnerId ? t('Update') : t('Create New Item')}
                          </button>
                        </>
                      )}
                    </div>
                  </Container>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <div className="text-center mt-5">
            <div className="spinner-wrapper">
              <i className="fa fa-spin fa-spinner" />
            </div>
          </div>
        )}
      </>
    );
  }
}

PartnerCreateModify.propTypes = {
  t: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  createPartnerSheet: PropTypes.func,
  resetAppSuccess: PropTypes.func.isRequired,
  getPartnerDetails: PropTypes.func.isRequired,
  resetPartnerDetails: PropTypes.func.isRequired,
  modifyPartnerSheet: PropTypes.func,
  app: PropTypes.object.isRequired,
  partner: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
  partner: state.partner,
});

export default withTranslation()(
  connect(mapStateToProps, {
    createPartnerSheet,
    resetAppSuccess,
    getPartnerDetails,
    resetPartnerDetails,
    modifyPartnerSheet,
  })(PartnerCreateModify),
);
