import React, { Component } from 'react';
import ProductList from '../ProductList';
import './style.scss';

class ActiveProductList extends Component {
  render() {
    return <ProductList activeProducts />;
  }
}

export default ActiveProductList;
