import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import './style.scss';

class AppStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    const { error, success } = this.props.app;
    return (
      <>
        {error?.map((errorSingle, key) => (
          <Alert key={key} variant="danger">
            {t(errorSingle)}
          </Alert>
        ))}
        {success?.map((successSingle, key) => (
          <Alert key={key} variant="success">
            {t(successSingle)}
          </Alert>
        ))}
      </>
    );
  }
}

AppStatus.propTypes = {
  t: PropTypes.func,
  app: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
});

export default withTranslation()(connect(mapStateToProps, {})(AppStatus));
