import { SITE_INFO } from '../actions/types';

const initialState = {
  siteInfo: {},
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SITE_INFO:
      return {
        ...state,
        siteInfo: payload,
      };
    default:
      return { ...state };
  }
}
