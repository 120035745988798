import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  getIngredients,
  setIngredientSearchKey,
  setIngredientCurrentPage,
  setIngredientArchivedStatus,
  archiveIngredientToggle,
} from '../../actions/ingredientActions';
import { resetAppSuccess } from '../../actions/appActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './style.scss';
import ReactPaginate from 'react-paginate';
import IngredientItem from '../IngredientItem';
import AddIcon from '../../images/add.svg';
import SearchIcon from '../../images/search_icon.svg';
import { withTranslation } from 'react-i18next';
import DeleteIcon from '../../images/delete_icon.svg';
import DeleteWhiteIcon from '../../images/delete_white_icon.svg';
import PencilWhiteIcon from '../../images/pencil_white.svg';
import { Modal } from 'react-bootstrap';
import TimesColorIcon from '../../images/times_color.svg';
import AppStatus from '../AppStatus';
import EmptyItem from '../EmptyItem';

class IngredientList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ingredientDetailsModalShow: false,
      ingredientArchiveModalShow: false,
      ingredientDetails: null,
    };
    this.handleIngredientDetailsModalClose = this.handleIngredientDetailsModalClose.bind(this);
    this.handleIngredientDetailsModalShow = this.handleIngredientDetailsModalShow.bind(this);
    this.handleIngredientArchiveModalClose = this.handleIngredientArchiveModalClose.bind(this);
    this.handleIngredientArchiveModalShow = this.handleIngredientArchiveModalShow.bind(this);
  }

  componentDidMount() {
    this.props.getIngredients();
  }

  handleIngredientDetailsModalClose() {
    this.setState({ ingredientDetailsModalShow: false });
  }

  handleIngredientDetailsModalShow(ingredient) {
    this.setState({ ingredientDetails: ingredient });
    this.setState({ ingredientDetailsModalShow: true });
  }

  handleIngredientArchiveModalClose() {
    this.setState({ ingredientArchiveModalShow: false });
  }

  handleIngredientArchiveModalShow() {
    this.setState({ ingredientArchiveModalShow: true });
  }

  render() {
    const { t } = this.props;
    const {
      offset,
      filteredDataList,
      perPage,
      currentPage,
      search,
      pageCount,
      archivedStatus,
      dataList,
    } = this.props.ingredient;
    return (
      <>
        <div className="top-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <Link to="/" className="top-bar-left-link">
                  <i>←</i> {t('Back to Home')}
                </Link>
              </div>
              <div className="col-md-6 text-right">
                <Link to="/IngredientCreate" className="top-bar-right-link">
                  <i>
                    <img src={AddIcon} alt="Add" />
                  </i>
                  {t('Add an ingredient')}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="text-center mb-4">
            <h1 className="page_head ">{t('Ingredients')}</h1>
          </div>
        </div>
        {dataList?.length > 0 ? (
          <>
            <div className="container">
              <div className="common_search_container">
                <div className="common_search_input_wrapper">
                  <span className="common_search_input_icon">
                    <img src={SearchIcon} alt="" />
                  </span>
                  <input
                    className="common_search_input"
                    type="search"
                    placeholder={t('Find an ingredient')}
                    value={search}
                    onChange={(e) => {
                      this.props.setIngredientSearchKey(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                {filteredDataList.slice(offset, offset + perPage).map((ingredient, index) => (
                  <IngredientItem
                    ingredient={ingredient}
                    key={index}
                    handleIngredientDetailsModalShow={this.handleIngredientDetailsModalShow}
                  />
                ))}
              </div>
              <div className="brand_pagination">
                {pageCount > 0 && (
                  <ReactPaginate
                    breakClassName="page-item"
                    breakLabel={<span className="page-link gap">...</span>}
                    containerClassName={'pagination d-flex justify-content-center'}
                    pageClassName="page-item"
                    previousLinkClassName={'page-link previous_page'}
                    nextLinkClassName={'page-link next_page'}
                    disabledClassName={'disabled'}
                    activeClassName={'active'}
                    previousLabel={'← ' + t('Previous')}
                    nextLabel={t('Next') + ' →'}
                    pageCount={pageCount}
                    onPageChange={(evData) => {
                      this.props.setIngredientCurrentPage(evData.selected);
                    }}
                    forcePage={currentPage}
                    previousClassName="page-item"
                    nextClassName="page-item"
                    pageLinkClassName="page-link"
                  />
                )}
              </div>
              <div className="mb-5">
                <button
                  type="button"
                  className="brand-btn-5"
                  onClick={() => {
                    this.props.setIngredientArchivedStatus(!archivedStatus);
                  }}
                >
                  <img src={DeleteIcon} alt="" />
                  {archivedStatus ? t('Hide archived ingredients') : t('See archived ingredients')}
                </button>
              </div>
            </div>
            <Modal
              show={this.state.ingredientDetailsModalShow}
              onHide={this.handleIngredientDetailsModalClose}
              className="brand-modal"
            >
              <Modal.Header className="pb-0 border-less">
                <Modal.Title>{this.state.ingredientDetails && this.state.ingredientDetails.Nom}</Modal.Title>
                <button onClick={this.handleIngredientDetailsModalClose} type="button" className="modal-close-btn">
                  <img src={TimesColorIcon} alt="close" />
                </button>
              </Modal.Header>
              <Modal.Body className="pt-0">
                <div>
                  <h6 className="ingredient_item_sous_titre">
                    {this.state.ingredientDetails && this.state.ingredientDetails.sousTitre}
                  </h6>
                  <h6 className="ingredient_item_sous_titre">
                    {this.state.ingredientDetails && this.state.ingredientDetails.Provenance}
                  </h6>
                  <p className="ingredient_item_description_ingredient">
                    {this.state.ingredientDetails && this.state.ingredientDetails.descriptionIngredient}
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer className="py-4 text-left justify-content-start">
                {this.props.app.loading ? (
                  <div className="spinner-wrapper">
                    <i className="fa fa-spin fa-spinner" />
                  </div>
                ) : this.state.ingredientDetails && this.state.ingredientDetails.Archived ? (
                  <button
                    type="button"
                    className="action_btn_with_txt"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.archiveIngredientToggle(this.state.ingredientDetails._id);
                      this.handleIngredientDetailsModalClose();
                    }}
                  >
                    <i className="action-icon rounded-circle mr-1">
                      <img src={DeleteWhiteIcon} alt="Reactivate" />
                    </i>{' '}
                    {t('Reactivate The Ingredient')}
                  </button>
                ) : (
                  <Fragment>
                    <Link
                      to={'/IngredientModify/' + (this.state.ingredientDetails ? this.state.ingredientDetails._id : '')}
                      className="action_btn_with_txt mr-3"
                    >
                      <i className="action-icon rounded-circle mr-1">
                        <img src={PencilWhiteIcon} alt="Edit" />
                      </i>{' '}
                      {t('Edit ingredient')}
                    </Link>
                    <button
                      type="button"
                      className="action_btn_with_txt"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleIngredientArchiveModalShow();
                        this.handleIngredientDetailsModalClose();
                      }}
                    >
                      <i className="action-icon rounded-circle mr-1">
                        <img src={DeleteWhiteIcon} alt="Delete" />
                      </i>{' '}
                      {t('Archive the ingredient')}
                    </button>
                  </Fragment>
                )}
              </Modal.Footer>
            </Modal>
            <Modal
              show={this.state.ingredientArchiveModalShow}
              onHide={this.handleIngredientArchiveModalClose}
              className="brand-modal"
            >
              <Modal.Header>
                <Modal.Title>{t('Archiving')}</Modal.Title>
                <button onClick={this.handleIngredientArchiveModalClose} type="button" className="modal-close-btn">
                  <img src={TimesColorIcon} alt="close" />
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="pop-msg-con mb-3">
                  <p className="pop-msg-con-h">{t('ingredientArchiveConfirmHead')}</p>
                  <p className="mb-0">{t('ingredientArchiveConfirmPara1')}</p>
                  <p>{t('ingredientArchiveConfirmPara2')}</p>
                </div>
                <div className="">
                  <AppStatus />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={this.handleIngredientArchiveModalClose}
                  className="btn btn-custom-outline-common px-3 mr-2"
                >
                  {t('Go back')}
                </button>
                {this.props.app.loading ? (
                  <div className="spinner-wrapper">
                    <i className="fa fa-spin fa-spinner" />
                  </div>
                ) : (
                  <Fragment>
                    <button
                      type="button"
                      onClick={() => {
                        this.props.archiveIngredientToggle(this.state.ingredientDetails._id);
                        this.handleIngredientArchiveModalClose();
                      }}
                      className="btn btn-custom-common px-3"
                    >
                      {t('Archive')}
                    </button>
                  </Fragment>
                )}
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          <EmptyItem />
        )}
      </>
    );
  }
}

IngredientList.propTypes = {
  t: PropTypes.func,
  getIngredients: PropTypes.func.isRequired,
  setIngredientSearchKey: PropTypes.func.isRequired,
  setIngredientCurrentPage: PropTypes.func.isRequired,
  setIngredientArchivedStatus: PropTypes.func.isRequired,
  archiveIngredientToggle: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  ingredient: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ingredient: state.ingredient,
  app: state.app,
});

export default withTranslation()(
  connect(mapStateToProps, {
    getIngredients,
    setIngredientSearchKey,
    setIngredientCurrentPage,
    archiveIngredientToggle,
    resetAppSuccess,
    setIngredientArchivedStatus,
  })(IngredientList),
);
