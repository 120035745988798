import { USER_LIST } from '../actions/types';

const initialState = {
  userList: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case USER_LIST:
      return {
        ...state,
        userList: payload,
      };
    default:
      return { ...state };
  }
}
