import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addSiteInfo, getSiteInfo } from '../../actions/siteInfoActions';
import { resetAppSuccess } from '../../actions/appActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Formik } from 'formik';
import { withTranslation } from 'react-i18next';
import { Container, Row } from 'react-bootstrap';
import './style.scss';
import AppStatus from '../AppStatus';

class ConsumerSiteScoringMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getSiteInfo();
  }

  render() {
    const { t } = this.props;
    const { siteInfo } = this.props.siteInfo;
    const ScoringMethodSchema = yup.object().shape({
      scoringMethodPageTitle: yup.string().required(t('Please enter page title')),
      scoringMethodPageText: yup.string().required(t('Please enter page text')),
    });
    return (
      <>
        <div className="top-bar">
          <div className="container">
            <Link to="/" className="top-bar-left-link">
              <i>←</i> {t('Back to Home')}
            </Link>
          </div>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            scoringMethodPageTitle: siteInfo.scoringMethodPageTitle ? siteInfo.scoringMethodPageTitle : '',
            scoringMethodPageText: siteInfo.scoringMethodPageText ? siteInfo.scoringMethodPageText : '',
          }}
          validationSchema={ScoringMethodSchema}
          onSubmit={(values) => {
            this.props.addSiteInfo(values);
          }}
        >
          {(renderProps) => (
            <form className="mt-5" onSubmit={renderProps.handleSubmit} onChange={this.props.resetAppSuccess}>
              <Container fluid>
                <Row>
                  <div className="col-lg-6 offset-lg-3">
                    <div className="text-center mb-5">
                      <h5 className="page_head_sub">{t('Consumer site')}</h5>
                      <h1 className="page_head ">{t('scoring method')}</h1>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="scoringMethodPageTitle" className="col-lg-5 col-form-label">
                        {t('Page title')}
                      </label>
                      <input
                        className="form-control col-lg-7"
                        type="text"
                        name="scoringMethodPageTitle"
                        id="scoringMethodPageTitle"
                        value={renderProps.values.scoringMethodPageTitle}
                        onChange={(ev) => {
                          renderProps.handleChange(ev);
                        }}
                        onBlur={renderProps.handleBlur}
                        required
                      />
                    </div>
                    <div className="form-group row">
                      <label htmlFor="scoringMethodPageText" className="col-lg-5 col-form-label pt-0">
                        {t('Page text')}
                      </label>
                      <textarea
                        rows="10"
                        className="form-control col-lg-7 "
                        name="scoringMethodPageText"
                        id="scoringMethodPageText"
                        onChange={(ev) => {
                          renderProps.handleChange(ev);
                        }}
                        onBlur={renderProps.handleBlur}
                        value={renderProps.values.scoringMethodPageText}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-5 mt-5">
                    <div className="row">
                      <div className="col-lg-8 offset-lg-2">
                        <div className="divider" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 offset-lg-3">
                    <div className="">
                      {renderProps.errors.scoringMethodPageTitle && renderProps.touched.scoringMethodPageTitle ? (
                        <div className="alert alert-danger">{renderProps.errors.scoringMethodPageTitle}</div>
                      ) : null}
                      {renderProps.errors.scoringMethodPageText && renderProps.touched.scoringMethodPageText ? (
                        <div className="alert alert-danger">{renderProps.errors.scoringMethodPageText}</div>
                      ) : null}
                      <AppStatus />
                    </div>
                  </div>
                  <div className="col-lg-6 offset-lg-3 mb-5 mt-3 pb-5 text-right form-footer pr-0">
                    {this.props.app.loading ? (
                      <div className="spinner-wrapper">
                        <i className="fa fa-spin fa-spinner" />
                      </div>
                    ) : (
                      <>
                        <Link to="/" className="btn btn-outline-custom">
                          {t('Discard changes')}
                        </Link>
                        <button type="submit" className="btn btn-custom ">
                          {t('Save Changes')}
                        </button>
                      </>
                    )}
                  </div>
                </Row>
              </Container>
            </form>
          )}
        </Formik>
      </>
    );
  }
}

ConsumerSiteScoringMethod.propTypes = {
  t: PropTypes.func,
  addSiteInfo: PropTypes.func.isRequired,
  getSiteInfo: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  app: PropTypes.object.isRequired,
  siteInfo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
  siteInfo: state.siteInfo,
});

export default withTranslation()(
  connect(mapStateToProps, { addSiteInfo, resetAppSuccess, getSiteInfo })(ConsumerSiteScoringMethod),
);
