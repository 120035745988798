import './style.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TestItemBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { name, description, archived, molecules } = this.props.testItem;
    return (
      <div className="col-lg-3 col-md-6">
        <div
          className="test_item"
          onClick={() => {
            this.props.handleTestItemDetailsModalShow(this.props.testItem);
          }}
        >
          <div className="test_item_wrapper">
            <h2 className={'test_item_name ' + (archived === true ? 'category_archived' : '')}> {name}</h2>
            <h6 className="test_item_sub"> {molecules?.map((item) => item?.name)?.join(', ')}</h6>
            <p className="test_item_description">
              {description?.substr(0, 160)}
              {description?.length > 160 && '...'}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

TestItemBlock.propTypes = {
  testItem: PropTypes.object.isRequired,
  handleTestItemDetailsModalShow: PropTypes.func.isRequired,
};

export default TestItemBlock;
