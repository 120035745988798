import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registerUser } from '../../actions/authActions';
import './style.scss';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const newUser = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      errors: {},
    };
    this.props.registerUser(newUser, this.props.history);
  }
  render() {
    const { errors } = this.props;
    const { isAuthenticated } = this.props?.auth;
    if (isAuthenticated) {
      return <Redirect to="/login" />;
    }
    return (
      <form className="col-lg-4 offset-lg-4">
        <div className="col-lg-12">
          <div className="form-group ">
            <input
              type="text"
              className="form-control"
              id="lastName"
              placeholder="Nom"
              value={this.state.lastName}
              onChange={this.onChange}
              error={errors?.lastName}
              required
            />
          </div>
          <div className="form-group ">
            <input
              type="text"
              className="form-control"
              id="firstName"
              placeholder="Prenom"
              value={this.state.firstName}
              onChange={this.onChange}
              error={errors?.firstName}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Email"
              value={this.state.email}
              onChange={this.onChange}
              error={errors?.email}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="mot de passe"
              value={this.state.password}
              onChange={this.onChange}
              error={errors?.password}
              required
            />
          </div>
          <div className="form-group col-lg-6 offset-lg-6 validation_register">
            <button type="submit" className="btn btn-success" onClick={this.onSubmit}>
              Valider
            </button>
            <p className="font-small grey-text">
              Go Back to
              <Link to="/login" className="text-success  font-weight-bold">
                Log in
              </Link>
            </p>
          </div>
        </div>
      </form>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { registerUser })(withRouter(Register));
