import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  getPartners,
  setPartnerSearchKey,
  setPartnerCurrentPage,
  setPartnerArchivedStatus,
} from '../../actions/partnerActions';
import { resetAppSuccess } from '../../actions/appActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './style.scss';
import ReactPaginate from 'react-paginate';
import PartnerItem from '../PartnerItem';
import AddIcon from '../../images/add.svg';
import { withTranslation } from 'react-i18next';
import SearchIcon from '../../images/search_icon.svg';
import DeleteIcon from '../../images/delete_icon.svg';
import EmptyItem from '../EmptyItem';

class PartnerList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getPartners();
  }

  render() {
    const { t } = this.props;
    const {
      offset,
      filteredDataList,
      perPage,
      currentPage,
      search,
      pageCount,
      archivedStatus,
      dataList,
    } = this.props.partner;
    return (
      <>
        <div className="top-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <Link to="/" className="top-bar-left-link">
                  <i>←</i> {t('Back to Home')}
                </Link>
              </div>
              <div className="col-md-6 text-right">
                <Link to="/PartnerCreateSheet" className="top-bar-right-link">
                  <i>
                    <img src={AddIcon} alt="Add" />
                  </i>
                  {t('Add a Partner')}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="text-center mb-4">
            <h1 className="page_head ">{t('Partners')}</h1>
          </div>
        </div>
        {dataList?.length > 0 ? (
          <div className="container">
            <div className="common_search_container">
              <div className="common_search_input_wrapper">
                <span className="common_search_input_icon">
                  <img src={SearchIcon} alt="" />
                </span>
                <input
                  className="common_search_input"
                  type="search"
                  placeholder={t('Search for a partner (name, category)')}
                  value={search}
                  onChange={(e) => {
                    this.props.setPartnerSearchKey(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row">
              {filteredDataList.slice(offset, offset + perPage).map((partner, index) => (
                <PartnerItem partner={partner} key={index} history={this.props.history} />
              ))}
            </div>
            <div className="brand_pagination">
              {pageCount > 0 && (
                <ReactPaginate
                  breakClassName="page-item"
                  breakLabel={<span className="page-link gap">...</span>}
                  containerClassName={'pagination d-flex justify-content-center'}
                  pageClassName="page-item"
                  previousLinkClassName={'page-link previous_page'}
                  nextLinkClassName={'page-link next_page'}
                  disabledClassName={'disabled'}
                  activeClassName={'active'}
                  previousLabel={'← ' + t('Previous')}
                  nextLabel={t('Next') + ' →'}
                  pageCount={pageCount}
                  onPageChange={(evData) => {
                    this.props.setPartnerCurrentPage(evData.selected);
                  }}
                  forcePage={currentPage}
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageLinkClassName="page-link"
                />
              )}
            </div>
            <div className="mb-5">
              <button
                type="button"
                className="brand-btn-5"
                onClick={() => {
                  this.props.setPartnerArchivedStatus(!archivedStatus);
                }}
              >
                <img src={DeleteIcon} alt="" />
                {archivedStatus ? t('Hide archived partners') : t('See archived partners')}
              </button>
            </div>
          </div>
        ) : (
          <EmptyItem />
        )}
      </>
    );
  }
}

PartnerList.propTypes = {
  t: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  getPartners: PropTypes.func.isRequired,
  setPartnerSearchKey: PropTypes.func.isRequired,
  setPartnerCurrentPage: PropTypes.func.isRequired,
  setPartnerArchivedStatus: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  partner: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  partner: state.partner,
  app: state.app,
});

export default withTranslation()(
  connect(mapStateToProps, {
    getPartners,
    setPartnerCurrentPage,
    setPartnerSearchKey,
    setPartnerArchivedStatus,
    resetAppSuccess,
  })(PartnerList),
);
