import React, { Component, Fragment } from 'react';
import * as api from '../../api.js';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  getProducts,
  setProductArchivedStatus,
  setProductShowLastVersionStatus,
  setProductCurrentPage,
  setProductSearchKey,
  deleteDraftProduct,
  getDraftProducts,
  setProductSortBy,
  setProductArchiveSortBy,
} from '../../actions/productActions';
import { resetAppSuccess } from '../../actions/appActions';
import { getProductDetails, archiveProductToggle } from '../../actions/productActions';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import AddIcon from '../../images/add.svg';
import SearchIcon from '../../images/search_icon.svg';
import { Col, Container, Form, FormControl, FormGroup, FormLabel, Row, Dropdown } from 'react-bootstrap';
import ProductListItem from '../ProductListItem';
import DeleteIcon from '../../images/delete_icon.svg';
import { withTranslation } from 'react-i18next';
import './style.scss';
import { isAdmin } from '../../utils/roleUtils';
import EmptyItem from '../EmptyItem';
import axios from 'axios';
import config from '../../config.js';
const { API_URL } = config;

// const FicheProduit = require('../models/FicheProduit');

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByListShow: false,
      selectedEquipmentDropdown: "",
    };
    this.setSortByListShowToggle = this.setSortByListShowToggle.bind(this);
 }

  componentDidMount() {
    
    const { productId } = this.props;
    if (productId) {
      this.props.getProductDetails(productId);
    };
    const { activeProducts } = this.props;
    this.props.setProductArchivedStatus(!activeProducts);
    this.props.getProducts(activeProducts);
    if (activeProducts && isAdmin()) {
      this.props.getDraftProducts();
    }
  }

  setSortByListShowToggle() {
    this.setState({ sortByListShow: !this.state.sortByListShow });
  }

  render() {
    const { t, activeProducts, productId } = this.props;
    const {
      offset,
      dataList,
      dataListDraft,
      filteredDataList,
      perPage,
      currentPage,
      search,
      pageCount,
      showLastVersionStatus,
      sortBy,
      sortByList,
      sortByListArchive,
      sortByArchive,
      productDetails,
    } = this.props.product;


    const archiveSelectedProductCategory =  () => {

      let x = filteredDataList?.slice(offset, offset + perPage)
      let y = x?.map((product) => (product.Categorie))
      let filteredProduct = x.filter(product => product.Categorie === this.state.selectedEquipmentDropdown)
      console.log("filteredProduct", filteredProduct)
      filteredProduct && filteredProduct.forEach(async product => {
        await api.archiveProductToggle(product._id);
        document.location.reload()
      });
    }


    //fonction suppression des doublons dans le select "Categorie"
    function removeDups() {
      let x = filteredDataList?.slice(offset, offset + perPage)
      let y = x?.map((product) => (product.Categorie))
      let unique = [...new Set(y)];
      return unique;
    };



    //fonction récupération des valeurs dans le select "Categorie"
    const handleChange = (event) => {
      this.setState({ selectedEquipmentDropdown: event.target.value })
    }



    return (
      <>
        {isAdmin() && (
          <div className="top-bar">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  {activeProducts ? (
                    <Link to={'/'} className="top-bar-left-link">
                      <i>←</i> {t('Back to Home')}
                    </Link>
                  ) : (
                      <Link to={'/ProductList'} className="top-bar-left-link">
                        <i>←</i> {t('Return to product list')}
                      </Link>
                    )}
                </div>
                {activeProducts && (
                  <div className="col-md-6 text-right">
                    <Link to={'/ProductCreateSheet'} className="top-bar-right-link">
                      <i>
                        <img src={AddIcon} alt="Add" />
                      </i>
                      {t('Create a new record')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="container-fluid mt-5">
          <div className="text-center mb-4">
            <h1 className="page_head">{activeProducts ? t("YOU PRODUCTS") : t('Archived products')}</h1>
          </div>
        </div>
        {dataList?.length > 0 || dataListDraft?.length > 0 ? (
          <>
            <div className="container">
              <div className="common_search_container">
                <div className="common_search_input_wrapper">
                  <span className="common_search_input_icon">
                    <img src={SearchIcon} alt="" />
                  </span>
                  <input
                    className="common_search_input"
                    type="search"
                    placeholder={t('product search placeholder')}
                    value={search}
                    onChange={(e) => {
                      this.props.setProductSearchKey(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <Container className="mt-2">
              {activeProducts && dataListDraft?.length > 0 && (
                <div className="mb-4">
                  <Row className="justify-content-center">
                    {dataListDraft?.map((product, index) => (
                      <ProductListItem
                        product={product}
                        key={index}
                        deleteDraftProduct={this.props.deleteDraftProduct}
                        isDraft
                      />
                    ))}
                  </Row>
                  <div className="divider" />
                </div>
              )}
              {dataList?.length > 0 && (
                <>
                  <Row className="product-list-filter">
                    {activeProducts ? (
                      <>
                        <Col lg={6}>
                          <Form.Group className="custom-checkbox-group ">
                            <Form.Check
                              custom
                              label={t('See only the latest versions of products')}
                              className="checkbox-partenaire"
                              id={'partners'}
                              checked={showLastVersionStatus}
                              onChange={(ev) => {
                                this.props.setProductShowLastVersionStatus(ev.target.checked);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <div className="text-right mb-3">
                            {t('Sort by')}:
                            <Dropdown
                              show={this.state.sortByListShow}
                              onToggle={this.setSortByListShowToggle}
                              className="product-sort d-inline-block ml-2"
                              alignRight
                            >
                              <Dropdown.Toggle>
                                {t(sortBy)} <i className="fas fa-chevron-down" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {sortByList &&
                                  sortByList.map((sortByItem, index) => (
                                    <Fragment key={index}>
                                      <button
                                        className={sortByItem === sortBy ? 'active-sort' : ''}
                                        type="button"
                                        onClick={() => {
                                          this.props.setProductSortBy(sortByItem);
                                          this.setSortByListShowToggle();
                                        }}
                                      >
                                        {t(sortByItem)}
                                      </button>
                                      <Dropdown.Divider />
                                    </Fragment>
                                  ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Col>
                        {/* Début de la fonctionnalité "archiver une catégorie" */}
                        <Col lg={5}>
                          <FormGroup as={Row} controlId="Categorie">
                            <Col lg={12}>
                              <FormControl
                                as="select"
                                name="Categorie"
                                placeholder='SELCTIONNEZ UNE CATEGORIE'
                                onChange={(event) => handleChange(event)}
                                required
                              >
                                <option disabled selected value> Selectionnez une catégorie à archiver </option>
                                {removeDups().map((product, index) => (
                                  <option>{product}</option>
                                ))}
                              </FormControl>
                            </Col>
                            <Col lg={7}>
                              {activeProducts && isAdmin() && (
                                <button
                                  className="top-bar-right-link action_btn_with_txt"
                                  type="button"
                                  onClick={() => archiveSelectedProductCategory()}
                                >
                                  <img src={DeleteIcon} alt="" />
                                  {t('Archive the category')}
                                </button>
                              )}
                            </Col>
                          </FormGroup>
                        </Col>
                        {/* Fin de la fonctionnalité "archiver une catégorie" */}
                      </>
                    ) : (
                        <Col lg={12}>
                          <div className="text-right mb-3">
                            {t('Sort by')}:
                          <Dropdown
                              show={this.state.sortByListShow}
                              onToggle={this.setSortByListShowToggle}
                              className="product-sort d-inline-block ml-2"
                              alignRight
                            >
                              <Dropdown.Toggle>
                                {t(sortByArchive)} <i className="fas fa-chevron-down" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {sortByListArchive &&
                                  sortByListArchive.map((sortByItem, index) => (
                                    <Fragment key={index}>
                                      <button
                                        className={sortByItem === sortByArchive ? 'active-sort' : ''}
                                        type="button"
                                        onClick={() => {
                                          this.props.setProductArchiveSortBy(sortByItem);
                                          this.setSortByListShowToggle();
                                        }}
                                      >
                                        {t(sortByItem)}
                                      </button>
                                      <Dropdown.Divider />
                                    </Fragment>
                                  ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Col>
                      )}
                  </Row>
                  <Row className="product-list-container">
                    {filteredDataList?.slice(offset, offset + perPage)?.map((product, index) => (
                      <ProductListItem product={product} key={index} />
                    ))}
                  </Row>
                  <div className="mb-5">
                    <div className="brand_pagination mt-5">
                      {pageCount > 0 && (
                        <ReactPaginate
                          breakClassName="page-item"
                          breakLabel={<span className="page-link gap">...</span>}
                          containerClassName={'pagination d-flex justify-content-center'}
                          pageClassName="page-item"
                          previousLinkClassName={'page-link previous_page'}
                          nextLinkClassName={'page-link next_page'}
                          disabledClassName={'disabled'}
                          activeClassName={'active'}
                          previousLabel={'← ' + t('Previous')}
                          nextLabel={t('Next') + ' →'}
                          pageCount={pageCount}
                          onPageChange={(ev) => {
                            this.props.setProductCurrentPage(ev.selected);
                          }}
                          forcePage={currentPage}
                          previousClassName="page-item"
                          nextClassName="page-item"
                          pageLinkClassName="page-link"
                        />
                      )}
                    </div>
                    {activeProducts && isAdmin() && (
                      <Link to="/ProductListArchived" className="brand-btn-5">
                        <img src={DeleteIcon} alt="" />
                        {t('See archived products')}
                      </Link>
                    )}
                  </div>
                </>
              )}
            </Container>
          </>
        ) : (
            <EmptyItem />
          )}
      </>
    );
  }
}

ProductList.propTypes = {
  t: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  setProductArchiveSortBy: PropTypes.func.isRequired,
  setProductSortBy: PropTypes.func.isRequired,
  getDraftProducts: PropTypes.func.isRequired,
  deleteDraftProduct: PropTypes.func,
  getProducts: PropTypes.func.isRequired,
  getProductDetails: PropTypes.func.isRequired,
  archiveProductToggle: PropTypes.func.isRequired,
  setProductSearchKey: PropTypes.func.isRequired,
  setProductCurrentPage: PropTypes.func.isRequired,
  setProductArchivedStatus: PropTypes.func.isRequired,
  setProductShowLastVersionStatus: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  activeProducts: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  product: state.product,
  app: state.app,
  auth: state.auth,
});

export default withTranslation()(
  connect(mapStateToProps, {
    archiveProductToggle,
    setProductArchiveSortBy,
    getProducts,
    getProductDetails,
    setProductCurrentPage,
    setProductSearchKey,
    setProductArchivedStatus,
    setProductShowLastVersionStatus,
    resetAppSuccess,
    deleteDraftProduct,
    getDraftProducts,
    setProductSortBy,
  })(ProductList),
);
