import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getTestCategories, setTestCategorySearchKey } from '../../actions/testCategoryActions';
import { resetAppSuccess } from '../../actions/appActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './style.scss';
import AddIcon from '../../images/add.svg';
import SearchIcon from '../../images/search_icon.svg';
import { withTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import EmptyItem from '../EmptyItem';

class TestCategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getTestCategories();
  }

  render() {
    const { t } = this.props;
    const { dataList, filteredDataList, search } = this.props.testCategory;

    console.log(this.props.testCategory)
    return (
      <>
        <div className="top-bar">
          <Container>
            <div className="row">
              <div className="col-md-6">
                <Link to="/" className="top-bar-left-link">
                  <i>←</i> {t('Back to Home')}
                </Link>
              </div>
              <div className="col-md-6 text-right">
                <Link to="/TestCategoryCreate" className="top-bar-right-link">
                  <i>
                    <img src={AddIcon} alt="Add" />
                  </i>
                  {t('Add a test category')}
                </Link>
              </div>
            </div>
          </Container>
        </div>
        <div className="container-fluid mt-5">
          <div className="text-center mb-4">
            <h1 className="page_head ">{t('SECURITY TESTS')}</h1>
          </div>
        </div>
        <div className="mb-5">
          {dataList?.length > 0 ? (
            <div className="container">
              <div className="common_search_container">
                <div className="common_search_input_wrapper">
                  <span className="common_search_input_icon">
                    <img src={SearchIcon} alt="" />
                  </span>
                  <input
                    className="common_search_input"
                    type="search"
                    placeholder={t('Find a test category')}
                    value={search}
                    onChange={(e) => {
                      this.props.setTestCategorySearchKey(e.target.value);
                    }}
                  />
                </div>
              </div>
              <Row>
                {filteredDataList?.map((testCategoryItem, index) => (
                  <Col md={4} key={index}>
                    <div className="test-category ">
                      <h2>
                        <Link to={`/TestCategoryModify/${testCategoryItem?._id}`}>{testCategoryItem?.name}</Link>
                      </h2>
                      <h5 className="">{testCategoryItem?.description}</h5>
                      <Link to={`/TestCategory/${testCategoryItem?._id}/TestItemList/`}>
                        {t('See the list of')} {` `}
                        {testCategoryItem?.name?.toLowerCase().substr(0, 18)}
                        {testCategoryItem?.name?.length > 18 && '...'} →
                      </Link>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <EmptyItem />
          )}
        </div>
      </>
    );
  }
}

TestCategoryList.propTypes = {
  t: PropTypes.func,
  getTestCategories: PropTypes.func.isRequired,
  setTestCategorySearchKey: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  testCategory: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  testCategory: state.testCategory,
  app: state.app,
});

export default withTranslation()(
  connect(mapStateToProps, { getTestCategories, setTestCategorySearchKey, resetAppSuccess })(TestCategoryList),
);
