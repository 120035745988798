import { PASSWORD_CHANGE_MODAL_SHOW } from '../actions/types';

const initialState = {
  show: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case PASSWORD_CHANGE_MODAL_SHOW:
      return {
        ...state,
        show: payload,
      };
    default:
      return state;
  }
}
