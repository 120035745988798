import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getPartnerDetails, archivePartnerToggle } from '../../actions/partnerActions';
import { resetAppSuccess } from '../../actions/appActions';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import './style.scss';
import TimesColorIcon from '../../images/times_color.svg';
import PencilWhiteIcon from '../../images/pencil_white.svg';
import DeleteWhiteIcon from '../../images/delete_white_icon.svg';
import PartnerCard from '../PartnerCard';
import UserAccount from '../UserAccount';
import Roles from '../../utils/roles';
import AppStatus from '../AppStatus';

class PartnerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerArchiveModalShow: false,
    };
    this.handlePartnerArchiveModalClose = this.handlePartnerArchiveModalClose.bind(this);
    this.handlePartnerArchiveModalShow = this.handlePartnerArchiveModalShow.bind(this);
  }

  componentDidMount() {
    const { partnerID } = this.props.match.params;
    if (partnerID) {
      this.props.getPartnerDetails(partnerID);
    }
  }

  handlePartnerArchiveModalClose() {
    this.setState({ partnerArchiveModalShow: false });
  }

  handlePartnerArchiveModalShow() {
    this.setState({ partnerArchiveModalShow: true });
  }

  render() {
    const { t } = this.props;
    const { partnerID, productId } = this.props.match.params;
    const { partnerDetails } = this.props.partner;
    return (
      <>
        <div className="top-bar">
          <Container>
            <Row>
              <Col md={4}>
                {productId ? (
                  <Link to={`/ProductDetails/${productId}`} className="top-bar-left-link">
                    <i>←</i> {t('Return to product sheet')}
                  </Link>
                ) : (
                  <Link to="/PartnerList" className="top-bar-left-link">
                    <i>←</i> {t('Back to the list of partners')}
                  </Link>
                )}
              </Col>
              <Col md={8} className="text-right">
                {partnerDetails?.Categorie === Roles.Manufacturer && (
                  <Link to={`/PartnerModify/${partnerID}`} className="top-bar-right-link  mr-3">
                    <i>
                      <img src={PencilWhiteIcon} alt="Add" />
                    </i>
                    {t('Modify the file')}
                  </Link>
                )}
                {partnerDetails?.Categorie === Roles.Laboratory && (
                    <Link to={`/PartnerModify/${partnerID}`} className="top-bar-right-link  mr-3">
                      <i>
                        <img src={PencilWhiteIcon} alt="Add" />
                      </i>
                      {t('Modify the file')}
                    </Link>
                )}
                {partnerDetails ? (
                  partnerDetails.Archived ? (
                    <button
                      type="button"
                      className="action_btn_with_txt"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.archivePartnerToggle(partnerID);
                      }}
                    >
                      <i className="action-icon rounded-circle mr-1">
                        <img src={DeleteWhiteIcon} alt="Reactivate" />
                      </i>
                      {t('Reactivate the file')}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="action_btn_with_txt"
                      onClick={(e) => {
                        this.handlePartnerArchiveModalShow();
                        e.preventDefault();
                      }}
                    >
                      <i className="action-icon rounded-circle mr-1">
                        <img src={DeleteWhiteIcon} alt="Delete" />
                      </i>
                      {t('Archive the file')}
                    </button>
                  )
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </Container>
        </div>
        {partnerDetails && (
          <Modal
            show={this.state.partnerArchiveModalShow}
            onHide={this.handlePartnerArchiveModalClose}
            className="brand-modal"
          >
            <Modal.Header>
              <Modal.Title>{t('Archiving')}</Modal.Title>
              <button onClick={this.handlePartnerArchiveModalClose} type="button" className="modal-close-btn">
                <img src={TimesColorIcon} alt="close" />
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="pop-msg-con mb-3">
                <p className="pop-msg-con-h">{t('archivePartnerHead?')}</p>
                <p className="mb-0">{t('archivePartnerPara1?')}</p>
                <p>{t('archivePartnerPara2?')}</p>
              </div>
              <div className="">
                <AppStatus />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={this.handlePartnerArchiveModalClose}
                className="btn btn-custom-outline-common px-3 mr-2"
              >
                {t('Go back')}
              </button>
              {this.props.app.loading ? (
                <div className="spinner-wrapper">
                  <i className="fa fa-spin fa-spinner" />
                </div>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      this.props.archivePartnerToggle(partnerID);
                      this.handlePartnerArchiveModalClose();
                      this.props.history.push('/PartnerList');
                    }}
                    className="btn btn-custom-common px-3"
                  >
                    {t('Archive')}
                  </button>
                </>
              )}
            </Modal.Footer>
          </Modal>
        )}
        <div className="mt-5">
          {partnerDetails ? (
            <Container>
              <PartnerCard partnerData={partnerDetails} />
              <UserAccount partnerData={partnerDetails} userList={partnerDetails?.Users} />
              <AppStatus />
            </Container>
          ) : (
            <div className="spinner-wrapper">
              <i className="fa fa-spin fa-spinner" />
            </div>
          )}
        </div>
      </>
    );
  }
}

PartnerDetails.propTypes = {
  t: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  getPartnerDetails: PropTypes.func.isRequired,
  archivePartnerToggle: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  partner: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  partner: state.partner,
  app: state.app,
});

export default withTranslation()(
  connect(mapStateToProps, { getPartnerDetails, resetAppSuccess, archivePartnerToggle })(PartnerDetails),
);
