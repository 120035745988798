import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  createIngredient,
  getIngredientDetails,
  modifyIngredient,
  resetIngredientDetails,
} from '../../actions/ingredientActions';
import { resetAppSuccess } from '../../actions/appActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Formik } from 'formik';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import AppStatus from '../AppStatus';
import './style.scss';

class IngredientCreateModify extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { ingredientId } = this.props.match.params;
    if (ingredientId) {
      this.props.getIngredientDetails(ingredientId);
    } else {
      this.props.resetIngredientDetails();
    }
  }

  render() {
    const { t } = this.props;
    const { ingredientId } = this.props.match.params;
    const { ingredientDetails } = this.props.ingredient;
    const IngredientSchema = yup.object().shape({
      Nom: yup.string().required(t('Please enter Nom')),
      sousTitre: yup.string(),
      Provenance: yup.string(),
      descriptionIngredient: yup.string(),
    });
    return (
      <>
        <div className="top-bar">
          <div className="container">
            <Link to="/IngredientList" className="top-bar-left-link">
              <i>←</i> {t('Back to Ingredient')}
            </Link>
          </div>
        </div>
        {(ingredientId && ingredientDetails) || !ingredientId ? (
          <Formik
            enableReinitialize
            validationSchema={IngredientSchema}
            initialValues={{
              Nom: ingredientDetails ? ingredientDetails.Nom : '',
              sousTitre: ingredientDetails ? ingredientDetails.sousTitre : '',
              Provenance: ingredientDetails ? ingredientDetails.Provenance : '',
              descriptionIngredient: ingredientDetails ? ingredientDetails.descriptionIngredient : '',
            }}
            onSubmit={(values) => {
              if (ingredientId) {
                this.props.modifyIngredient(ingredientId, values);
              } else {
                this.props.createIngredient(values, this.props.history);
              }
            }}
          >
            {(renderProps) => (
              <form className="mt-5" onSubmit={renderProps.handleSubmit} onChange={this.props.resetAppSuccess}>
                <Container fluid>
                  <Row className="justify-content-center">
                    <Col lg={6}>
                      <div className="text-center mb-5">
                        <h5 className="page_head_sub">{ingredientId ? t('Update') : t('Create a')}</h5>
                        <h1 className="page_head ">{ingredientId ? t('Ingredient') : t('New Ingredient')}</h1>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="Nom" className="col-lg-5 col-form-label">
                          {t('Name')}
                        </label>
                        <input
                          className="form-control col-lg-7"
                          type="text"
                          name="Nom"
                          id="Nom"
                          value={renderProps.values.Nom}
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          required
                        />
                      </div>
                      <div className="form-group row">
                        <label htmlFor="sousTitre" className="col-lg-5 col-form-label pt-0">
                          {t('Utility')}
                          <span className="text-muted d-block font-weight-normal font-smallest">{t('Optional')}</span>
                        </label>
                        <input
                          className="form-control col-lg-7"
                          type="text"
                          name="sousTitre"
                          value={renderProps.values.sousTitre}
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          placeholder="ex : Agent lavant"
                        />
                      </div>
                      <div className="form-group row">
                        <label htmlFor="Provenance" className="col-lg-5 col-form-label pt-0">
                          {t('Provenance')}
                          <span className="text-muted d-block font-weight-normal font-smallest">{t('Optional')}</span>
                        </label>
                        <input
                          className="form-control col-lg-7"
                          type="text"
                          name="Provenance"
                          value={renderProps.values.Provenance}
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          placeholder="ex : Dérivé d’huile de coco"
                        />
                      </div>
                      <div className="form-group row">
                        <label htmlFor="description_entreprise" className="col-lg-5 col-form-label pt-0">
                          {t('Ingredient description')}
                          <span className="text-muted d-block font-weight-normal font-smallest">{t('Optional')}</span>
                        </label>
                        <textarea
                          rows="5"
                          className="form-control col-lg-7 "
                          name="descriptionIngredient"
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          value={renderProps.values.descriptionIngredient}
                        />
                      </div>
                    </Col>
                    <Col lg={8} className="my-5">
                      <div className="divider" />
                    </Col>
                    <Col lg={6} className="mb-5  pb-5 text-right form-footer pr-0">
                      <div className="text-left">
                        {renderProps.errors.Nom && renderProps.touched.Nom ? (
                          <div className="alert alert-danger">{renderProps.errors.Nom}</div>
                        ) : null}
                        <AppStatus />
                      </div>
                      {this.props.app.loading ? (
                        <div className="spinner-wrapper">
                          <i className="fa fa-spin fa-spinner" />
                        </div>
                      ) : (
                        <button type="submit" className="btn btn-custom ">
                          {ingredientId ? t('Update') : t('Create New Item')}
                        </button>
                      )}
                    </Col>
                  </Row>
                </Container>
              </form>
            )}
          </Formik>
        ) : (
          <div className="text-center mt-5">
            <div className="spinner-wrapper">
              <i className="fa fa-spin fa-spinner" />
            </div>
          </div>
        )}
      </>
    );
  }
}

IngredientCreateModify.propTypes = {
  t: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  createIngredient: PropTypes.func,
  resetAppSuccess: PropTypes.func.isRequired,
  getIngredientDetails: PropTypes.func.isRequired,
  modifyIngredient: PropTypes.func,
  resetIngredientDetails: PropTypes.func.isRequired,
  app: PropTypes.object.isRequired,
  ingredient: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
  ingredient: state.ingredient,
});

export default withTranslation()(
  connect(mapStateToProps, {
    createIngredient,
    resetAppSuccess,
    getIngredientDetails,
    modifyIngredient,
    resetIngredientDetails,
  })(IngredientCreateModify),
);
