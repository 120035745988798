import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DocIcon from '../../images/document.svg';

class FilePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      thumb: undefined,
      imgMime: ['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml', 'image/gif', 'image/tiff', 'image/webp'],
      vdoMime: ['video/mp4'],
    };
    this.renderPrev = this.renderPrev.bind(this);
    this.updateFileData = this.updateFileData.bind(this);
  }

  componentDidMount() {
    this.updateFileData();
  }

  componentDidUpdate() {
    this.updateFileData();
  }

  async updateFileData() {
    try {
      const { file } = this.props;
      if (!file) {
        this.setState({ thumb: undefined });
        return;
      }
      const readFileData = await this.readFileAsync(file);
      if (readFileData !== this.state.thumb) {
        this.setState({ thumb: readFileData });
      }
    } catch (e) {
      console.log(e);
    }
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  renderPrev() {
    const { thumb, imgMime, vdoMime } = this.state;
    const { file } = this.props;
    if (file) {
      if (imgMime.includes(file.type)) {
        return <img src={thumb} className="img-fluid prev_image" alt="" />;
      } else if (vdoMime.includes(file.type)) {
        return <video controls src={thumb} className="img-fluid prev_video" />;
      } else {
        return (
          <div className="prev_file">
            <a download href={thumb}>
              <img src={DocIcon} alt="" /> <span>{file.name}</span>
            </a>
          </div>
        );
      }
    } else {
      return '';
    }
  }

  render() {
    const { file } = this.props;
    const { loading /*,thumb, imgMime, vdoMime*/ } = this.state;
    if (loading) {
      return (
        <div className="preview_spinner">
          <i className="fa fa-spin fa-spinner" />
        </div>
      );
    } else {
      return <Fragment>{file && <this.renderPrev />}</Fragment>;
    }
  }
}

FilePreview.propTypes = {
  file: PropTypes.instanceOf(File).isRequired,
};

export default FilePreview;
