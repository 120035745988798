import {
  PRODUCT_LIST,
  PRODUCT_CURRENT_PAGE,
  PRODUCT_SEARCH,
  PRODUCT_ARCHIVED_STATUS,
  PRODUCT_DETAILS,
  PRODUCT_LIST_DRAFT,
  PRODUCT_SHOW_LAST_VERSION_STATUS,
  PRODUCT_SORT_BY,
  PRODUCT_ARCHIVE_SORT_BY,
} from '../actions/types';

const intialState = {
  offset: 0,
  dataList: [],
  dataListDraft: [],
  filteredDataList: [],
  elements: [],
  perPage: 12,
  currentPage: 0,
  search: '',
  pageCount: 0,
  archivedStatus: false,
  showLastVersionStatus: false,
  productDetails: null,
  sortByList: ['lastBatch', /*'firstBatch',*/ 'lastCreatedProduct', 'alphabetical'],
  sortBy: 'lastBatch',
  sortByListArchive: ['dateOfArchivingDESC', 'dateOfArchivingASC'],
  sortByArchive: 'dateOfArchivingASC',
};

export default function (state = intialState, { type, payload }) {
  const filterLastVersionProducts = (dataList, showLastVersionStatus) => {
    return showLastVersionStatus
      ? Object.values(
          dataList.reduce((previousValue, currentValue) => {
            previousValue[currentValue?.Parent?._id] =
              previousValue[currentValue?.Parent?._id] &&
              previousValue[currentValue?.Parent?._id].Version > currentValue.Version
                ? previousValue[currentValue.Version]
                : currentValue;
            return previousValue;
          }, {}),
        )
      : dataList;
  };
  const filterSearchedProducts = (dataList, search) => {
    return dataList.filter((product) => {
      if (!search || search.length === 0) {
        return true;
      } else {
        let payloadT = search.toLowerCase();
        return (
          product.nameFR.toLowerCase().search(payloadT) !== -1 ||
          //product.nameEN.toLowerCase().search(payloadT) !== -1 ||
          product.codeEAN.toLowerCase().search(payloadT) !== -1
        );
      }
    });
  };

  const sortActiveProducts = (dataList, sortBy) => {
    return dataList
      .sort(function (a, b) {
        return b?.Batches?.length - a?.Batches?.length;
      })
      .sort(function (a, b) {
        const batchSortedA = a?.Batches?.sort((x, y) => new Date(y.creationDate) - new Date(x.creationDate));
        const batchSortedB = b?.Batches?.sort((x, y) => new Date(y?.creationDate) - new Date(x?.creationDate));
        const bVal = new Date(batchSortedB?.[0]?.creationDate);
        const aVal = new Date(batchSortedA?.[0]?.creationDate);
        switch (sortBy) {
          case 'lastCreatedProduct':
            return new Date(b?.date) - new Date(a?.date);
          case 'alphabetical':
            return a?.nameFR.localeCompare(b?.nameFR);
          case 'firstBatch':
            return aVal - bVal;
          case 'lastBatch':
          default:
            return bVal - aVal;
        }
      });
  };
  const sortArchivedProducts = (dataList, sortByArchive) => {
    return dataList.sort(function (a, b) {
      const bVal = new Date(b?.ArchivedChangeDate);
      const aVal = new Date(a?.ArchivedChangeDate);
      switch (sortByArchive) {
        case 'dateOfArchivingASC':
          return aVal - bVal;
        case 'dateOfArchivingDESC':
        default:
          return bVal - aVal;
      }
    });
  };
  const applyFilter = (dataList, search, archivedStatus, showLastVersionStatus, sortBy, sortByArchive) => {
    let filteredDataList = filterSearchedProducts(dataList, search);
    filteredDataList = filterLastVersionProducts(filteredDataList, showLastVersionStatus);
    filteredDataList = !archivedStatus
      ? sortActiveProducts(filteredDataList, sortBy)
      : sortArchivedProducts(filteredDataList, sortByArchive);
    return {
      ...state,
      filteredDataList: filteredDataList,
      pageCount: Math.ceil(filteredDataList.length / state.perPage),
      currentPage: 0,
      offset: 0,
    };
  };

  switch (type) {
    case PRODUCT_LIST:
      return {
        ...applyFilter(
          payload,
          state.search,
          state.archivedStatus,
          state.showLastVersionStatus,
          state.sortBy,
          state.sortByArchive,
        ),
        dataList: payload,
      };
    case PRODUCT_SEARCH:
      return {
        ...applyFilter(
          state.dataList,
          payload,
          state.archivedStatus,
          state.showLastVersionStatus,
          state.sortBy,
          state.sortByArchive,
        ),
        search: payload,
      };
    case PRODUCT_ARCHIVED_STATUS:
      return {
        ...applyFilter(
          state.dataList,
          state.search,
          payload,
          state.showLastVersionStatus,
          state.sortBy,
          state.sortByArchive,
        ),
        archivedStatus: payload,
      };
    case PRODUCT_SHOW_LAST_VERSION_STATUS:
      return {
        ...applyFilter(state.dataList, state.search, state.archivedStatus, payload, state.sortBy, state.sortByArchive),
        showLastVersionStatus: payload,
      };
    case PRODUCT_SORT_BY:
      return {
        ...applyFilter(
          state.dataList,
          state.search,
          state.archivedStatus,
          state.showLastVersionStatus,
          payload,
          state.sortByArchive,
        ),
        sortBy: payload,
      };
    case PRODUCT_ARCHIVE_SORT_BY:
      return {
        ...applyFilter(
          state.dataList,
          state.search,
          state.archivedStatus,
          state.showLastVersionStatus,
          state.sortBy,
          payload,
        ),
        sortByArchive: payload,
      };
    case PRODUCT_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
        offset: payload * state.perPage,
      };
    case PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: payload,
      };
    case PRODUCT_LIST_DRAFT:
      return {
        ...state,
        dataListDraft: payload,
      };
    default:
      return { ...state };
  }
}
