import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import appReducer from './appReducer';
import ingredientReducer from './ingredientReducer';
import partnerReducer from './partnerReducer.js';
import productReducer from './productReducer';
import profileReducer from './profileReducer.js';
import passwordChangeModalReducer from './passwordChangeModalReducer.js';
import siteInfoReducer from './siteInfoReducer';
import testCategoryReducer from './testCategoryReducer';
import testItemReducer from './testItemReducer';
import batchReducer from './batchReducer';

export default combineReducers({
  profile: profileReducer,
  ingredient: ingredientReducer,
  partner: partnerReducer,
  product: productReducer,
  app: appReducer,
  auth: authReducer,
  errors: errorReducer,
  passwordChangeModal: passwordChangeModalReducer,
  siteInfo: siteInfoReducer,
  testCategory: testCategoryReducer,
  testItem: testItemReducer,
  batch: batchReducer,
});
