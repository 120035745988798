import * as api from '../api.js';
import {
  APP_ERROR,
  APP_LOADING,
  APP_SUCCESS,
  PRODUCT_LIST,
  PRODUCT_SEARCH,
  PRODUCT_ARCHIVED_STATUS,
  PRODUCT_CURRENT_PAGE,
  PRODUCT_DETAILS,
  PRODUCT_LIST_DRAFT,
  PRODUCT_SHOW_LAST_VERSION_STATUS,
  PRODUCT_SORT_BY,
  PRODUCT_ARCHIVE_SORT_BY,
} from './types';

const buildProductFormData = (productData) => {
  let productDataBuild = new FormData();
  for (const property in productData) {
    if ({}.hasOwnProperty.call(productData, property)) {
      if (productData[property] && typeof productData[property] === 'object') {
        for (let i = 0; i < productData[property].length; i++) {
          if (
            productData[property][i] &&
            typeof productData[property][i] === 'object' &&
            property === 'Documentations'
          ) {
            for (const property2 in productData[property][i]) {
              if ({}.hasOwnProperty.call(productData[property][i], property2) && productData[property][i][property2]) {
                productDataBuild.append(property + '.' + property2 + '.' + i, productData[property][i][property2]);
              }
            }
          } else {
            productDataBuild.append(property, productData[property][i]);
          }
        }
      } else {
        productDataBuild.append(property, productData[property]);
      }
    }
  }
  return productDataBuild;
};

export const createProductSheet = (productData, history) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await api.createProductSheet(buildProductFormData(productData), headers);
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
    if (res.data?.createdFiche?._id) {
      const res2 = await api.getProductDetails(res.data?.createdFiche?._id);
      dispatch({
        type: PRODUCT_DETAILS,
        payload: res2.data,
      });
      history.push(`/ProductModifySheet/${res.data?.createdFiche?._id}`);
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      payload: ['Something went wrong.'],
    });
  }
};

export const modifyProductSheet = (productId, productData) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await api.modifyProductSheet(productId, buildProductFormData(productData), headers);
    const res2 = await api.getProductDetails(productId);
    dispatch({
      type: PRODUCT_DETAILS,
      payload: res2.data,
    });
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      payload: ['Something went wrong.'],
    });
  }
};

export const createProductVersion = (productId, parentProductId, productData, history) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await api.createProductVersion(productId, parentProductId, buildProductFormData(productData), headers);
    const { _id } = res.data?.versionProduct;
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
    const res2 = await api.getProductDetails(_id);
    dispatch({
      type: PRODUCT_DETAILS,
      payload: res2.data,
    });
    history.push('/ProductModifySheet/' + _id);
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      payload: ['Something went wrong.'],
    });
  }
};

export const getProducts = (active) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
    const res = active ? await api.getActiveProducts() : await api.getArchivedProducts();
    dispatch({
      type: PRODUCT_LIST,
      payload: res.data,
    });
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      payload: ['failed to load products.'],
    });
  }
};

export const getDraftProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
    const res = await api.getDraftProducts();
    dispatch({
      type: PRODUCT_LIST_DRAFT,
      payload: res.data,
    });
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      payload: ['failed to load products.'],
    });
  }
};

export const getProductDetails = (productId) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.getProductDetails(productId);
    dispatch({
      type: PRODUCT_DETAILS,
      payload: res.data,
    });
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      payload: ['Product load failed'],
    });
  }
};
export const resetProductDetails = () => (dispatch) => {
  dispatch({
    type: PRODUCT_DETAILS,
    payload: null,
  });
};

export const setProductSearchKey = (SearchKey) => async (dispatch) => {
  dispatch({
    type: PRODUCT_SEARCH,
    payload: SearchKey,
  });
};

export const setProductCurrentPage = (currentPage) => async (dispatch) => {
  dispatch({
    type: PRODUCT_CURRENT_PAGE,
    payload: currentPage,
  });
};

export const setProductArchivedStatus = (archivedStatus) => async (dispatch) => {
  dispatch({
    type: PRODUCT_ARCHIVED_STATUS,
    payload: archivedStatus,
  });
};

export const setProductShowLastVersionStatus = (showLastVersionStatus) => async (dispatch) => {
  dispatch({
    type: PRODUCT_SHOW_LAST_VERSION_STATUS,
    payload: showLastVersionStatus,
  });
};

export const setProductSortBy = (payload) => async (dispatch) => {
  dispatch({
    type: PRODUCT_SORT_BY,
    payload: payload,
  });
};

export const setProductArchiveSortBy = (payload) => async (dispatch) => {
  dispatch({
    type: PRODUCT_ARCHIVE_SORT_BY,
    payload: payload,
  });
};

export const archiveProductToggle = (productId) => async (dispatch, states) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    await api.archiveProductToggle(productId);
    await getProductDetails(productId)(dispatch);
    await getProducts(!states()?.product.archivedStatus)(dispatch);
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const deleteDraftProduct = (productId) => async (dispatch, states) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    await api.deleteDraftProduct(productId);
    await getProducts(!states()?.product.archivedStatus)(dispatch);
    await getDraftProducts()(dispatch);
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};
