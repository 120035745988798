export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const USER_LIST = 'USER_LIST';
export const FIRST_LOGIN_SUCCESSFUL = 'FIRST_LOGIN_SUCCESSFUL';

export const APP_LOADING = 'APP_LOADING';
export const APP_ERROR = 'APP_ERROR';
export const APP_SUCCESS = 'APP_SUCCESS';

export const INGREDIENT_LIST = 'INGREDIENT_LIST';
export const INGREDIENT_SEARCH = 'INGREDIENT_SEARCH';
export const INGREDIENT_CURRENT_PAGE = 'INGREDIENT_CURRENT_PAGE';
export const INGREDIENT_ARCHIVED_STATUS = 'INGREDIENT_ARCHIVED_STATUS';
export const INGREDIENT_DETAILS = 'INGREDIENT_DETAILS';

export const PARTNER_LIST = 'PARTNER_LIST';
export const PARTNER_SEARCH = 'PARTNER_SEARCH';
export const PARTNER_CURRENT_PAGE = 'PARTNER_CURRENT_PAGE';
export const PARTNER_ARCHIVED_STATUS = 'PARTNER_ARCHIVED_STATUS';
export const PARTNER_DETAILS = 'PARTNER_DETAILS';

export const PRODUCT_LIST = 'PRODUCT_LIST';
export const PRODUCT_SEARCH = 'PRODUCT_SEARCH';
export const PRODUCT_CURRENT_PAGE = 'PRODUCT_CURRENT_PAGE';
export const PRODUCT_ARCHIVED_STATUS = 'PRODUCT_ARCHIVED_STATUS';
export const PRODUCT_SHOW_LAST_VERSION_STATUS = 'PRODUCT_SHOW_LAST_VERSION_STATUS';
export const PRODUCT_DETAILS = 'PRODUCT_DETAILS';
export const PRODUCT_LIST_DRAFT = 'PRODUCT_LIST_DRAFT';
export const PRODUCT_SORT_BY = 'PRODUCT_SORT_BY';
export const PRODUCT_ARCHIVE_SORT_BY = 'PRODUCT_ARCHIVE_SORT_BY';

export const PASSWORD_CHANGE_MODAL_SHOW = 'PASSWORD_CHANGE_MODAL_SHOW';

export const SITE_INFO = 'SITE_INFO';

export const TEST_CATEGORY_LIST = 'TEST_CATEGORY_LIST';
export const TEST_CATEGORY_SEARCH = 'TEST_CATEGORY_SEARCH';
export const TEST_CATEGORY_DETAILS = 'TEST_CATEGORY_DETAILS';

export const TEST_ITEM_LIST = 'TEST_ITEM_LIST';
export const TEST_ITEM_SEARCH = 'TEST_ITEM_SEARCH';
export const TEST_ITEM_CURRENT_PAGE = 'TEST_ITEM_CURRENT_PAGE';
export const TEST_ITEM_ARCHIVED_STATUS = 'TEST_ITEM_ARCHIVED_STATUS';
export const TEST_ITEM_DETAILS = 'TEST_ITEM_DETAILS';
export const TEST_ITEM_ALL = 'TEST_ITEM_ALL';

export const BATCH_DETAILS = 'BATCH_DETAILS';
export const QUALITY_ANALYSIS_REPORT_ADD_MODAL_SHOW = 'QUALITY_ANALYSIS_REPORT_ADD_MODAL_SHOW';
export const TEST_REPORT_ADD_MODAL_SHOW = 'TEST_REPORT_ADD_MODAL_SHOW';
export const BATCH_DETAILS_MODAL_SHOW = 'BATCH_DETAILS_MODAL_SHOW';
