import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './style.scss';
import ThreeDotCircle from '../../images/three-dot-circle.svg';

class EmptyItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    const { loading } = this.props.app;
    return (
      <>
        {loading ? (
          <div className="text-center mt-5 pt-4">
            <div className="spinner-wrapper">
              <i className="fa fa-spin fa-spinner" />
            </div>
          </div>
        ) : (
          <div className="empty-item mt-5 pt-3">
            <img src={ThreeDotCircle} alt="" />
            <p>{t('No item has been created')}</p>
          </div>
        )}
      </>
    );
  }
}

EmptyItem.propTypes = {
  t: PropTypes.func,
  app: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
});

export default withTranslation()(connect(mapStateToProps, {})(EmptyItem));
