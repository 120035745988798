import * as api from '../api.js';
import {
  APP_ERROR,
  APP_LOADING,
  APP_SUCCESS,
  TEST_ITEM_LIST,
  TEST_ITEM_SEARCH,
  TEST_ITEM_CURRENT_PAGE,
  TEST_ITEM_ARCHIVED_STATUS,
  TEST_ITEM_DETAILS,
  TEST_ITEM_ALL,
} from './types';

export const createTestItem = (testCategoryId, testItemData, history) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.createTestItem(testCategoryId, testItemData);
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
    if (res.data?.testItem?._id) {
      const res2 = await api.getTestItemDetails(testCategoryId, res.data?.testItem?._id);
      dispatch({
        type: TEST_ITEM_DETAILS,
        payload: res2.data,
      });
      history.push(`/TestCategory/${testCategoryId}/TestItemModify/${res.data?.testItem?._id}`);
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const getTestItems = (testCategoryId) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.getTestItems(testCategoryId);
    dispatch({
      type: TEST_ITEM_LIST,
      payload: res.data,
    });
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const getTestItemAll = () => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.getTestItemAll();
    dispatch({
      type: TEST_ITEM_ALL,
      payload: res.data,
    });
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const archiveTestItemToggle = (testCategoryId, testItemId) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    await api.archiveTestItemToggle(testCategoryId, testItemId);
    await getTestItems(testCategoryId)(dispatch);
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const setTestItemSearchKey = (SearchKey) => async (dispatch) => {
  dispatch({
    type: TEST_ITEM_SEARCH,
    payload: SearchKey,
  });
};

export const setTestItemCurrentPage = (currentPage) => async (dispatch) => {
  dispatch({
    type: TEST_ITEM_CURRENT_PAGE,
    payload: currentPage,
  });
};

export const setTestItemArchivedStatus = (archivedStatus) => async (dispatch) => {
  dispatch({
    type: TEST_ITEM_ARCHIVED_STATUS,
    payload: archivedStatus,
  });
};

export const resetTestItemDetails = () => (dispatch) => {
  dispatch({
    type: TEST_ITEM_DETAILS,
    payload: null,
  });
};

export const getTestItemDetails = (testCategoryId, testItemId) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.getTestItemDetails(testCategoryId, testItemId);
    dispatch({
      type: TEST_ITEM_DETAILS,
      payload: res.data,
    });
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const modifyTestItem = (testCategoryId, testItemId, testItemData) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.modifyTestItem(testCategoryId, testItemId, testItemData);
    const res2 = await api.getTestItemDetails(testCategoryId, testItemId);
    dispatch({
      type: TEST_ITEM_DETAILS,
      payload: res2.data,
    });
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};
