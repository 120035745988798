import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { resetAppSuccess } from '../../actions/appActions';
import './style.scss';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import TimesColorIcon from '../../images/times_color.svg';
import * as yup from 'yup';
import { changePassword } from '../../actions/profileActions';
import { passwordChangeModalShow } from '../../actions/passwordChangeModalActions';
import { firstLoginSuccessful } from '../../actions/authActions';
import AppStatus from '../AppStatus';

class PasswordChangeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handlePasswordChangeModalClose = this.handlePasswordChangeModalClose.bind(this);
    this.handlePasswordChangeShow = this.handlePasswordChangeShow.bind(this);
  }

  handlePasswordChangeModalClose() {
    if (!this.props.auth.user.changePassword) {
      this.props.resetAppSuccess();
      this.props.passwordChangeModalShow(false);
    }
  }

  handlePasswordChangeShow() {
    this.props.passwordChangeModalShow(true);
  }

  render() {
    const { t } = this.props;
    const { user } = this.props.auth;
    const PasswordChangeSchema = yup.object().shape({
      NewPassword: yup
        .string()
        .required(t('Please enter a new password'))
        .min(10, t('The password should be 10 characters'))
        .matches(/[A-Z]/, t('The password should contain at least 1 capital letter'))
        .matches(/[\d]/, t('The password should contain at least 1 digit'))
        .matches(/[!@#$%&*()_]/, t('The password should contain at least 1 special character')),
      ConfirmPassword: yup
        .string()
        .required(t('Please confirm your new password'))
        .oneOf([yup.ref('NewPassword'), null], t('Passwords must match')),
    });
    return (
      <Formik
        initialValues={{
          NewPassword: '',
          ConfirmPassword: '',
        }}
        validationSchema={PasswordChangeSchema}
        onSubmit={(values, { resetForm }) => {
          this.props.changePassword(values.NewPassword);
          if (!user.firstLogin) {
            this.props.firstLoginSuccessful();
          }
          resetForm();
        }}
      >
        {(renderProps) => (
          <Modal
            show={this.props.passwordChangeModal.show}
            onHide={this.handlePasswordChangeModalClose}
            className="brand-modal password-change-modal"
          >
            <Modal.Header>
              <Modal.Title>{t('Change password')}</Modal.Title>
              <button onClick={this.handlePasswordChangeModalClose} type="button" className="modal-close-btn">
                <img src={TimesColorIcon} alt="close" />
              </button>
            </Modal.Header>
            <form onSubmit={renderProps.handleSubmit} onChange={this.props.resetAppSuccess}>
              <Modal.Body>
                <div className="form-group row pr-3">
                  <label htmlFor="NewPassword" className="col-lg-6 col-form-label">
                    {t('New Password')}
                  </label>
                  <input
                    className="form-control col-lg-6"
                    type="password"
                    name="NewPassword"
                    id="NewPassword"
                    value={renderProps.values.NewPassword}
                    onChange={(ev) => {
                      renderProps.handleChange(ev);
                    }}
                    onBlur={renderProps.handleBlur}
                    required
                  />
                </div>
                <div className="form-group row pr-3">
                  <label htmlFor="ConfirmPassword" className="col-lg-6 col-form-label">
                    {t('Password Confirmation')}
                  </label>
                  <input
                    className="form-control col-lg-6"
                    type="password"
                    name="ConfirmPassword"
                    id="ConfirmPassword"
                    value={renderProps.values.ConfirmPassword}
                    onChange={(ev) => {
                      renderProps.handleChange(ev);
                    }}
                    onBlur={renderProps.handleBlur}
                    required
                  />
                </div>
                <div className="password-requirements mb-3">
                  <div className="password-requirements-h">{t('The password must include')}</div>
                  <div>
                    {t('10 characters minimum, including at least 1 capital letter, 1 digit, 1 special character')}
                  </div>
                </div>
                <div className="">
                  {renderProps.errors.NewPassword && renderProps.touched.NewPassword ? (
                    <div className="alert alert-danger">{renderProps.errors.NewPassword}</div>
                  ) : null}
                  {renderProps.errors.ConfirmPassword && renderProps.touched.ConfirmPassword ? (
                    <div className="alert alert-danger">{renderProps.errors.ConfirmPassword}</div>
                  ) : null}
                  <AppStatus />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={this.handlePasswordChangeModalClose}
                  className="btn btn-custom-outline-common px-3 mr-2"
                >
                  {t('Cancel the modification')}
                </button>
                {this.props.app.loading ? (
                  <div className="spinner-wrapper">
                    <i className="fa fa-spin fa-spinner" />
                  </div>
                ) : (
                  <button type="submit" className="btn btn-custom-common px-3">
                    {t('Change the password')}
                  </button>
                )}
              </Modal.Footer>
            </form>
          </Modal>
        )}
      </Formik>
    );
  }
}

PasswordChangeModal.propTypes = {
  t: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  app: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  passwordChangeModal: PropTypes.object.isRequired,
  passwordChangeModalShow: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  firstLoginSuccessful: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
  auth: state.auth,
  passwordChangeModal: state.passwordChangeModal,
});

export default withTranslation()(
  connect(mapStateToProps, { changePassword, resetAppSuccess, passwordChangeModalShow, firstLoginSuccessful })(
    PasswordChangeModal,
  ),
);
