import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import LanguageSwitcher from '../LanguageSwitcher';
import { withTranslation } from 'react-i18next';
import logo from '../../images/logo.png';
import './style.scss';
import PicoPeopleIcon from '../../images/pico-people.svg';
import { isManufacturerOrLaboratory } from '../../utils/roleUtils';

class Headerbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    const { user, isAuthenticated } = this.props.auth;
    return (
      <header className="toolbar">
        <Container>
          <Row>
            <Col sm={3} xs={5}>
              <div className="">
                <Link to={isManufacturerOrLaboratory() ? '/ProductList' : '/'} className="toolbar_logo">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
            </Col>
            <Col sm={9} xs={7} className="text-right">
              <ul className="toolbar-menu">
                {isAuthenticated ? (
                  <>
                    <li>
                      <Link to="/Profile">
                        <img src={PicoPeopleIcon} alt="" />
                        <span>
                          {user?.firstName} {user?.lastName}
                        </span>
                      </Link>
                    </li>
                    <li onClick={this.props.logoutUser}>
                      <span>{t('Logout')}</span>
                    </li>
                  </>
                ) : (
                  <li>
                    <Link to="/login">{t('Login')}</Link>
                  </li>
                )}
                <li>
                  <LanguageSwitcher />
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </header>
    );
  }
}

Headerbar.propTypes = {
  t: PropTypes.func,
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withTranslation()(
  connect(mapStateToProps, {
    logoutUser,
  })(Headerbar),
);
