import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

class Sidebar extends Component {
  PicoHomeIcon() {
    return (
      <svg width="37" height="32" viewBox="0 0 37 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.459388 15.544C0.558637 15.544 0.657885 15.5075 0.739088 15.4435L3.61729 13.1122V31.5429C3.61729 31.7989 3.81578 32 4.06841 32H32.9406C33.1932 32 33.3917 31.7989 33.3917 31.5429V13.1122L36.2699 15.4435C36.3511 15.5075 36.4504 15.544 36.5496 15.544C36.685 15.544 36.8113 15.4892 36.9015 15.3703C37.0549 15.1692 37.0278 14.8858 36.8293 14.7304L33.2203 11.8049L18.7842 0.10285C18.6218 -0.0342833 18.3872 -0.0342833 18.2158 0.10285L3.77969 11.8049L0.170666 14.7304C-0.0278299 14.8858 -0.0548977 15.1783 0.0984859 15.3703C0.197734 15.4892 0.32405 15.544 0.459388 15.544ZM4.51954 12.3808L18.5045 1.0445L32.4895 12.3808V31.0858H4.51954V12.3808Z"
          fill="white"
        />
      </svg>
    );
  }

  PicoProductIcon() {
    return (
      <svg width="25" height="44" viewBox="0 0 25 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13 43C13 43 8.27742 43 6.26452 43C3.47742 43 1 41.9365 1 39.3718C1 36.807 4.9871 30.8329 4.9871 24.6086C4.9871 18.3843 3.05161 15.2878 2.81935 14.5058C2.31613 12.6917 3.36129 11.6282 5.37419 10.3458C6.53548 9.62645 9.7871 8 9.7871 8H12.9613"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 43C13 43 17.329 43 19.1742 43C21.729 43 24 41.9365 24 39.3718C24 36.807 20.3452 30.8016 20.3452 24.6086C20.3452 18.4155 22.0839 15.2878 22.2968 14.5058C22.7581 12.6917 21.8 11.6282 19.9548 10.3458C18.8903 9.62645 15.9097 8 15.9097 8H13"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4463 3.55521V1H13.8136V3.55521"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.49219 7.95172V4.1189H15.7675V7.95172"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 33C15.7614 33 18 29.4183 18 25C18 20.5817 15.7614 17 13 17C10.2386 17 8 20.5817 8 25C8 29.4183 10.2386 33 13 33Z"
          stroke="white"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  PicoPeopleIcon() {
    return (
      <svg width="26" height="31" viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.9233 17.8319H7.8643C3.56637 17.8319 0 21.3983 0 25.6962V31H1.37168V25.6962C1.37168 22.1298 4.29793 19.2036 7.8643 19.2036H17.9233C21.4897 19.2036 24.4159 22.1298 24.4159 25.6962V31H25.7876V25.6962C25.7876 21.3983 22.2212 17.8319 17.9233 17.8319Z"
          fill="white"
        />
        <path
          d="M12.8936 15.9115C17.283 15.9115 20.8493 12.3451 20.8493 7.95575C20.8493 3.56637 17.283 0 12.8936 0C8.50422 0 5.0293 3.65782 5.0293 7.95575C5.0293 12.2537 8.50422 15.9115 12.8936 15.9115ZM12.8936 1.46312C16.5514 1.46312 19.4777 4.38938 19.4777 8.04719C19.4777 11.705 16.5514 14.6313 12.8936 14.6313C9.23578 14.6313 6.30953 11.705 6.30953 8.04719C6.40098 4.38938 9.32723 1.46312 12.8936 1.46312Z"
          fill="white"
        />
      </svg>
    );
  }

  render() {
    return (
      <aside className="sidebar-container">
        <ul className="sidebar-menu">
          <li>
            <Link to="/">{this.PicoHomeIcon()}</Link>
          </li>
          <li>
            <Link to="/ProductList">{this.PicoProductIcon()}</Link>
          </li>
          <li>
            <Link to="/PartnerList">{this.PicoPeopleIcon()}</Link>
          </li>
        </ul>
      </aside>
    );
  }
}

export default Sidebar;
