import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './style.scss';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import groupproduit from '../../images/Group-147.png';
import grouppartenaire from '../../images/Group-148.png';
import { isAdmin } from '../../utils/roleUtils';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <Container className="mt-5 pt-4">
        <Row className="justify-content-center">
          <Col md={8}>
            <div className="dashboard-card-1 mb-30px">
              <img src={groupproduit} alt="" />
              <h2>{t('Products')}</h2>
              <div className="text-right">
                <Link to="/ProductList" className="btn">
                  {t('See the list of products')}
                </Link>
              </div>
            </div>
            {isAdmin() && (
              <>
                <div className="dashboard-card-1 mb-30px">
                  <img src={grouppartenaire} alt="" />
                  <h2>{t('Partners')}</h2>
                  <div className="text-right">
                    <Link to="/PartnerList" className="btn">
                      {t('See the list of partners')}
                    </Link>
                    jj
                  </div>
                </div>
                <Row>
                  <Col md={6}>
                    <div className="dashboard-card-2 mb-30px">
                      <h2>{t('Ingredients')}</h2>
                      <Link to="/IngredientList">{t('See the list of ingredients')} →</Link>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="dashboard-card-2 mb-30px">
                      <h2>{t('SECURITY TESTS')}</h2>
                      <Link to="/TestCategoryList">{t('See the list of security testing')} →</Link>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="dashboard-card-2 mb-30px">
                      <h2>
                        <span>{t('consumer site')}</span>
                        {t('learn more')}
                      </h2>
                      <Link to="/ConsumerSiteKnowMore">{t('Edit page')} →</Link>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="dashboard-card-2 mb-30px">
                      <h2>
                        <span>{t('consumer site')}</span>
                        {t('scoring method')}
                      </h2>
                      <Link to="/ConsumerSiteScoringMethod">{t('Edit page')} →</Link>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

Dashboard.propTypes = {
  t: PropTypes.func,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withTranslation()(connect(mapStateToProps, {})(Dashboard));
