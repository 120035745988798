import React, { Component, Fragment } from 'react';
import './style.scss';
import { Dropdown } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { i18n, t } = this.props;
    const lang = [
      {
        langCode: 'fr',
        langCodeWithCountry: 'fr-FR',
        landLabel: 'French',
        langCountry: 'fr',
      },
      {
        langCode: 'en',
        langCodeWithCountry: 'en-US',
        landLabel: 'English',
        langCountry: 'gb',
      },
    ];
    let langFound = false;
    lang.map((langItem) => {
      if (langItem.langCodeWithCountry === i18n.language) {
        langFound = true;
      }
      return langFound;
    });
    if (!langFound) {
      i18n.changeLanguage(i18n.options.fallbackLng[0]);
    }
    return (
      <Fragment>
        <Dropdown className="lang-switcher" alignRight>
          {lang?.map((langItem, index) => {
            return (
              <Fragment key={index}>
                {langItem.langCodeWithCountry === i18n.language && (
                  <Dropdown.Toggle>
                    <i className={'flag-icon flag-icon-' + langItem.langCountry} /> {langItem.langCode}{' '}
                    <i className="fas fa-chevron-down" />
                  </Dropdown.Toggle>
                )}
              </Fragment>
            );
          })}
          <Dropdown.Menu>
            {lang?.map((langItem, index) => (
              <Fragment key={index}>
                <button
                  className={i18n.language === langItem.langCodeWithCountry ? 'active-lang' : ''}
                  type="button"
                  onClick={() => {
                    i18n.changeLanguage(langItem.langCodeWithCountry);
                  }}
                >
                  <i className={'flag-icon flag-icon-' + langItem.langCountry} />
                  {t(langItem.landLabel)}
                </button>
                <Dropdown.Divider />
              </Fragment>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Fragment>
    );
  }
}
LanguageSwitcher.propTypes = {
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withTranslation()(LanguageSwitcher);
