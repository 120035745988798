import { APP_ERROR, APP_LOADING, APP_SUCCESS } from '../actions/types';

const initialState = {
  error: [],
  success: [],
  loading: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case APP_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case APP_SUCCESS:
      return {
        ...state,
        success: payload,
        loading: false,
      };
    case APP_LOADING:
      return {
        ...state,
        loading: payload,
        error: [],
        success: [],
      };
    default:
      return state;
  }
}
