import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FilePreview from '../FilePreview';
import { getProductDetails, resetProductDetails } from '../../actions/productActions';
import { getBatchDetails, resetBatchDetails, createBatch, modifyBatch } from '../../actions/batchActions';
import { resetAppSuccess } from '../../actions/appActions';
import { Link } from 'react-router-dom';
import { Container, Row, Col, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Formik } from 'formik';
import './style.scss';
import TimesIcon from '../../images/times.svg';
import config from '../../config';
import AppStatus from '../AppStatus';
const { API_URL } = config;

class BatchCreateModify extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { productId, batchId } = this.props.match.params;
    if (productId) {
      this.props.getProductDetails(productId);
    } else {
      this.props.resetProductDetails();
    }
    if (batchId) {
      this.props.getBatchDetails(batchId);
    } else {
      this.props.resetBatchDetails();
    }
  }

  render() {
    const { t } = this.props;
    const { productId, batchId } = this.props.match.params;
    const { productDetails } = this.props.product;
    const { batchDetails } = this.props.batch;
    const { user } = this.props.auth;
    const BatchCreateModifySchema = yup.object().shape({
      lotNumber: yup.string().required(t('Please enter lot number')),
      creationDate: yup
        .string()
        .required(t('Please enter creation date'))
        .matches(/^\d{1,2}\/\d{1,2}\/\d{4}$/, {
          excludeEmptyString: true,
          message: t('Please enter correct creation date'),
        }),
      analysisReport: yup.mixed().required(t('Please select a quality report')),
      analysisReportDate: yup.string().required(t('please enter a date')).matches(/^\d{1,2}\/\d{1,2}\/\d{4}$/, {
        excludeEmptyString: true,
        message: t('Please enter correct analysis report date'),
      }),
    });
    return (
      <>
        <div className="top-bar">
          <Container>
            <Link to={`/ProductDetails/${productId}`} className="top-bar-left-link">
              <i>←</i> {t('Return to product')}
            </Link>
          </Container>
        </div>
        {(batchId && batchDetails && productId && productDetails) ||
        (!batchId && !batchDetails && productId && productDetails) ? (
          <Formik
            enableReinitialize
            initialValues={{
              lotNumber: batchDetails ? batchDetails.lotNumber : '',
              creationDate: batchDetails ? batchDetails.creationDate : '',
              analysisReport: batchDetails ? batchDetails.analysisReport : null,
              analysisReportDate: batchDetails ? batchDetails.analysisReportDate : '',
              product: productId,
              user: user?.id,
              partner: user?.partnerData?._id,
              analysisReportUser: user?.id,
              analysisReportPartner: user?.partnerData?._id,
            }}
            validationSchema={BatchCreateModifySchema}
            onSubmit={(values) => {
              if (batchId) {
                this.props.modifyBatch(batchId, values);
                console.log("modifybatchokpokkk", values);
              } else {
                this.props.createBatch(values, this.props.history);
                console.log("analyseReport", values.analysisReport);
              }
            }}
          >
            {(renderProps) => (
              <form className="mt-5 " onSubmit={renderProps.handleSubmit} onChange={this.props.resetAppSuccess}>
                <Container>
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      <div className="text-center mb-5">
                        <h5 className="page_head_sub">{batchId ? t('Update') : t('Create a')}</h5>
                        <h1 className="page_head "> {batchId ? t('Batch') : t('New Batch')}</h1>
                      </div>
                      <FormGroup as={Row}>
                        <FormLabel column lg={5}>
                          {t('Category')}
                        </FormLabel>
                        <Col lg={7}>
                          <FormControl type="text" value={productDetails?.Categorie} disabled />
                        </Col>
                      </FormGroup>
                      <FormGroup as={Row}>
                        <FormLabel column lg={5}>
                          {t('Name')}
                        </FormLabel>
                        <Col lg={7}>
                          <FormControl type="text" value={productDetails?.nameFR} disabled />
                        </Col>
                      </FormGroup>
                      <FormGroup as={Row} controlId="lotNumber">
                        <FormLabel column lg={5}>
                          {t('Lot Number')}
                        </FormLabel>
                        <Col lg={7}>
                          <FormControl
                            type="text"
                            name="lotNumber"
                            value={renderProps.values.lotNumber}
                            onChange={(ev) => {
                              renderProps.handleChange(ev);
                            }}
                            onBlur={renderProps.handleBlur}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup as={Row} controlId="creationDate">
                        <FormLabel column lg={5}>
                          {t('Date of manufacture')}
                        </FormLabel>
                        <Col lg={7}>
                          <FormControl
                            type="text"
                            name="creationDate"
                            value={renderProps.values.creationDate}
                            onChange={(ev) => {
                              renderProps.handleChange(ev);
                            }}
                            onBlur={renderProps.handleBlur}
                            placeholder={t('DD/MM/YYYY')}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Container>
                <div className="bg_block pt-5 pb-4 my-5">
                  <Container>
                    <Row className="justify-content-center">
                      <Col lg={8}>
                        <h3 className="page_head_2 mb-3">
                          {t('Product quality control')}
                          <span className="d-block font-weight-normal font-smallest">
                            {t('YOU CAN ADD THE ANALYSIS REPORT LATER')}
                          </span>
                        </h3>
                        <Row className=" mt-3">
                          <Col lg={3}>
                            <div className="rect_box_wrapper">
                              <div className="rect_box" style={{ height: 220, marginBottom: 10 }}>
                                <input
                                  type="file"
                                  name="analysisReport"
                                  id="analysisReport"
                                  className="d-none"
                                  onBlur={renderProps.handleBlur}
                                  onChange={(ev) => {
                                    renderProps.setFieldValue('analysisReport', ev.currentTarget.files);
                                  }}
                                />
                                <label htmlFor="analysisReport" className="rect-lbl">
                                  +
                                </label>
                                {renderProps?.values?.analysisReport && (
                                  <div className="preview_container">
                                    {batchId && (
                                      <button
                                        onClick={(ev) => {
                                          renderProps.setFieldValue('analysisReport', undefined);
                                          document.getElementById('analysisReport').value = '';
                                          console.log("APIIII", API_URL.concat(`/${renderProps.values.analysisReport}`))
                                        }}
                                        type="button"
                                        className="preview_close"
                                      >
                                        <img src={TimesIcon} alt="remove" />
                                      </button>
                                    )}
                                    {renderProps?.values?.analysisReport &&
                                    typeof renderProps?.values?.analysisReport === 'string' ? (
                                      <img
                                      src={API_URL.concat(`/${renderProps.values.analysisReport}`)}
                                      alt=""
                                      className="img-fluid prev_image"
                                      />
                                      ) : (
                                        renderProps?.values?.analysisReport && (
                                          <FilePreview file={renderProps.values.analysisReport[0]} />
                                          )
                                          )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <FormGroup controlId="analysisReportDate">
                              <FormControl
                                type="text"
                                name="analysisReportDate"
                                value={renderProps.values.analysisReportDate}
                                onChange={(ev) => {
                                  renderProps.handleChange(ev);
                                }}
                                onBlur={renderProps.handleBlur}
                                placeholder={t('DD/MM/YYYY')}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <Container className="mb-5">
                  <div className="divider" />
                </Container>
                <Container>
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      <div className="">
                        {renderProps.errors.lotNumber && renderProps.touched.lotNumber ? (
                          <div className="alert alert-danger">{renderProps.errors.lotNumber}</div>
                        ) : null}
                        {renderProps.errors.creationDate && renderProps.touched.creationDate ? (
                          <div className="alert alert-danger">{renderProps.errors.creationDate}</div>
                        ) : null}
                        {renderProps.errors.analysisReport && renderProps.touched.analysisReport ? (
                          <div className="alert alert-danger">{renderProps.errors.analysisReport}</div>
                        ) : null}
                        {renderProps.errors.analysisReportDate && renderProps.touched.analysisReportDate ? (
                          <div className="alert alert-danger">{renderProps.errors.analysisReportDate}</div>
                        ) : null}
                        <AppStatus />
                      </div>
                    </Col>
                  </Row>
                  <div className="mb-5 mt-3 pb-5 text-right form-footer">
                    {this.props.app.loading ? (
                      <div className="spinner-wrapper">
                        <i className="fa fa-spin fa-spinner" />
                      </div>
                    ) : (
                      <button type="submit" className="btn btn-custom ">
                        {batchId ? t('Update batch') : t('Create the batch')}
                      </button>
                    )}
                  </div>
                </Container>
              </form>
            )}
          </Formik>
        ) : (
          <div className="text-center mt-5">
            <div className="spinner-wrapper">
              <i className="fa fa-spin fa-spinner" />
            </div>
          </div>
        )}
      </>
    );
  }
}

BatchCreateModify.propTypes = {
  t: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  createBatch: PropTypes.func.isRequired,
  modifyBatch: PropTypes.func.isRequired,
  getBatchDetails: PropTypes.func.isRequired,
  resetBatchDetails: PropTypes.func.isRequired,
  resetProductDetails: PropTypes.func.isRequired,
  getProductDetails: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  app: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  batch: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
  product: state.product,
  batch: state.batch,
  auth: state.auth,
});

export default withTranslation()(
  connect(mapStateToProps, {
    resetAppSuccess,
    getProductDetails,
    resetProductDetails,
    getBatchDetails,
    resetBatchDetails,
    createBatch,
    modifyBatch,
  })(BatchCreateModify),
);
