import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  qualityAnalysisReportAddModalShowToggle,
  testReportAddModalShowToggle,
  batchDetailsModalShowToggle,
  getBatchDetails,
  batchAvailableToggle
} from '../../actions/batchActions';
import { resetAppSuccess } from '../../actions/appActions';
import { getTestItemAll } from '../../actions/testItemActions';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import './style.css';
import AddIcon from '../../images/add.svg';
import CheckMarkGreen from '../../images/check_mark_green.svg';
import CheckMarkOrange from '../../images/check_mark_orange.svg';
import BatchQualityReportAddModal from '../BatchQualityReportAddModal';
import BatchTestReportAddModal from '../BatchTestReportAddModal';
import BatchDetails from '../BatchDetails';
import {
  isManufacturer,
  isLaboratory,
  isAdminOrManufacturer,
  isAdminOrLaboratory,
  isAdmin,
} from '../../utils/roleUtils';
import Roles from '../../utils/roles';
import DotRed from '../../images/red-dot.svg';
import DotGreen from '../../images/green-dot.svg';
import DotOrange from '../../images/orange-dot.svg';
import Col from "react-bootstrap/Col";

class BatchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qualityAnalysisReportAddBatchId: '',
      testAnalysisReportAddBatchId: '',
      batchIdForDetails: '',
    };
  }
  componentDidMount() {
    this.props.getTestItemAll();
  }

  render() {
    const { productDetails, t } = this.props;
    const { user } = this.props.auth;
    const { qualityAnalysisReportAddModalShow, testReportAddModalShow, batchDetailsModalShow, batchDetails } = this.props.batch;
    return (
      <>
        <h4 className="product-details-item-header mt-2 mb-4">
          {t('Associated batches')}
          {isManufacturer() && (
            <Link to={'/BatchCreate/' + productDetails?._id} className="top-bar-right-link  float-right mr-1">
              <i className="action-icon rounded-circle mr-2">
                <img src={AddIcon} alt="Add" />
              </i>
              {t('Create a new batch')}
            </Link>
          )}
        </h4>
        <Table responsive striped borderless hover className="w-100 brand-table mb-5 sortable">
          <thead>
            <tr>
              <th>{t('Lot Number')}</th>
              <th>{t('Manufacturing date')}</th>
              {isAdmin() && <th>{t('Manufacturer status')}</th>}
              {isManufacturer() && <th>{t('Lot information')}</th>}
              {isAdminOrManufacturer() && <th>{t('Product quality control status')}</th>}
              {isAdminOrLaboratory() && <th>{t('Product safety analysis status')}</th>}
              {isAdmin() && <th>{t('Note')}</th>}
              <th />
            </tr>
          </thead>
          <tbody>
            
            {productDetails?.Batches?.sort((x, y) => new Date(y.creationDate) - new Date(x.creationDate))?.map(
              (batch, index) => {
                const testReportLaboratory = batch?.testReports?.find(
                  (item) => item?.partner?._id === user?.partnerData?._id,
                );
                const allLaboratories = productDetails?.Partners?.filter(
                  (item) => item?.Categorie === Roles.Laboratory,
                );
                const allTestReport = batch?.testReports;
                const testItemDetected = batch?.testReports?.reduce(
                  (acc, testReport) => acc + testReport?.testItemMolecules?.length + testReport?.testItems?.length,
                  0,
                );
                let calcWithCoefficient = 0;
                for (let l = 0; l < batchDetails?.testReports?.length; l++) {
                  const singleTest = batchDetails?.testReports?.[l];
                  for (let u = 0; u < singleTest?.testItemMolecules?.length; u++) {
                    const testItemMolecule = singleTest?.testItemMolecules?.[u];
                    console.log("X testItemMolecule", singleTest?.testItemMolecules?.[u])
                    console.log("X testItemMolecules?.length", singleTest.testItemMolecules?.length)
                    if (testItemMolecule?.TestItem?.testCategory?.coefficient) {
                      calcWithCoefficient += testItemMolecule?.TestItem?.testCategory?.coefficient;
                      console.log("X calcWithCoefficient", calcWithCoefficient)
                    }
                  }
                  for (let u = 0; u < singleTest?.testItems?.length; u++) {
                    const testItem = singleTest?.testItems?.[u];
                    if (testItem?.testCategory?.coefficient) {
                      calcWithCoefficient += testItem?.testCategory?.coefficient;
                    }
                  }
                }
                const score = Math.round(((productDetails.Molecules.length - calcWithCoefficient) / productDetails.Molecules.length) * 100);

                let noteIcon = '';
                if (score <= 30) {
                  noteIcon = DotRed;
                } else if (score > 30 && score <= 70) {
                  noteIcon = DotOrange;
                } else if (score > 70) {
                  noteIcon = DotGreen;
                }
                return (
                  <tr key={index}>
                    <td>{batch?.lotNumber}</td>
                    <td>{batch?.creationDate}</td>
                    {isManufacturer() && (
                      <td>
                        <img src={CheckMarkGreen} alt="yes" />
                      </td>
                    )}
                    {isAdmin() && (
                      <td>
                        <img src={CheckMarkGreen} alt="yes" />
                      </td>
                    )}
                    {isAdminOrManufacturer() && (
                      <td>
                        {batch?.analysisReport && batch?.analysisReportDate ? (
                          <img src={CheckMarkGreen} alt="yes" />
                        ) : isManufacturer() ? (
                          <button
                            className="reset-btn  brand-color"
                            type="button"
                            onClick={() => {
                              this.setState({ qualityAnalysisReportAddBatchId: batch?._id });
                              this.props.qualityAnalysisReportAddModalShowToggle();
                            }}
                          >
                            {t('Add the analysis report')}
                          </button>
                        ) : (
                          '...'
                        )}
                      </td>
                    )}
                    {isAdminOrLaboratory() && (
                      <td className="row">
                        <Col>{isAdmin() &&
                        <div className="custom-control custom-switch">
                          <input type="checkbox" className="custom-control-input" id={batch?._id} onChange={()=>{
                            this.props.batchAvailableToggle(batch?._id,productDetails?._id);
                          }} checked={!batch?.Available} />
                            <label className="custom-control-label" htmlFor={batch?._id}>
                            </label>
                        </div>
                        }
                        </Col>
                       <Col>
                         {isLaboratory() ? (
                             testReportLaboratory ? (
                                 <img src={CheckMarkGreen} alt="yes" />
                             ) : (
                                 <button
                                     className="reset-btn brand-color"
                                     type="button"
                                     onClick={() => {
                                       this.setState({ testAnalysisReportAddBatchId: batch?._id });
                                       this.props.testReportAddModalShowToggle();
                                     }}
                                 >
                                   {t('Add the analysis report')}
                                 </button>
                             )
                         ) : (
                             <div className="d-flex">
                               {productDetails?.Partners?.filter((item) => item?.Categorie === Roles.Laboratory)?.map(
                                   (singlePartner, indexP) => {
                                     const testReport = batch?.testReports?.find(
                                         (item) => item?.partner?._id === singlePartner?._id,
                                     );
                                     const totalDetected =
                                         testReport?.testItemMolecules?.length + testReport?.testItems?.length;
                                     return (
                                         <div className="" style={{ width: 75 }} key={indexP}>
                                           {testReport ? (
                                               totalDetected > 0 ? (
                                                   <img src={CheckMarkOrange} alt="yes" />
                                               ) : (
                                                   <img src={CheckMarkGreen} alt="yes" />
                                               )
                                           ) : (
                                               '...'
                                           )}
                                         </div>
                                     );
                                   },
                               )}
                             </div>
                         )}
                       </Col>
                      </td>
                    )}
                    {isAdmin() && (
                      <td>
                        {allLaboratories?.length === allTestReport?.length && (
                          <img src={noteIcon} style={{ width: 20 }} alt="" />
                        )}
                      </td>
                    )}
                    <td>
                      <button
                        onClick={() => {
                          this.setState({ batchIdForDetails: batch?._id });
                          this.props.getBatchDetails(batch?._id);
                          this.props.batchDetailsModalShowToggle();
                        }}
                        className="reset-btn brand-color"
                      >
                        {t('See')} →
                      </button>
                    </td>
                  </tr>
                );
              },
            )}
          </tbody>
        </Table>
        {qualityAnalysisReportAddModalShow && (
          <BatchQualityReportAddModal
            batchId={this.state.qualityAnalysisReportAddBatchId}
            productId={productDetails?._id}
          />
        )}
        {testReportAddModalShow && (
          <BatchTestReportAddModal batchId={this.state.testAnalysisReportAddBatchId} productId={productDetails?._id} />
        )}
        {batchDetailsModalShow && <BatchDetails productDetails={productDetails} />}
      </>
    );
  }
}

BatchList.propTypes = {
  batchAvailableToggle: PropTypes.func.isRequired,
  getBatchDetails: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  qualityAnalysisReportAddModalShowToggle: PropTypes.func.isRequired,
  testReportAddModalShowToggle: PropTypes.func.isRequired,
  getTestItemAll: PropTypes.func.isRequired,
  batchDetailsModalShowToggle: PropTypes.func.isRequired,
  app: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  productDetails: PropTypes.object.isRequired,
  batch: PropTypes.object.isRequired,
  testItem: PropTypes.object.isRequired,
  t: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  app: state.app,
  auth: state.auth,
  batch: state.batch,
  testItem: state.testItem,
});

export default withTranslation()(
  connect(mapStateToProps, {
    resetAppSuccess,
    qualityAnalysisReportAddModalShowToggle,
    testReportAddModalShowToggle,
    batchDetailsModalShowToggle,
    getBatchDetails,
    getTestItemAll,
    batchAvailableToggle,
  })(BatchList),
);
