import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { resetAppSuccess } from '../../actions/appActions';
import './style.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { changePassword } from '../../actions/profileActions';
import { firstLoginSuccessful } from '../../actions/authActions';
import { withRouter } from 'react-router-dom';
import { isManufacturerOrLaboratory, isManufacturer } from '../../utils/roleUtils';
import AppStatus from '../AppStatus';

class PasswordChange extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    const { user } = this.props.auth;
    const PasswordChangeSchema = yup.object().shape({
      NewPassword: yup
        .string()
        .required(t('Please enter a new password'))
        .min(10, t('The password should be 10 characters'))
        .matches(/[A-Z]/, t('The password should contain at least 1 capital letter'))
        .matches(/[\d]/, t('The password should contain at least 1 digit'))
        .matches(/[!@#$%&*()_]/, t('The password should contain at least 1 special character')),
      ConfirmPassword: yup
        .string()
        .required(t('Please confirm your new password'))
        .oneOf([yup.ref('NewPassword'), null], t('Passwords must match')),
    });
    return (
      <Formik
        initialValues={{
          NewPassword: '',
          ConfirmPassword: '',
        }}
        validationSchema={PasswordChangeSchema}
        onSubmit={(values, { resetForm }) => {
          this.props.changePassword(values.NewPassword);
          if (!user?.firstLogin) {
            this.props.firstLoginSuccessful(this.props.history, isManufacturerOrLaboratory() ? '/ProductList' : '/');
          }
          resetForm();
        }}
      >
        {(renderProps) => (
          <>
            <div className="container-fluid mt-5 mb-5">
              <div className="text-center mb-4">
                <h1 className="page_head ">
                  {t('Welcome')} {user?.firstName} {user?.lastName}
                </h1>
              </div>
            </div>
            <Container>
              <div className="mt-5 py-3">
                {isManufacturer() ? <h2 className="page_head_3 mb-1 ">{t('Step')} 2/2</h2> : ''}
                <h3 className="page_head_2 mb-2 pb-1">{t('Creation of your password')}</h3>
              </div>
              <div className="divider" />
              <form className="mt-5" onSubmit={renderProps.handleSubmit} onChange={this.props.resetAppSuccess}>
                <Row className="my-5">
                  <Col md={8}>
                    <div className="form-group row pr-3">
                      <label htmlFor="NewPassword" className="col-lg-6 col-form-label text-right">
                        {t('Password')}
                      </label>
                      <input
                        className="form-control col-lg-6"
                        type="password"
                        name="NewPassword"
                        id="NewPassword"
                        value={renderProps.values.NewPassword}
                        onChange={(ev) => {
                          renderProps.handleChange(ev);
                        }}
                        onBlur={renderProps.handleBlur}
                        required
                      />
                    </div>
                    <div className="form-group row pr-3">
                      <label htmlFor="ConfirmPassword" className="col-lg-6 col-form-label text-right">
                        {t('Password Confirmation')}
                      </label>
                      <input
                        className="form-control col-lg-6"
                        type="password"
                        name="ConfirmPassword"
                        id="ConfirmPassword"
                        value={renderProps.values.ConfirmPassword}
                        onChange={(ev) => {
                          renderProps.handleChange(ev);
                        }}
                        onBlur={renderProps.handleBlur}
                        required
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="password-requirements" style={{ lineHeight: 1.3 }}>
                      <div className="password-requirements-h">{t('The password must include')} :</div>
                      <div style={{ lineHeight: 1.5 }}>
                        {t('10 characters minimum, at least of which')}
                        <ul className="mb-0">
                          <li>1 {t('uppercase')}</li>
                          <li>1 {t('digit')}</li>
                          <li>1 {t('special character')}</li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="divider" />
                <div className="mb-5 mt-4 pt-2 pb-5 text-right form-footer">
                  <div className="text-left">
                    {renderProps.errors.NewPassword && renderProps.touched.NewPassword ? (
                      <div className="alert alert-danger">{renderProps.errors.NewPassword}</div>
                    ) : null}
                    {renderProps.errors.ConfirmPassword && renderProps.touched.ConfirmPassword ? (
                      <div className="alert alert-danger">{renderProps.errors.ConfirmPassword}</div>
                    ) : null}
                    <AppStatus />
                  </div>
                  {this.props.app.loading ? (
                    <div className="spinner-wrapper">
                      <i className="fa fa-spin fa-spinner" />
                    </div>
                  ) : (
                    <button type="submit" className="btn btn-custom ">
                      {t('Validate')}
                    </button>
                  )}
                </div>
              </form>
            </Container>
          </>
        )}
      </Formik>
    );
  }
}

PasswordChange.propTypes = {
  t: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  app: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  firstLoginSuccessful: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
  auth: state.auth,
});

export default withTranslation()(
  connect(mapStateToProps, { changePassword, resetAppSuccess, firstLoginSuccessful })(withRouter(PasswordChange)),
);
