import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { resetAppSuccess } from '../../actions/appActions';
import ReactPlayer from 'react-player';
import './style.scss';
import { Row, Col } from 'react-bootstrap';
import LocationIcon from '../../images/locationIcon.svg';
import VideoThumbnail from '../../images/video-thumbnail.png';
import Roles from '../../utils/roles';
import { isManufacturer } from '../../utils/roleUtils';
import config from '../../config';
const { API_URL } = config;

class PartnerCard extends Component {
  render() {
    const { partnerData, t } = this.props;
    const { user } = this.props.auth;

    return (
      <>
        <Row className="my-5">
          <Col md={8}>
            <div className="partner_info ">
              <h6 className="category">{t(partnerData?.Categorie)}</h6>
              <h2 className="mon">{partnerData?.Nom}</h2>
              {partnerData?.Categorie === Roles.Manufacturer && (
                <p className="location">
                  <img src={LocationIcon} alt="" /> {partnerData?.Localisation}
                </p>
              )}
            </div>
            {isManufacturer() && (
              <div className="user-quote-section mt-5">
                <div className="user-name mb-2">
                  {user?.firstName}
                  {` `}
                  {user?.lastName}
                </div>
                <div className="user-email mb-3">{user?.email}</div>
                <div className="d-md-flex">
                  {user?.photo && <img className="user-image" src={API_URL + '/' + user?.photo} alt="" />}
                  <div className="user-quote">{`“ ${user?.quote} “`}</div>
                </div>
              </div>
            )}
          </Col>
          {partnerData?.Categorie === Roles.Manufacturer && partnerData?.videoFabrication && (
            <Col md={4}>
              <div className="video-player">
                <ReactPlayer
                  light
                  controls
                  url={API_URL.concat(`/${partnerData?.videoFabrication}`)}
                  height="auto"
                  playing
                  width="100%"
                  playIcon={
                    <div className="video-overlay">
                      <img src={VideoThumbnail} className="video-thumbnail" alt="" />
                      <span>{t('Manufacturing video')}</span>
                    </div>
                  }
                />
              </div>
            </Col>
          )}
        </Row>
        <div className="divider" />
      </>
    );
  }
}

PartnerCard.propTypes = {
  t: PropTypes.func,
  app: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  partnerData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
  auth: state.auth,
});

export default withTranslation()(connect(mapStateToProps, { resetAppSuccess })(PartnerCard));
