import * as api from '../api.js';
import {
  APP_ERROR,
  APP_LOADING,
  APP_SUCCESS,
  TEST_CATEGORY_LIST,
  TEST_CATEGORY_SEARCH,
  TEST_CATEGORY_DETAILS,
} from './types';

export const createTestCategory = (testCategoryData, history) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.createTestCategory(testCategoryData);
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
    if (res.data?.testCategory?._id) {
      const res2 = await api.getTestCategoryDetails(res.data?.testCategory?._id);
      dispatch({
        type: TEST_CATEGORY_DETAILS,
        payload: res2.data,
      });
      history.push(`/TestCategoryModify/${res.data?.testCategory?._id}`);
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const getTestCategories = () => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.getTestCategories();
    dispatch({
      type: TEST_CATEGORY_LIST,
      payload: res.data,
    });
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const setTestCategorySearchKey = (SearchKey) => async (dispatch) => {
  dispatch({
    type: TEST_CATEGORY_SEARCH,
    payload: SearchKey,
  });
};

export const resetTestCategoryDetails = () => (dispatch) => {
  dispatch({
    type: TEST_CATEGORY_DETAILS,
    payload: null,
  });
};

export const getTestCategoryDetails = (testCategoryId) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.getTestCategoryDetails(testCategoryId);
    dispatch({
      type: TEST_CATEGORY_DETAILS,
      payload: res.data,
    });
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const modifyTestCategory = (testCategoryId, testCategoryData) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.modifyTestCategory(testCategoryId, testCategoryData);
    const res2 = await api.getTestCategoryDetails(testCategoryId);
    dispatch({
      type: TEST_CATEGORY_DETAILS,
      payload: res2.data,
    });
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};
