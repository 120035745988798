import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetPassword } from '../../actions/authActions';
import { resetAppSuccess } from '../../actions/appActions';
import { Link } from 'react-router-dom';
import './style.scss';
import { Col, Container, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Formik } from 'formik';
import { isManufacturerOrLaboratory } from '../../utils/roleUtils';
import AppStatus from '../AppStatus';

class ResetPassword extends Component {
  render() {
    const { t } = this.props;
    const { isAuthenticated } = this.props?.auth;
    if (isAuthenticated) {
      return <Redirect to={isManufacturerOrLaboratory() ? '/ProductList' : '/'} />;
    }
    const LoginSchema = yup.object().shape({
      email: yup.string().required(t('Please enter e-mail')).email(t('Please enter a valid e-mail')),
    });
    return (
      <>
        <div className="password-reset-page">
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={LoginSchema}
            onSubmit={(values) => {
              this.props.resetPassword(values);
            }}
          >
            {(renderProps) => (
              <form className="" onSubmit={renderProps.handleSubmit} onChange={this.props.resetAppSuccess}>
                <h1 className="password-reset-page-headline">{t('Forgot your password')}</h1>
                <Container>
                  <Row className="justify-content-center">
                    <Col lg={7}>
                      <div className="password-reset-page-form-container">
                        <FormGroup as={Row} controlId="email">
                          <FormLabel column lg={3} className="text-lg-right font-weight-bold">
                            {t('E-mail')}
                          </FormLabel>
                          <Col lg={9}>
                            <FormControl
                              type="email"
                              name="email"
                              value={renderProps.values.email}
                              onChange={(ev) => {
                                renderProps.handleChange(ev);
                              }}
                              onBlur={renderProps.handleBlur}
                              required
                            />
                          </Col>
                        </FormGroup>
                        <div className="">
                          {renderProps.errors.email && renderProps.touched.email ? (
                            <div className="alert alert-danger">{renderProps.errors.email}</div>
                          ) : null}
                          <AppStatus />
                        </div>
                        <Row>
                          <Col md={6}>
                            <Link to="/login" className="brand-color no-underscore d-block mt-3">
                              {t('Login to your account')}
                            </Link>
                          </Col>
                          <Col md={6} className="text-md-right">
                            {this.props.app.loading ? (
                              <div className="spinner-wrapper d-inline-block">
                                <i className="fa fa-spin fa-spinner" />
                              </div>
                            ) : (
                              <button type="submit" className="btn btn-custom-common min-width-200 ml-0 ">
                                {t('Reset Password')}
                              </button>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </form>
            )}
          </Formik>
        </div>
      </>
    );
  }
}

ResetPassword.propTypes = {
  t: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  app: PropTypes.object.isRequired,
  resetPassword: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  app: state.app,
});

export default withTranslation()(connect(mapStateToProps, { resetPassword, resetAppSuccess })(ResetPassword));
