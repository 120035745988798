import './style.scss';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class IngredientItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { Nom, sousTitre, descriptionIngredient, Archived } = this.props.ingredient;
    return (
      <div className="col-lg-3 col-md-6">
        <div
          className="ingredient_item"
          onClick={() => {
            this.props.handleIngredientDetailsModalShow(this.props.ingredient);
          }}
        >
          <div className="ingredient_item_wrapper">
            <h2 className={'ingredient_item_mon ' + (Archived === true ? 'category_archived' : '')}> {Nom}</h2>
            <h6 className="ingredient_item_sous_titre"> {sousTitre}</h6>
            <p className="ingredient_item_description_ingredient"> {descriptionIngredient}</p>
          </div>
        </div>
      </div>
    );
  }
}

IngredientItem.propTypes = {
  ingredient: PropTypes.object.isRequired,
  handleIngredientDetailsModalShow: PropTypes.func.isRequired,
};

export default IngredientItem;
