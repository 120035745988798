import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { resetAppSuccess } from '../../actions/appActions';
import { Link } from 'react-router-dom';
import './style.scss';
import { Container } from 'react-bootstrap';
import PartnerCard from '../PartnerCard';

class InfoConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    const { user } = this.props.auth;
    return (
      <>
        <div className="container-fluid mt-5 mb-5">
          <h1 className="page_head  text-center mb-4">
            {t('Welcome')} {user?.firstName} {user?.lastName}
          </h1>
        </div>
        <Container>
          <div className="mt-5 py-3">
            <h2 className="page_head_3 mb-1 ">{t('Step')} 1/2</h2>
            <h3 className="page_head_2 mb-2 pb-1">{t('Validation of your information')}</h3>
            <p style={{ maxWidth: 405 }}>{t('Validation of your information Message')}</p>
          </div>
          <div className="divider" />
          {user?.partnerData && <PartnerCard partnerData={user?.partnerData} />}
          <div className="mb-5 mt-5 pb-5 text-right form-footer">
            <Link to="/PasswordChange" className="btn btn-custom ">
              {t('Validate this information')}
            </Link>
          </div>
        </Container>
      </>
    );
  }
}

InfoConfirmation.propTypes = {
  t: PropTypes.func,
  app: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  app: state.app,
  auth: state.auth,
});

export default withTranslation()(connect(mapStateToProps, { resetAppSuccess })(InfoConfirmation));
