import * as api from '../api.js';
import {
  APP_ERROR,
  APP_LOADING,
  APP_SUCCESS,
  PARTNER_CURRENT_PAGE,
  PARTNER_LIST,
  PARTNER_SEARCH,
  PARTNER_ARCHIVED_STATUS,
  PARTNER_DETAILS,
  USER_LIST,
} from './types';

const buildPartnerFormData = (partnerData) => {
  let partnerDataBuild = new FormData();
  for (const property in partnerData) {
    if ({}.hasOwnProperty.call(partnerData, property)) {
      if (partnerData[property] && typeof partnerData[property] === 'object') {
        for (let i = 0; i < partnerData[property].length; i++) {
          partnerDataBuild.append(
            property,
            property === 'User' ? JSON.stringify(partnerData[property][i]) : partnerData[property][i],
          );
        }
      } else {
        partnerDataBuild.append(property, partnerData[property]);
      }
    }
  }
  return partnerDataBuild;
};

export const createPartnerSheet = (partnerData, history) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await api.createPartnerSheet(buildPartnerFormData(partnerData), headers);
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
    if (res.data?.createdFiche?._id) {
      const res2 = await api.getPartnerDetails(res.data?.createdFiche?._id);
      dispatch({
        type: PARTNER_DETAILS,
        payload: res2.data,
      });
      history.push(`/PartnerModify/${res.data?.createdFiche?._id}`);
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const modifyPartnerSheet = (partnerId, partnerData) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await api.modifyPartnerSheet(partnerId, buildPartnerFormData(partnerData), headers);
    const res2 = await api.getPartnerDetails(partnerId);
    dispatch({
      type: PARTNER_DETAILS,
      payload: res2.data,
    });
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const getPartners = () => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.getPartners();
    dispatch({
      type: PARTNER_LIST,
      payload: res.data.partners,
    });
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const deleteUser = (userId, partnerId) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    await api.deleteUser(userId);
    if (partnerId) {
      const res = await api.getPartnerDetails(partnerId);
      dispatch({
        type: PARTNER_DETAILS,
        payload: res.data,
      });
    } else {
      const res = await api.getUsers();
      dispatch({
        type: USER_LIST,
        payload: res.data,
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const addModifyUser = (userData, userId) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res1 = userId
      ? await api.modifyUser(userId, buildPartnerFormData(userData), headers)
      : await api.addUser(buildPartnerFormData(userData), headers);
    dispatch({
      type: APP_SUCCESS,
      payload: [res1.data.msg],
    });
    if (userData?.partner) {
      const res = await api.getPartnerDetails(userData?.partner);
      dispatch({
        type: PARTNER_DETAILS,
        payload: res.data,
      });
    } else {
      const res = await api.getUsers();
      dispatch({
        type: USER_LIST,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: APP_ERROR,
      payload: [err?.response ? err?.response?.data?.msg : 'Something is wrong.'],
    });
  }
};

export const archivePartnerToggle = (partnerId) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    await api.archivePartnerToggle(partnerId);
    await getPartnerDetails(partnerId)(dispatch);
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const setPartnerSearchKey = (SearchKey) => async (dispatch) => {
  dispatch({
    type: PARTNER_SEARCH,
    payload: SearchKey,
  });
};

export const setPartnerCurrentPage = (currentPage) => async (dispatch) => {
  dispatch({
    type: PARTNER_CURRENT_PAGE,
    payload: currentPage,
  });
};

export const setPartnerArchivedStatus = (archivedStatus) => async (dispatch) => {
  dispatch({
    type: PARTNER_ARCHIVED_STATUS,
    payload: archivedStatus,
  });
};

export const getPartnerDetails = (partnerId) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.getPartnerDetails(partnerId);
    dispatch({
      type: PARTNER_DETAILS,
      payload: res.data,
    });
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const resetPartnerDetails = () => (dispatch) => {
  dispatch({
    type: PARTNER_DETAILS,
    payload: null,
  });
};
