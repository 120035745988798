import * as api from '../api';
import { APP_ERROR, APP_LOADING, APP_SUCCESS, USER_LIST } from './types';
import { updateLocalStore } from './authActions';

export const changePasswordByUserId = (userId, password) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.changePasswordByUserId(userId, password);
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const changePassword = (password) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.changePassword(password);
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
    const res2 = await api.validateSession();
    updateLocalStore(dispatch, res2.data);
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const getUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.getUsers();
    dispatch({
      type: USER_LIST,
      payload: res.data,
    });
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};
