import store from '../store';
import Roles from './roles';

function getUserType() {
  return store.getState()?.auth?.user?.userType;
}

const isAdmin = () => {
  return getUserType() === Roles.Admin;
};

const isManufacturer = () => {
  return getUserType() === Roles.Manufacturer;
};

const isLaboratory = () => {
  return getUserType() === Roles.Laboratory;
};

const isNotAdmin = () => {
  return !isAdmin();
};

const isNotManufacturer = () => {
  return !isManufacturer();
};

const isNotLaboratory = () => {
  return !isLaboratory();
};

const isAdminOrManufacturer = () => {
  return isAdmin() || isManufacturer();
};

const isAdminOrLaboratory = () => {
  return isAdmin() || isLaboratory();
};

const isManufacturerOrLaboratory = () => {
  return isManufacturer() || isLaboratory();
};

export {
  isAdmin,
  isManufacturer,
  isLaboratory,
  isNotAdmin,
  isNotManufacturer,
  isNotLaboratory,
  isAdminOrManufacturer,
  isAdminOrLaboratory,
  isManufacturerOrLaboratory,
};
