import * as api from '../api.js';
import { APP_ERROR, APP_LOADING, APP_SUCCESS, SITE_INFO } from './types';

export const addSiteInfo = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    await api.addSiteInfo('knowMorePageTitle', payload.knowMorePageTitle);
    await api.addSiteInfo('knowMorePageText', payload.knowMorePageText);
    await api.addSiteInfo('scoringMethodPageTitle', payload.scoringMethodPageTitle);
    await api.addSiteInfo('scoringMethodPageText', payload.scoringMethodPageText);
    dispatch({
      type: APP_SUCCESS,
      payload: ['Site info added successfully.'],
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const getSiteInfo = () => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.getSiteInfo();
    dispatch({
      type: SITE_INFO,
      payload: res.data,
    });
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};
