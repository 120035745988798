import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enAll from './translations/en/all';
import frAll from './translations/fr/all';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      'en-US': {
        all: enAll,
      },
      'fr-FR': {
        all: frAll,
      },
    },
    fallbackLng: 'fr-FR',
    debug: false,
    ns: ['all'],
    defaultNS: 'all',
    keySeparator: false,
    interpolation: { escapeValue: false },
  });

export default i18n;
