import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  getTestItems,
  setTestItemSearchKey,
  setTestItemCurrentPage,
  setTestItemArchivedStatus,
  archiveTestItemToggle,
} from '../../actions/testItemActions';
import { getTestCategoryDetails, resetTestCategoryDetails } from '../../actions/testCategoryActions';
import { resetAppSuccess } from '../../actions/appActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './style.scss';
import ReactPaginate from 'react-paginate';
import TestItemBlock from '../TestItemBlock';
import AddIcon from '../../images/add.svg';
import SearchIcon from '../../images/search_icon.svg';
import { withTranslation } from 'react-i18next';
import DeleteIcon from '../../images/delete_icon.svg';
import DeleteWhiteIcon from '../../images/delete_white_icon.svg';
import PencilWhiteIcon from '../../images/pencil_white.svg';
import { Modal } from 'react-bootstrap';
import TimesColorIcon from '../../images/times_color.svg';
import EmptyItem from '../EmptyItem';
import AppStatus from '../AppStatus';

class TestItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testItemDetailsModalShow: false,
      testItemArchiveModalShow: false,
      testItemDetails: null,
      testCategoryId: null,
    };
    this.handleTestItemDetailsModalClose = this.handleTestItemDetailsModalClose.bind(this);
    this.handleTestItemDetailsModalShow = this.handleTestItemDetailsModalShow.bind(this);
    this.handleTestItemArchiveModalClose = this.handleTestItemArchiveModalClose.bind(this);
    this.handleTestItemArchiveModalShow = this.handleTestItemArchiveModalShow.bind(this);
  }

  componentDidMount() {
    const { testCategoryId } = this.props.match.params;
    if (testCategoryId) {
      this.setState({ testCategoryId });
      this.props.getTestItems(testCategoryId);
      this.props.getTestCategoryDetails(testCategoryId);
    } else {
      this.props.resetTestCategoryDetails();
    }
  }

  handleTestItemDetailsModalClose() {
    this.setState({ testItemDetailsModalShow: false });
  }

  handleTestItemDetailsModalShow(testItem) {
    this.setState({ testItemDetails: testItem });
    this.setState({ testItemDetailsModalShow: true });
  }

  handleTestItemArchiveModalClose() {
    this.setState({ testItemArchiveModalShow: false });
  }

  handleTestItemArchiveModalShow() {
    this.setState({ testItemArchiveModalShow: true });
  }

  render() {
    const { t } = this.props;
    const {
      offset,
      dataList,
      filteredDataList,
      perPage,
      currentPage,
      search,
      pageCount,
      archivedStatus,
    } = this.props.testItem;
    const { testCategoryDetails } = this.props.testCategory;
    const { testCategoryId, testItemDetails } = this.state;
    return (
      <>
        <div className="top-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <Link to="/TestCategoryList" className="top-bar-left-link">
                  <i>←</i> {t('Back to the list of security tests')}
                </Link>
              </div>
              <div className="col-md-6 text-right">
                <Link to={`/TestCategory/${testCategoryId}/TestItemCreate`} className="top-bar-right-link">
                  <i>
                    <img src={AddIcon} alt="Add" />
                  </i>
                  {t('Add a')} {testCategoryDetails?.name}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="text-center mb-4">
            <h1 className="page_head ">
              {t('The')} {testCategoryDetails?.name}{' '}
            </h1>
          </div>
        </div>
        <div className="mb-5">
          {dataList?.length > 0 ? (
            <>
              <div className="container">
                <div className="common_search_container">
                  <div className="common_search_input_wrapper">
                    <span className="common_search_input_icon">
                      <img src={SearchIcon} alt="" />
                    </span>
                    <input
                      className="common_search_input"
                      type="search"
                      placeholder={`${t('Find a')} ${testCategoryDetails?.name}`}
                      value={search}
                      onChange={(e) => {
                        this.props.setTestItemSearchKey(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  {filteredDataList.slice(offset, offset + perPage).map((testItem, index) => (
                    <TestItemBlock
                      testItem={testItem}
                      key={index}
                      handleTestItemDetailsModalShow={this.handleTestItemDetailsModalShow}
                    />
                  ))}
                </div>
                <div className="brand_pagination">
                  {pageCount > 0 && (
                    <ReactPaginate
                      breakClassName="page-item"
                      breakLabel={<span className="page-link gap">...</span>}
                      containerClassName={'pagination d-flex justify-content-center'}
                      pageClassName="page-item"
                      previousLinkClassName={'page-link previous_page'}
                      nextLinkClassName={'page-link next_page'}
                      disabledClassName={'disabled'}
                      activeClassName={'active'}
                      previousLabel={`← ${t('Previous')}`}
                      nextLabel={`${t('Next')} →`}
                      pageCount={pageCount}
                      onPageChange={(evData) => {
                        this.props.setTestItemCurrentPage(evData.selected);
                      }}
                      forcePage={currentPage}
                      previousClassName="page-item"
                      nextClassName="page-item"
                      pageLinkClassName="page-link"
                    />
                  )}
                </div>
                <div className="mb-5">
                  <button
                    type="button"
                    className="brand-btn-5"
                    onClick={() => {
                      this.props.setTestItemArchivedStatus(!archivedStatus);
                    }}
                  >
                    <img src={DeleteIcon} alt="" />
                    {archivedStatus
                      ? `Hide archived ${testCategoryDetails?.name?.toLowerCase()}`
                      : `Voir les ${testCategoryDetails?.name?.toLowerCase()} archivés`}
                  </button>
                </div>
              </div>
              <Modal
                show={this.state.testItemDetailsModalShow}
                onHide={this.handleTestItemDetailsModalClose}
                className="brand-modal"
              >
                <Modal.Header className="pb-0 border-less">
                  <Modal.Title>{testItemDetails?.name}</Modal.Title>
                  <button onClick={this.handleTestItemDetailsModalClose} type="button" className="modal-close-btn">
                    <img src={TimesColorIcon} alt="close" />
                  </button>
                </Modal.Header>
                <Modal.Body className="pt-0">
                  <div>
                    <h6 className="test_item_sub">
                      {testItemDetails?.molecules?.map((item) => item?.name)?.join(', ')}
                    </h6>
                    <p className="test_item_description"> {testItemDetails?.description}</p>
                  </div>
                </Modal.Body>
                <Modal.Footer className="py-4 text-left justify-content-start">
                  {this.props.app.loading ? (
                    <div className="spinner-wrapper">
                      <i className="fa fa-spin fa-spinner" />
                    </div>
                  ) : testItemDetails?.archived ? (
                    <button
                      type="button"
                      className="action_btn_with_txt"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.archiveTestItemToggle(testCategoryId, testItemDetails._id);
                        this.handleTestItemDetailsModalClose();
                      }}
                    >
                      <i className="action-icon rounded-circle mr-1">
                        <img src={DeleteWhiteIcon} alt="Reactivate" />
                      </i>
                      {t('Reactivate The')} {testCategoryDetails?.name}
                    </button>
                  ) : (
                    <>
                      <Link
                        to={`/TestCategory/${testCategoryId}/TestItemModify/${testItemDetails?._id}`}
                        className="action_btn_with_txt mr-3"
                      >
                        <i className="action-icon rounded-circle mr-1">
                          <img src={PencilWhiteIcon} alt="Edit" />
                        </i>{' '}
                        {t('Edit')} {testCategoryDetails?.name?.toLowerCase()}
                      </Link>
                      <button
                        type="button"
                        className="action_btn_with_txt"
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleTestItemArchiveModalShow();
                          this.handleTestItemDetailsModalClose();
                        }}
                      >
                        <i className="action-icon rounded-circle mr-1">
                          <img src={DeleteWhiteIcon} alt="Delete" />
                        </i>
                        {t('Archive the')} {testCategoryDetails?.name?.toLowerCase()}
                      </button>
                    </>
                  )}
                </Modal.Footer>
              </Modal>
              <Modal
                show={this.state.testItemArchiveModalShow}
                onHide={this.handleTestItemArchiveModalClose}
                className="brand-modal"
              >
                <Modal.Header>
                  <Modal.Title>{t('Archiving')}</Modal.Title>
                  <button onClick={this.handleTestItemArchiveModalClose} type="button" className="modal-close-btn">
                    <img src={TimesColorIcon} alt="close" />
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <div className="pop-msg-con mb-3">
                    <p className="pop-msg-con-h">{t('testItemArchiveHead')}</p>
                    <p className="mb-0">{t('testItemArchivePara1')}</p>
                    <p>{t('testItemArchivePara2')}</p>
                  </div>
                  <div className="">
                    <AppStatus />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    onClick={this.handleTestItemArchiveModalClose}
                    className="btn btn-custom-outline-common px-3 mr-2"
                  >
                    {t('Go back')}
                  </button>
                  {this.props.app.loading ? (
                    <div className="spinner-wrapper">
                      <i className="fa fa-spin fa-spinner" />
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        this.props.archiveTestItemToggle(testCategoryId, testItemDetails._id);
                        this.handleTestItemArchiveModalClose();
                      }}
                      className="btn btn-custom-common px-3"
                    >
                      {t('Archive')}
                    </button>
                  )}
                </Modal.Footer>
              </Modal>
            </>
          ) : (
            <EmptyItem />
          )}
        </div>
      </>
    );
  }
}

TestItemList.propTypes = {
  t: PropTypes.func,
  getTestItems: PropTypes.func.isRequired,
  setTestItemSearchKey: PropTypes.func.isRequired,
  setTestItemCurrentPage: PropTypes.func.isRequired,
  setTestItemArchivedStatus: PropTypes.func.isRequired,
  archiveTestItemToggle: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  testCategory: PropTypes.object.isRequired,
  testItem: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  getTestCategoryDetails: PropTypes.func.isRequired,
  resetTestCategoryDetails: PropTypes.func.isRequired,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  testCategory: state.testCategory,
  testItem: state.testItem,
  app: state.app,
});

export default withTranslation()(
  connect(mapStateToProps, {
    getTestItems,
    setTestItemSearchKey,
    getTestCategoryDetails,
    setTestItemCurrentPage,
    archiveTestItemToggle,
    resetAppSuccess,
    setTestItemArchivedStatus,
    resetTestCategoryDetails,
  })(TestItemList),
);
