import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import TimesWhiteIcon from '../../images/times.svg';
import moment from 'moment';
import { archiveProductToggle } from '../../actions/productActions';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import config from '../../config';
const { API_URL } = config;

class ProductListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      _id,
      nameFR,
      Categorie,
      codeEAN,
      Version,
      Batches,
      Archived,
      productImage,
      ArchivedChangeDate,
    } = this.props.product;
    const { deleteDraftProduct, isDraft, t } = this.props;
    return (
      <Col
        lg={isDraft ? 6 : 12}
        className={`${isDraft ? 'draft-product' : 'no-draft-product'} ${Archived ? 'archived-product' : ''}`}
      >
        <div className="product-item-card ">
          {Archived && (
            <div className="reactive-date mb-3">
              {t('Archived on')} {ArchivedChangeDate && moment(ArchivedChangeDate).format('DD/MM/YYYY')}
              <button
                className="reset-btn brand-color ml-3 "
                onClick={() => {
                  this.props.archiveProductToggle(_id);
                }}
              >
                {t('Reactivate')}
              </button>
            </div>
          )}
          <div className="d-flex">
            {isDraft && (
              <>
                <div className="product-item-label hang-top-right">{t('DRAFT COPY')}</div>
                <button
                  type="button"
                  className="action_btn_with_txt"
                  onClick={(e) => {
                    deleteDraftProduct(_id);
                  }}
                >
                  <i className="action-icon">
                    <img src={TimesWhiteIcon} alt="Delete" />
                  </i>
                </button>
              </>
            )}
            <div className="product-image">
              <img src={productImage ? `${API_URL}/${productImage}` : ``} alt="" />
            </div>
            <div className={'flex-grow-1 d-flex flex-column ' + (isDraft ? 'justify-content-between' : '')}>
              <div className={'product-name-cat ' + (!isDraft ? 'mb-4' : '')}>
                {!isDraft && (
                  <div className="product-item-label mb-2">
                    {t('Version')} {Version}
                  </div>
                )}
                <h4>{Categorie}</h4>
                <h5>{nameFR}</h5>
              </div>
              <div className="product-details">
                <Row>
                  {isDraft ? (
                    <>
                      <Col sm={6}>{t('Blockchain ID')}</Col>
                      <Col sm={6} className="product-details-text">
                        #12
                      </Col>

                      <Col sm={6}>{t('Product ID')}</Col>
                      <Col sm={6} className="product-details-text">
                        {codeEAN}
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md={3}>{t('Blockchain ID')}</Col>
                      <Col md={3} className="product-details-text">
                        #12
                      </Col>

                      <Col md={3}>{t('No. of batches')}</Col>
                      <Col md={3} className="product-details-text">
                        {Batches?.length}
                      </Col>

                      <Col md={3}>{t('EAN code')}</Col>
                      <Col md={3} className="product-details-text">
                        {codeEAN}
                      </Col>

                      <Col md={3}> {t('lastBatch')}</Col>
                      <Col md={3} className="product-details-text">
                        {Batches?.[Batches?.length - 1]?.creationDate}
                      </Col>
                    </>
                  )}
                </Row>
              </div>
              {isDraft ? (
                <Link to={`/ProductModifySheet/${_id}`} className="product-link">
                  {t('See the draft')} →
                </Link>
              ) : (
                <Link to={`/ProductDetails/${_id}`} className="product-link">
                  {t('See the sheet')} →
                </Link>
              )}
            </div>
          </div>
        </div>
      </Col>
    );
  }
}

ProductListItem.propTypes = {
  t: PropTypes.func,
  archiveProductToggle: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  deleteDraftProduct: PropTypes.func,
  isDraft: PropTypes.bool,
};

const mapStateToProps = (state) => ({});

export default withTranslation()(
  connect(mapStateToProps, {
    archiveProductToggle,
  })(ProductListItem),
);
