import {
  BATCH_DETAILS,
  QUALITY_ANALYSIS_REPORT_ADD_MODAL_SHOW,
  TEST_REPORT_ADD_MODAL_SHOW,
  BATCH_DETAILS_MODAL_SHOW,
} from '../actions/types';

const initialState = {
  batchDetails: null,
  qualityAnalysisReportAddModalShow: false,
  testReportAddModalShow: false,
  batchDetailsModalShow: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case BATCH_DETAILS:
      return {
        ...state,
        batchDetails: payload,
      };
    case QUALITY_ANALYSIS_REPORT_ADD_MODAL_SHOW:
      return {
        ...state,
        qualityAnalysisReportAddModalShow: !state.qualityAnalysisReportAddModalShow,
      };
    case TEST_REPORT_ADD_MODAL_SHOW:
      return {
        ...state,
        testReportAddModalShow: !state.testReportAddModalShow,
      };
    case BATCH_DETAILS_MODAL_SHOW:
      return {
        ...state,
        batchDetailsModalShow: !state.batchDetailsModalShow,
      };
    default:
      return { ...state };
  }
}
