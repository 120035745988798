import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  createTestCategory,
  getTestCategoryDetails,
  modifyTestCategory,
  resetTestCategoryDetails,
} from '../../actions/testCategoryActions';
import { resetAppSuccess } from '../../actions/appActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Formik } from 'formik';
import { withTranslation } from 'react-i18next';
import { Container, Row } from 'react-bootstrap';
import './style.scss';
import AppStatus from '../AppStatus';

class TestCategoryCreateModify extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { testCategoryId } = this.props.match.params;
    if (testCategoryId) {
      this.props.getTestCategoryDetails(testCategoryId);
    } else {
      this.props.resetTestCategoryDetails();
    }
  }

  render() {
    const { t } = this.props;
    const { testCategoryDetails } = this.props.testCategory;
    const { testCategoryId } = this.props.match.params;
    const TestCategorySchema = yup.object().shape({
      name: yup.string().required(t('Please enter Nom')),
      description: yup.string(),
      coefficient: yup.number(),
    });

    return (
      <>
        <div className="top-bar">
          <div className="container">
            <Link to="/TestCategoryList" className="top-bar-left-link">
              <i>←</i> {t('Back to the list of security tests')}
            </Link>
          </div>
        </div>
        {(testCategoryId && testCategoryDetails) || !testCategoryId ? (
          <Formik
            enableReinitialize
            initialValues={{
              name: testCategoryDetails ? testCategoryDetails.name : '',
              description: testCategoryDetails ? testCategoryDetails.description : '',
              coefficient: testCategoryDetails ? testCategoryDetails.coefficient : '',
            }}
            validationSchema={TestCategorySchema}
            onSubmit={(values) => {
              if (testCategoryId) {
                this.props.modifyTestCategory(testCategoryId, values);
              } else {
                this.props.createTestCategory(values, this.props.history);
              }
            }}
          >
            {(renderProps) => (
              <form className="mt-5" onSubmit={renderProps.handleSubmit} onChange={this.props.resetAppSuccess}>
                <Container fluid>
                  <Row>
                    <div className="col-lg-6 offset-lg-3">
                      <div className="text-center mb-5">
                        <h5 className="page_head_sub">{testCategoryId ? t('Update') : t('Create a')}</h5>
                        <h1 className="page_head ">{testCategoryId ? t('Test Category') : t('New Test Category')}</h1>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="name" className="col-lg-5 col-form-label">
                          {t('Name')}
                        </label>
                        <input
                          className="form-control col-lg-7"
                          type="text"
                          name="name"
                          id="name"
                          value={renderProps.values.name}
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          required
                        />
                      </div>
                      <div className="form-group row">
                        <label htmlFor="description" className="col-lg-5 col-form-label pt-0">
                          {t('Introduction text')}
                          <span className="d-block font-weight-normal font-smallest2">
                            {t('Max x characters', { number: 150 })}
                          </span>
                          <span className="text-muted d-block font-weight-normal font-smallest">{t('Optional')}</span>
                        </label>
                        <textarea
                          rows="5"
                          className="form-control col-lg-7 "
                          name="description"
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          value={renderProps.values.description}
                        />
                      </div>
                      <div className="form-group row">
                        <label htmlFor="coefficient" className="col-lg-5 col-form-label">
                          {t('Coefficient')}
                        </label>
                        <input
                          className="form-control col-lg-7"
                          type="text"
                          name="coefficient"
                          id="coefficient"
                          value={renderProps.values.coefficient}
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mb-5 mt-5">
                      <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                          <div className="divider" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 offset-lg-3">
                      <div className="">
                        {renderProps.errors.name && renderProps.touched.name ? (
                          <div className="alert alert-danger">{renderProps.errors.name}</div>
                        ) : null}
                        {renderProps.errors.coefficient && renderProps.touched.coefficient ? (
                          <div className="alert alert-danger">{renderProps.errors.coefficient}</div>
                        ) : null}
                        <AppStatus />
                      </div>
                    </div>
                    <div className="col-lg-6 offset-lg-3 mb-5 mt-3 pb-5 text-right form-footer pr-0">
                      {this.props.app.loading ? (
                        <div className="spinner-wrapper">
                          <i className="fa fa-spin fa-spinner" />
                        </div>
                      ) : (
                        <button type="submit" className="btn btn-custom ">
                          {testCategoryId ? t('Update') : t('Create New Item')}
                        </button>
                      )}
                    </div>
                  </Row>
                </Container>
              </form>
            )}
          </Formik>
        ) : (
          <div className="text-center mt-5">
            <div className="spinner-wrapper">
              <i className="fa fa-spin fa-spinner" />
            </div>
          </div>
        )}
      </>
    );
  }
}

TestCategoryCreateModify.propTypes = {
  t: PropTypes.func,
  createTestCategory: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  getTestCategoryDetails: PropTypes.func.isRequired,
  modifyTestCategory: PropTypes.func.isRequired,
  resetTestCategoryDetails: PropTypes.func.isRequired,
  app: PropTypes.object.isRequired,
  testCategory: PropTypes.object.isRequired,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  app: state.app,
  testCategory: state.testCategory,
});

export default withTranslation()(
  connect(mapStateToProps, {
    createTestCategory,
    resetAppSuccess,
    getTestCategoryDetails,
    modifyTestCategory,
    resetTestCategoryDetails,
  })(TestCategoryCreateModify),
);
