import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  createTestItem,
  getTestItemDetails,
  modifyTestItem,
  resetTestItemDetails,
} from '../../actions/testItemActions';
import { getTestCategoryDetails, resetTestCategoryDetails } from '../../actions/testCategoryActions';
import { resetAppSuccess } from '../../actions/appActions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Formik, FieldArray } from 'formik';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import './style.scss';
import TimesColorIcon from '../../images/times_color.svg';
import AddIcon from '../../images/add.svg';
import AppStatus from '../AppStatus';

class TestItemCreateModify extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { testItemId, testCategoryId } = this.props.match.params;
    if (testCategoryId) {
      this.props.getTestCategoryDetails(testCategoryId);
    } else {
      this.props.resetTestCategoryDetails();
    }
    if (testItemId) {
      this.props.getTestItemDetails(testCategoryId, testItemId);
    } else {
      this.props.resetTestItemDetails();
    }
  }

  render() {
    const { t } = this.props;
    const { testItemDetails } = this.props.testItem;
    const { testCategoryDetails } = this.props.testCategory;
    const { testItemId, testCategoryId } = this.props.match.params;
    const TestItemSchema = yup.object().shape({
      name: yup.string().required(t('Please enter Nom')),
      description: yup.string(),
      molecules: yup.array().of(
        yup.object().shape({
          name: yup.string(),
          _id: yup.string(),
        }),
      ),
    });
    return (
      <Fragment>
        <div className="top-bar">
          <div className="container">
            <Link to={`/TestCategory/${testCategoryId}/TestItemList/`} className="top-bar-left-link">
              <i>←</i>
              {t('Back to the list of')} {testCategoryDetails?.name}
            </Link>
          </div>
        </div>
        {(testItemId && testItemDetails) || !testItemId ? (
          <Formik
            enableReinitialize
            initialValues={{
              name: testItemDetails ? testItemDetails.name : '',
              description: testItemDetails ? testItemDetails.description : '',
              molecules: testItemDetails ? testItemDetails.molecules : [],
            }}
            validationSchema={TestItemSchema}
            onSubmit={(values) => {
              if (testItemId) {
                this.props.modifyTestItem(testCategoryId, testItemId, values);
              } else {
                this.props.createTestItem(testCategoryId, values, this.props.history);
              }
            }}
          >
            {(renderProps) => (
              <form className="mt-5" onSubmit={renderProps.handleSubmit} onChange={this.props.resetAppSuccess}>
                <Container fluid>
                  <Row>
                    <div className="col-lg-6 offset-lg-3">
                      <div className="text-center mb-5">
                        <h5 className="page_head_sub">{testItemId ? t('Update') : t('Create a')} </h5>
                        <h1 className="page_head ">
                          {testItemId ? testCategoryDetails?.name : `${t('New')} ${testCategoryDetails?.name}`}
                        </h1>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="name" className="col-lg-5 col-form-label rect_box_title">
                          {t('Name')}
                        </label>
                        <input
                          className="form-control col-lg-7"
                          type="text"
                          name="name"
                          id="name"
                          value={renderProps.values.name}
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          required
                        />
                      </div>
                      <FormGroup as={Row}>
                        <FormLabel column lg={5} className="rect_box_title pt-0">
                          {t('Sub-category (ies)')}
                          <span className="text-muted d-block font-weight-normal font-smallest">{t('Optional')}</span>
                        </FormLabel>
                        <Col lg={7} className="p-0">
                          <FieldArray
                            name="molecules"
                            render={(arrayHelpers) => (
                              <>
                                {renderProps?.values?.molecules?.map((item, index) => (
                                  <div className="input-with-cross" key={index}>
                                    <FormControl
                                      type="text"
                                      required
                                      autoComplete="off"
                                      value={item?.name}
                                      onChange={(ev) => {
                                        arrayHelpers.replace(index, {
                                          name: ev.currentTarget.value,
                                          _id: item?._id,
                                        });
                                      }}
                                    />
                                    <img
                                      className="close-icon"
                                      src={TimesColorIcon}
                                      alt=""
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </div>
                                ))}
                                <div>
                                  <button
                                    type="button"
                                    className="action_btn_with_txt font-weight-normal"
                                    onClick={() => arrayHelpers.push({ name: '', _id: '' })}
                                  >
                                    <i className="action-icon rounded-circle mr-1">
                                      <img src={AddIcon} alt="Add" />
                                    </i>
                                    {t('Add a molecule')}
                                  </button>
                                </div>
                              </>
                            )}
                          />
                        </Col>
                      </FormGroup>
                      <div className="form-group row">
                        <label htmlFor="description" className="col-lg-5 col-form-label pt-0 rect_box_title">
                          {t('Description')} {testCategoryDetails?.name?.toLowerCase()}
                          <span className="text-muted d-block font-weight-normal font-smallest">{t('Optional')}</span>
                        </label>
                        <textarea
                          rows="5"
                          className="form-control col-lg-7 "
                          name="description"
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          value={renderProps.values.description}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mb-5 mt-5">
                      <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                          <div className="divider" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 offset-lg-3">
                      <div className="">
                        {renderProps.errors.name && renderProps.touched.name ? (
                          <div className="alert alert-danger">{renderProps.errors.name}</div>
                        ) : null}
                        <AppStatus />
                      </div>
                    </div>
                    <div className="col-lg-6 offset-lg-3 mb-5 mt-3 pb-5 text-right form-footer pr-0">
                      {this.props.app.loading ? (
                        <div className="spinner-wrapper">
                          <i className="fa fa-spin fa-spinner" />
                        </div>
                      ) : (
                        <Fragment>
                          <button type="submit" className="btn btn-custom ">
                            {testItemId ? t('Update') : t('Create New Item')}
                          </button>
                        </Fragment>
                      )}
                    </div>
                  </Row>
                </Container>
              </form>
            )}
          </Formik>
        ) : (
          <div className="text-center mt-5">
            <div className="spinner-wrapper">
              <i className="fa fa-spin fa-spinner" />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

TestItemCreateModify.propTypes = {
  t: PropTypes.func,
  createTestItem: PropTypes.func.isRequired,
  resetAppSuccess: PropTypes.func.isRequired,
  getTestItemDetails: PropTypes.func.isRequired,
  modifyTestItem: PropTypes.func.isRequired,
  resetTestItemDetails: PropTypes.func.isRequired,
  getTestCategoryDetails: PropTypes.func.isRequired,
  resetTestCategoryDetails: PropTypes.func.isRequired,
  app: PropTypes.object.isRequired,
  testItem: PropTypes.object.isRequired,
  testCategory: PropTypes.object.isRequired,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  app: state.app,
  testItem: state.testItem,
  testCategory: state.testCategory,
});

export default withTranslation()(
  connect(mapStateToProps, {
    createTestItem,
    resetAppSuccess,
    getTestItemDetails,
    modifyTestItem,
    resetTestItemDetails,
    getTestCategoryDetails,
    resetTestCategoryDetails,
  })(TestItemCreateModify),
);
