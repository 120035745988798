import {
  PARTNER_LIST,
  PARTNER_CURRENT_PAGE,
  PARTNER_SEARCH,
  PARTNER_ARCHIVED_STATUS,
  PARTNER_DETAILS,
} from '../actions/types';

const initialState = {
  offset: 0,
  dataList: [],
  filteredDataList: [],
  elements: [],
  perPage: 48,
  currentPage: 0,
  search: '',
  pageCount: 0,
  archivedStatus: false,
  partnerDetails: null,
};

export default function (state = initialState, { type, payload }) {
  const filterArchivedPartners = (dataList, archivedStatus) => {
    return dataList.filter((partner) => !partner.Archived || archivedStatus === partner.Archived);
  };
  const filterSearchedPartners = (dataList, search) => {
    return dataList.filter((partner) => {
      if (search.length === 0) {
        return true;
      } else {
        let payloadT = search.toLowerCase();
        return (
          partner.Nom.toLowerCase().search(payloadT) !== -1 || partner.Categorie.toLowerCase().search(payloadT) !== -1
        );
      }
    });
  };
  let filteredDataList = [];
  switch (type) {
    case PARTNER_LIST:
      filteredDataList = filterArchivedPartners(payload, state.archivedStatus);
      return {
        ...state,
        dataList: payload,
        filteredDataList: filteredDataList,
        pageCount: Math.ceil(filteredDataList.length / state.perPage),
      };

    case PARTNER_SEARCH:
      filteredDataList = filterSearchedPartners(state.dataList, payload);
      filteredDataList = filterArchivedPartners(filteredDataList, state.archivedStatus);
      return {
        ...state,
        search: payload,
        filteredDataList: filteredDataList,
        pageCount: Math.ceil(filteredDataList.length / state.perPage),
        currentPage: 0,
        offset: 0,
      };
    case PARTNER_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
        offset: payload * state.perPage,
      };
    case PARTNER_ARCHIVED_STATUS:
      filteredDataList = filterSearchedPartners(state.dataList, state.search);
      filteredDataList = filterArchivedPartners(filteredDataList, payload);
      return {
        ...state,
        filteredDataList: filteredDataList,
        pageCount: Math.ceil(filteredDataList.length / state.perPage),
        currentPage: 0,
        offset: 0,
        archivedStatus: payload,
      };
    case PARTNER_DETAILS:
      return {
        ...state,
        partnerDetails: payload,
      };
    default:
      return { ...state };
  }
}
