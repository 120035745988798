import * as api from '../api.js';
import {
  APP_ERROR,
  APP_LOADING,
  APP_SUCCESS,
  QUALITY_ANALYSIS_REPORT_ADD_MODAL_SHOW,
  BATCH_DETAILS,
  PRODUCT_DETAILS,
  TEST_REPORT_ADD_MODAL_SHOW,
  BATCH_DETAILS_MODAL_SHOW,
} from './types';
import { getProductDetails } from './productActions';

export const getBatchDetails = (batchId) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const res = await api.getBatchDetails(batchId);
    dispatch({
      type: BATCH_DETAILS,
      payload: res.data,
    });
    dispatch({
      type: APP_LOADING,
      payload: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};

export const resetBatchDetails = () => (dispatch) => {
  dispatch({
    type: BATCH_DETAILS,
    payload: null,
  });
};

const buildPartnerFormData = (batchData) => {
  let batchDataBuild = new FormData();
  for (const property in batchData) {
    if ({}.hasOwnProperty.call(batchData, property)) {
      if (batchData[property] && typeof batchData[property] === 'object') {
        for (let i = 0; i < batchData[property].length; i++) {
          batchDataBuild.append(property, batchData[property][i]);
        }
      } else {
        batchDataBuild.append(property, batchData[property]);
      }
    }
  }
  return batchDataBuild;
};

export const createBatch = (batchData, history) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await api.createBatch(buildPartnerFormData(batchData), headers);
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
    history.push(`/ProductDetails/${batchData?.product}`);
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      payload: [err?.response ? err?.response?.data?.msg : 'Something is wrong.'],
    });
  }
};

export const modifyBatch = (batchId, batchData) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await api.modifyBatch(batchId, buildPartnerFormData(batchData), headers);
    const res2 = await api.getBatchDetails(batchId);
    dispatch({
      type: BATCH_DETAILS,
      payload: res2.data,
    });
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      payload: [err?.response ? err?.response?.data?.msg : 'Something is wrong.'],
    });
  }
};
export const modifyBatch2 = (batchId, batchData) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await api.modifyBatch2(batchId, buildPartnerFormData(batchData), headers);
    const res2 = await api.getBatchDetails(batchId);
    dispatch({
      type: BATCH_DETAILS,
      payload: res2.data,
    });
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      payload: [err?.response ? err?.response?.data?.msg : 'Something is wrong.'],
    });
  }
};

export const addBatchQualityReport = (batchId, productId, batchData) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await api.addBatchQualityReport(batchId, buildPartnerFormData(batchData), headers);
    const res2 = await api.getProductDetails(productId);
    dispatch({
      type: PRODUCT_DETAILS,
      payload: res2.data,
    });
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      payload: [err.response ? err.response?.data?.msg : 'Something is wrong.'],
    });
  }
};

export const addBatchTestReport = (batchId, productId, batchData) => async (dispatch) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const res = await api.addBatchTestReport(batchId, buildPartnerFormData(batchData), headers);
    const res2 = await api.getProductDetails(productId);
    dispatch({
      type: PRODUCT_DETAILS,
      payload: res2.data,
    });
    dispatch({
      type: APP_SUCCESS,
      payload: [res.data.msg],
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      payload: [err.response ? err.response?.data?.msg : 'Something is wrong.'],
    });
  }
};

export const qualityAnalysisReportAddModalShowToggle = () => async (dispatch) => {
  dispatch({
    type: QUALITY_ANALYSIS_REPORT_ADD_MODAL_SHOW,
  });
};

export const testReportAddModalShowToggle = () => async (dispatch) => {
  dispatch({
    type: TEST_REPORT_ADD_MODAL_SHOW,
  });
};

export const batchDetailsModalShowToggle = () => async (dispatch) => {
  dispatch({
    type: BATCH_DETAILS_MODAL_SHOW,
  });
};

export const batchAvailableToggle = (batchId, productId) => async (dispatch, states) => {
  try {
    dispatch({
      type: APP_LOADING,
      payload: true,
    });
    await api.availableBatchToggle(batchId);
    await getProductDetails(productId)(dispatch);
  } catch (err) {
    console.log(err);
    dispatch({
      type: APP_ERROR,
      // payload: [(err.response ? err.response.data : "Something is wrong.")]
      payload: ['Something is wrong.'],
    });
  }
};