import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { deleteUser, addModifyUser } from '../../actions/partnerActions';
import { resetAppSuccess } from '../../actions/appActions';
import { changePasswordByUserId } from '../../actions/profileActions';
import { Container, Table, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Formik } from 'formik';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import randomize from 'randomatic';
import './style.scss';
import TimesIcon from '../../images/times.svg';
import PencilIcon from '../../images/pencil_white.svg';
import TimesColorIcon from '../../images/times_color.svg';
import AddIcon from '../../images/add.svg';
import CheckMarkGreenIcon from '../../images/check_mark_green.svg';
import TimesGrayIcon from '../../images/times-gray.svg';
import PhotoPlaceholderIcon from '../../images/table-image-placeholder.svg';
import FilePreview from '../FilePreview';
import Roles from '../../utils/roles';
import config from '../../config';
import AppStatus from '../AppStatus';
const { API_URL } = config;

class UserAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userModalShow: false,
      userDeleteModalShow: false,
      deleteUserID: null,
      activeModifyUser: null,
      copied: [],
    };
    this.handleUserCreateModifyModalClose = this.handleUserCreateModifyModalClose.bind(this);
    this.handleUserCreateModifyModalShow = this.handleUserCreateModifyModalShow.bind(this);
    this.handleUserDeleteModalClose = this.handleUserDeleteModalClose.bind(this);
    this.handleUserDeleteModalShow = this.handleUserDeleteModalShow.bind(this);
  }

  handleUserCreateModifyModalClose() {
    this.setState({ userModalShow: false });
  }

  handleUserCreateModifyModalShow() {
    this.setState({ userModalShow: true });
  }

  handleUserDeleteModalClose() {
    this.setState({ userDeleteModalShow: false });
  }

  handleUserDeleteModalShow() {
    this.setState({ userDeleteModalShow: true });
  }

  render() {
    const { t } = this.props;
    const { partnerData, userList } = this.props;
    const { activeModifyUser } = this.state;
    const UserCreateModifySchema = yup.object().shape({
      lastName: yup.string().required(t('Please enter last name')),
      firstName: yup.string().required(t('Please enter first name')),
      email: yup.string().required(t('Please enter e-mail')).email(t('Please enter a valid e-mail')),
      quote: yup.string(),
      photo: yup.mixed(),
    });
    return (
      <>
        {this.state.userModalShow && (
          <Formik
            initialValues={{
              firstName: activeModifyUser ? activeModifyUser?.firstName : '',
              lastName: activeModifyUser?.lastName ? activeModifyUser?.lastName : '',
              email: activeModifyUser?.email ? activeModifyUser?.email : '',
              quote: activeModifyUser?.quote ? activeModifyUser?.quote : '',
              photo: activeModifyUser?.photo ? activeModifyUser?.photo : '',
            }}
            validationSchema={UserCreateModifySchema}
            onSubmit={(values, { resetForm }) => {
              if (partnerData) {
                values.partner = partnerData?._id;
              }
              this.props.addModifyUser(values, activeModifyUser?._id);
              if (!activeModifyUser) {
                resetForm();
              }
              //this.handleUserCreateModifyModalClose();
            }}
          >
            {(renderProps) => (
              <Modal
                show={this.state.userModalShow}
                onHide={this.handleUserCreateModifyModalClose}
                className="brand-modal"
              >
                <Modal.Header>
                  <Modal.Title>{t('Adding a user')}</Modal.Title>
                  <button onClick={this.handleUserCreateModifyModalClose} type="button" className="modal-close-btn">
                    <img src={TimesColorIcon} alt="close" />
                  </button>
                </Modal.Header>
                <form onSubmit={renderProps.handleSubmit} onChange={this.props.resetAppSuccess}>
                  <Modal.Body>
                    <Container>
                      <div className="form-group row pr-3">
                        <label htmlFor="firstName" className="col-lg-4 col-form-label">
                          {t('First name')}
                        </label>
                        <input
                          className="form-control col-lg-8 "
                          type="text"
                          name="firstName"
                          id="firstName"
                          value={renderProps.values.firstName}
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          required
                        />
                      </div>
                      <div className="form-group row pr-3">
                        <label htmlFor="lastName" className="col-lg-4 col-form-label">
                          {t('Name')}
                        </label>
                        <input
                          className="form-control col-lg-8 "
                          type="text"
                          name="lastName"
                          id="lastName"
                          value={renderProps.values.lastName}
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          required
                        />
                      </div>
                      <div className="form-group row pr-3">
                        <label htmlFor="email" className="col-lg-4 col-form-label">
                          {t('E-mail')}
                        </label>
                        <input
                          className="form-control col-lg-8 "
                          type="email"
                          name="email"
                          id="email"
                          value={renderProps.values.email}
                          onChange={(ev) => {
                            renderProps.handleChange(ev);
                          }}
                          onBlur={renderProps.handleBlur}
                          required
                        />
                      </div>
                      {partnerData?.Categorie === Roles.Manufacturer && (
                        <>
                          <div className="form-group row pr-3">
                            <label htmlFor="quote" className="col-lg-4 col-form-label">
                              {t('Quote')}
                              <span className="d-block font-weight-normal font-smallest2">
                                {' '}
                                {t('Max x characters', { number: 150 })}
                              </span>
                              <span className="text-muted d-block font-weight-normal font-smallest">
                                {t('Optional')}
                              </span>
                            </label>
                            <textarea
                              rows="4"
                              className="form-control col-lg-8 "
                              name="quote"
                              id="quote"
                              onChange={(ev) => {
                                renderProps.handleChange(ev);
                              }}
                              onBlur={renderProps.handleBlur}
                              value={renderProps.values.quote}
                            />
                          </div>
                          <div>
                            <h4 className="page_head_2 mb-2 font-weight-500 text-capitalize">
                              {t('User Photo')}
                              <span className="d-inline-block pl-3 text-muted font-weight-normal font-smallest text-capitalize">
                                {t('Optional')}
                              </span>
                            </h4>
                            <div className="rect_box_wrapper">
                              <div className="rect_box" style={{ height: 220, maxWidth: 150 }}>
                                <input
                                  type="file"
                                  name="photo"
                                  id="photo"
                                  className="d-none"
                                  accept="image/*"
                                  onChange={(ev) => {
                                    renderProps.setFieldValue('photo', ev.currentTarget.files);
                                  }}
                                  onBlur={renderProps.handleBlur}
                                />
                                <label htmlFor="photo" className="rect-lbl">
                                  +
                                </label>
                                {renderProps?.values?.photo && (
                                  <div className="preview_container">
                                    <button
                                      onClick={(ev) => {
                                        renderProps.setFieldValue('photo', undefined);
                                        document.getElementById('photo').value = '';
                                      }}
                                      type="button"
                                      className="preview_close"
                                    >
                                      <img src={TimesIcon} alt="remove" />
                                    </button>
                                    {renderProps?.values?.photo && typeof renderProps?.values?.photo === 'string' ? (
                                      <img
                                        src={API_URL.concat(`/${renderProps.values.photo}`)}
                                        alt=""
                                        className="img-fluid prev_image"
                                      />
                                    ) : (
                                      renderProps?.values?.photo && <FilePreview file={renderProps.values.photo[0]} />
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="">
                        {renderProps.errors.firstName && renderProps.touched.firstName ? (
                          <div className="alert alert-danger">{renderProps.errors.firstName}</div>
                        ) : null}
                        {renderProps.errors.lastName && renderProps.touched.lastName ? (
                          <div className="alert alert-danger">{renderProps.errors.lastName}</div>
                        ) : null}
                        {renderProps.errors.email && renderProps.touched.email ? (
                          <div className="alert alert-danger">{renderProps.errors.email}</div>
                        ) : null}
                        <AppStatus />
                      </div>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer>
                    {activeModifyUser && (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            this.handleUserCreateModifyModalClose();
                            this.handleUserDeleteModalShow();
                          }}
                          className="reset-btn brand-color font-weight-bold flex-grow-1 text-left"
                        >
                          {t('Delete account')}
                        </button>
                        <button
                          type="button"
                          onClick={this.handleUserCreateModifyModalClose}
                          className="btn btn-custom-outline-common px-3 mr-2"
                        >
                          {t('Cancel')}
                        </button>
                      </>
                    )}
                    {this.props.app.loading ? (
                      <div className="spinner-wrapper">
                        <i className="fa fa-spin fa-spinner" />
                      </div>
                    ) : (
                      <button type="submit" className="btn btn-custom-common px-3">
                        {activeModifyUser ? t('Edit') : t('Add')} {}
                      </button>
                    )}
                  </Modal.Footer>
                </form>
              </Modal>
            )}
          </Formik>
        )}
        <Modal show={this.state.userDeleteModalShow} onHide={this.handleUserDeleteModalClose} className="brand-modal">
          <Modal.Header>
            <Modal.Title>{t('Deleting a user')}</Modal.Title>
            <button onClick={this.handleUserDeleteModalClose} type="button" className="modal-close-btn">
              <img src={TimesColorIcon} alt="close" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="pop-msg-con mb-3">
              <p className="pop-msg-con-h">{t('Are you sure you want to delete this user?')}</p>
              <p className="mb-0">{t('Once deleted, it will no longer be able to access the Blockchain You.')}</p>
            </div>
            <div className="">
              <AppStatus />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={() => {
                this.handleUserDeleteModalClose();
                this.handleUserCreateModifyModalShow();
              }}
              className="btn btn-custom-outline-common px-3 mr-2"
            >
              {t('Go back')}
            </button>
            {this.props.app.loading ? (
              <div className="spinner-wrapper">
                <i className="fa fa-spin fa-spinner" />
              </div>
            ) : (
              <button
                type="button"
                onClick={() => {
                  this.props.deleteUser(activeModifyUser?._id, partnerData?._id);
                  this.handleUserDeleteModalClose();
                }}
                className="btn btn-custom-common px-3"
              >
                {t('Archive')}
              </button>
            )}
          </Modal.Footer>
        </Modal>
        <div className="mt-5 py-3">
          <h3 className="page_head_2 mb-4">
            {!partnerData ? t('Organization user accounts') : t('User accounts')}
            <button
              type="button"
              className="action_btn_with_txt float-right"
              onClick={(e) => {
                this.props.resetAppSuccess();
                this.setState({ activeModifyUser: null });
                this.handleUserCreateModifyModalShow();
              }}
            >
              <i className="action-icon rounded-circle mr-1">
                <img src={AddIcon} alt="Add" />
              </i>
              {t('Add user')}
            </button>
          </h3>
        </div>
        <Table responsive striped borderless hover className="w-100 brand-table user-list-table">
          <thead>
            <tr>
              {partnerData?.Categorie === Roles.Manufacturer && <th />}
              <th>{t('First name')}</th>
              <th>{t('Name')}</th>
              <th>{t('E-mail')}</th>
              {partnerData?.Categorie === Roles.Manufacturer && <th>Citation</th>}
              <th>{t('Registration status')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {userList?.map((singleUser, index) => (
              <tr key={index}>
                {partnerData?.Categorie === Roles.Manufacturer && (
                  <td className="photo-column">
                    <img
                      className="user-image"
                      src={singleUser?.photo ? API_URL + '/' + singleUser?.photo : PhotoPlaceholderIcon}
                      alt=""
                    />
                  </td>
                )}
                <td>{singleUser.firstName}</td>
                <td>{singleUser.lastName}</td>
                <td>{singleUser.email}</td>
                {partnerData?.Categorie === Roles.Manufacturer && (
                  <td>
                    {singleUser?.quote ? <img src={CheckMarkGreenIcon} alt="" /> : <img src={TimesGrayIcon} alt="" />}
                  </td>
                )}
                <td>
                  {!singleUser.firstLogin ? (
                    !this.state.copied.includes(singleUser._id) ? (
                      <CopyToClipboard
                        text={randomize('*', 10)}
                        onCopy={(text, result) => {
                          this.props.changePasswordByUserId(singleUser._id, text);
                          this.setState({
                            copied: [...this.state.copied, singleUser._id],
                          });
                          setTimeout(() => {
                            this.setState({
                              copied: this.state.copied.filter(function (e) {
                                return e !== singleUser._id;
                              }),
                            });
                          }, 2000);
                        }}
                      >
                        <span className="copy_pass_btn">{'Copy temporary password'}</span>
                      </CopyToClipboard>
                    ) : (
                      <span className="copied_pass_lbl">
                        {t('Copy')}
                        <img src={CheckMarkGreenIcon} alt="" className="ml-2" />
                      </span>
                    )
                  ) : (
                    <span className="">{t('Connected')}</span>
                  )}
                </td>
                <td />
                <td className="text-right text-nowrap">
                  <button
                    className="action_btn mx-1"
                    type="button"
                    onClick={(e) => {
                      this.props.resetAppSuccess();
                      this.setState({ activeModifyUser: singleUser });
                      this.handleUserCreateModifyModalShow();
                    }}
                  >
                    <img src={PencilIcon} alt="" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}

UserAccount.propTypes = {
  t: PropTypes.func,
  resetAppSuccess: PropTypes.func.isRequired,
  partner: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
  addModifyUser: PropTypes.func.isRequired,
  changePasswordByUserId: PropTypes.func.isRequired,
  partnerData: PropTypes.object,
  userList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  partner: state.partner,
  app: state.app,
  auth: state.auth,
});

export default withTranslation()(
  connect(mapStateToProps, { resetAppSuccess, deleteUser, addModifyUser, changePasswordByUserId })(UserAccount),
);
